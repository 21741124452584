<template>

    <v-list>
        <div v-for="(menuElement, i) in menuElements" :key="i">

          <v-card style="background: #2e3e91" v-if="menuElement?.menuItems.length > 0">
            <v-list-group :value="menuElement.title" fluid style="padding: 0; margin: 0;">
              <template v-slot:activator="{ props }">
                <v-list-item class="listItemPadding" v-bind="props" :title="menuElement.title">
                  <template v-slot:prepend>
                    <div>
                      <img
                        :src="menuElement.icon"
                        class="menu-icon-img"
                      />
                    </div>
                  </template>
                </v-list-item>
              </template>

              <v-list-item class="listItemPadding">
                <div v-for="(menuItem, i) in menuElement.menuItems" :key="i">
                  <v-list-item class="listItemPadding"  :title="menuItem.name" router :to="menuItem.route" exact>
                    <template v-slot:prepend>
                      <div style="padding-right: 20px; padding-left: 13px;">
                        <img
                          style="width: 25px;"
                          src="@/assets/icons/caret_closed_white.png"
                          class="menu-icon-img"
                        />
                      </div>
                    </template>
                  
                  </v-list-item>
                </div>
              </v-list-item>
            </v-list-group>
          </v-card>

          <v-card style="background: #2e3e91" v-else>
            <v-list-item class="listItemPadding" :title="menuElement.title" router :to="menuElement.route" exact>
              <template v-slot:prepend>
                <div>
                  <img
                    :src="menuElement.icon"
                    class="menu-icon-img"
                  />
                </div>
              </template>
            </v-list-item>
          </v-card>
        </div>
    </v-list>
</template>

<script>
import { router } from '@/router/index.js';
// import { Auth } from 'aws-amplify';

import { useFavoritesStore } from "@/stores/admin/Favorites/favoritesStore.js"
import { useAuthStore } from "@/stores/auth/authStore.js";
import { mapStores, mapActions } from 'pinia';
import { useHelpStore } from "@/stores/Help/helpStore.js";

export default {
  name: "MenuBar",
  props: {},

  data: () => ({
    props: null,
    menuElement: null,
    menuElements: [
      // {
      //   icon: require("@/assets/icons/Workspace.png"),
      //   title: "Workspace",
      //   menuItems: [],
      //   route: "/workspace",
      //   isHover: false,
      //   clickActive: false,
      //   enabled: false
      // },
      // {
      //   icon: require("@/assets/icons/Favorites.png"),
      //   title: "Favorites",
      //   menuItems: [],
      //   route: "",
      //   isHover: false,
      //   clickActive: false,
      //   enabled: false
      // },
      // {
      //   icon: require("@/assets/icons/Analytics.png"),
      //   title: "Analytics",
      //   menuItems: [],
      //   route: "/analytics",
      //   isHover: false,
      //   clickActive: false,
      //   enabled: false
      // },
      // {
      //   icon: require("@/assets/icons/Billing.png"),
      //   title: "Billing",
      //   menuItems: [],
      //   // menuItems: [
      //   //   {name: "Customer Management", route: "/customerManagement"},
      //   //   {name: "International Rate Lookup", route: "/internationalRatesLookup"},
      //   //   {name: "Pricebook", route: "/Pricebooks"},
      //   //   {name: "Tax Calculator", route: "/taxCalculator"},
      //   //   {name: "Traffic Study Processing", route: "/trafficStudyProcessing"}
      //   // ],
      //   route: "",
      //   isHover: false,
      //   clickActive: false,
      //   enabled: false
      // },
      {
        icon: require("@/assets/icons/Contact Center.png"),
        title: "Contact Center",
        // menuItems: [],
        menuItems: [{name: "LEX", route: "/lex"}],
        route: "",
        isHover: false,
        clickActive: false,
        enabled: false
      },
      // {
      //   icon: require("@/assets/icons/LES.png"),
      //   title: "LES",
      //   menuItems: [],
      //   // menuItems: [{name: "Dispatch Schedule", route: "/dispatchSchedule"},{name: "Quotes", route: "/quotes"}],
      //   route: "",
      //   isHover: false,
      //   clickActive: false,
      //   enabled: false
      // },
      // {
      //   icon: require("@/assets/icons/SwitchOps.png"),
      //   title: "Switch Ops",
      //   menuItems: [],
      //   // menuItems: [{name: "Account Limits", route: "/accountLimits"},{name: "Carrier Rate", route: "/carrierRates"},{name: "Number Management", route: "/numberManagement"},{name: "Switch Support", route: "/lexPortal"},{name: "Text-to-Speech Converter", route: "/texttospeech"}],
      //   route: "",
      //   isHover: false,
      //   clickActive: false,
      //   enabled: false
      // },
      // {
      //   icon: require("@/assets/icons/Tickets.png"),
      //   title: "Tickets",
      //   menuItems: [],
      //   route: "/tickets",
      //   isHover: false,
      //   clickActive: false,
      //   enabled: false
      // },
      // {
      //   icon: require("@/assets/icons/Voice.png"),
      //   title: "Voice",
      //   menuItems: [],
      //   // menuItems: [{name: "bConfig", route: "/BConfig"},{name: "iConfig", route: "/IConfig"},{name: "E911", route: "/E911"},{name: "Management", route: "/voiceManagement"},{name: "Requests", route: "/Requests"},{name: "Provisioning", route: "/Provisioning"},],
      //   route: "",
      //   isHover: false,
      //   clickActive: false,
      //   enabled: false
      // },
      // {
      //   icon: require("@/assets/icons/Admin.png"),
      //   title: "Admin",
      //   menuItems: [],
      //   route: "/admin",
      //   isHover: false,
      //   clickActive: false,
      // }
    ],
  }),
  async mounted() {
    // let user = await Auth.currentAuthenticatedUser();

    // let userFavorites = await this.FavoritesStore.fetchfavoriteApps(user.username);

    // let favoritesMenu = this.menuElements.find(menu => menu.title === "Favorites");

    // if (userFavorites && Array.isArray(userFavorites) && favoritesMenu) {
    //   favoritesMenu.menuItems = userFavorites.map(app => {
    //     return {
    //       name: app.name,
    //       route: app.route
    //     };
    //   });
    // }

    // const user_applications = this.AuthStore.getUsersApplications;
    // let allowedApp = user_applications.filter(app => userFavorites.some(allowedApp => allowedApp.route === app.route));

    // let applications = [];
    // for (let i=0; i < allowedApp.length; i++) {
    //   applications.push(
    //     { name: `${allowedApp[i].application_section} - ${allowedApp[i].name}`,
    //       route: allowedApp[i].route
    //     }
    //   );
    // }
    // await this.FavoritesStore.updateAllowedFavoriteApps(user.username, applications);

    // const favoritesMenuItemIndex = user_applications.findIndex(app => app.name === "Favorites");

    // if (favoritesMenuItemIndex === -1 && favoritesMenu) {
    //   user_applications.push({
    //     name: "Favorites",
    //     application_section: "Favorites",
    //     is_child_application: false,
    //     route: "",
    //     allowed_permissions: ""
    //   });
    // }
    
    // for (var user_app of user_applications) {
    //   var menuElementIndex = this.menuElements.findIndex(menuElement => menuElement.title === user_app.application_section);
    //   if (menuElementIndex != -1) {
    //     this.menuElements[menuElementIndex].enabled = true;
    //     if (user_app.is_child_application) {
    //       this.menuElements[menuElementIndex].menuItems.push({ name: user_app.name, route: user_app.route });
    //     }
    //   }
    // }

    // let favoritesIndex = this.menuElements.findIndex(element => element.title === "Favorites");
    // let formattedLinks = allowedApp.map(element => ({ name: element.name, route: element.route }));
    // if (favoritesIndex != -1) {
    //   this.menuElements[favoritesIndex].menuItems = formattedLinks;
    // }
  },
  computed: {
    ...mapStores(useFavoritesStore, useAuthStore, useHelpStore),
    // computedMenuElements() {
    //   // const filteredMenuElements = this.menuElements.filter(menuElement => menuElement.enabled);

    //   // filteredMenuElements.forEach(menuElement => {
    //   //   if (menuElement.menuItems && menuElement.menuItems.length > 0) {
    //   //     menuElement.menuItems.sort((a, b) => a.name.localeCompare(b.name));
    //   //   }
    //   // });

    //   // return filteredMenuElements;
    // }
  },
  methods: {
    ...mapActions(useHelpStore, ['setPage']),
    ...mapActions(useFavoritesStore, ['updateAllowedFavoriteApps']),


    toggleMenuOn(menuElement) {
      if (menuElement.route) {
        menuElement.isHover = false;
        menuElement.clickActive = false;
        router.replace(menuElement.route);
      }
      else {
        menuElement.isHover = true; // just do opposite
        menuElement.clickActive = true;
      }
      this.checkForOtherActiveMenusAndToggleOff(menuElement);

    },
    checkForOtherActiveMenusAndToggleOff(menuElement){
      for(let mE of this.menuElements){
        if(mE != menuElement){
          mE.isHover = false; // just do opposite
          mE.clickActive = false;
        }
      }
    },
    toggleMenuOff(menuElement) {
      menuElement.isHover = false;
      menuElement.clickActive = false;
    },
    clickDashboard(index, menuElement) {
      console.log('dashboard')
      // TODO: what needs to be done here besides a console.log? 
      //console.log("clicked Dashboard");
      menuElement.isHover = false;
      menuElement.clickActive = false;
      this.HelpManagementStore.setPage('Workspace / Workspace');
      router.replace(menuElement.route);
    },
    clickOnElement(index, menuElement) {
      console.log(menuElement)
      // e.stopPropagation();
      menuElement.isHover = false;
      menuElement.clickActive = false;
      this.HelpManagementStore.setPage(`${menuElement.title} / ${menuElement.menuItems[index].name}`);
      router.replace(menuElement.menuItems[index].route);
    },
  },
};
</script>

<style scoped>

.v-list-group__items .v-list-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 12px !important;
  padding-right: 0 !important;
  padding-inline-start: 0px !important;
}
.listItemPadding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  /* padding-right: 10px !important; */
  color: white;
}
.menubar-container {
  width: 100%;
  background-color: #2e3e91;
  height: 100%;
  box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(39, 29, 122, 0.4);
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.menu-container-no-radius {
  display: flex;
  flex-direction: column;
  background-color: #3a77c1;
  margin-left: 8px;
  margin-top: -8px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 41px;
}
.menu-container {
  display: flex;
  flex-direction: column;
  background-color: #3a77c1;
  margin-left: 8px;
  margin-top: -8px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  height: 41px;
}

.menu-title {
  color: #ffffff;
  font-weight: 600;
  font-size: 16pt;
  width: 160px;
  height: 41px;
  padding: 0px 12px 1.5px;
  padding-top: 7px;
}

.menu-element-wrapper{
  display: flex;
  flex-direction: column;
}
.menu-element {
  color: #ffffff;
  background-color: #2c358b;
  padding: 6px 9.5px;
  font-size: 14pt;
  /* height: 50px; */
}

.menu-element:last-child {
  border-bottom-right-radius: 2.5px;
}

.menu-element:hover {
  background-color: #292580;
  cursor: pointer;
}

.menu-element:active {
  background-color: #271d7a;
}

.img-container:hover {
  background-color: #3e87d3;
  cursor: pointer;
  border-top-left-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
}

.img-container {
  width: 50px;
  padding: 9.5px 8px;
  height: 40px;
  display: flex;
  align-items: center;
}

.menu-icon-img {
  object-fit: contain;
  width: 50px;
  height: 25px;
}

.narrow-nav {
  color: #ffffff;
  font-weight: 400;
  font-size: 13pt;
  /* width: 100%; */
  height: 42px;
  padding: 0px 12px;
  padding-top: 7px;
}
</style>
