<template>
  <div class="tableContainer" :style="{ width: dynamicWidth + 'px' }">
    <h1 class="listName">
      {{ name }}
    </h1>
    <div class="tableWrapper">
      <table class="table">
        <colgroup>
          <col
            v-for="(header, index) in headers"
            :key="index"
            :style="{ width: header.width + 'px' }"
          />
        </colgroup>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header" class="tableHeader" @click="header.value === 'agentName' || header.value === 'status' ? sort(header.value) : null">
              <span class="tableHeader" v-if="header.value !== 'supervisor'">
                {{ header.name }}
              </span>
            </th>
          </tr>
          <tr class="dividerRow">
            <td :colspan="headers.length"><DividerComp /></td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in items" :key="index">
            <tr 
              style="height: 80px"
              @dblclick="$emit('click-row', item)"
            >
              <td v-for="header in headers" :key="header" class="data-cell">
                <div
                  v-if="typeof item[header.value] === 'object'"
                  class="tableCell"
                >
                  <div class="multiContainer">
                    <img
                      :src="
                        require(`@/assets/icons/${
                          getColumnIcons(header.name, item[header.value])[0]
                        }`)
                      "
                      class="tableIcons"
                    />
                    <span v-if="header.value === 'status'" class="tableText" v-html="item[header.value].first"></span>
                  </div>
                  <div>
                    <img
                      :src="
                        require(`@/assets/icons/${
                          getColumnIcons(header.name)[1]
                        }`)
                      "
                      class="tableIcons"
                    />
                    <span class="tableText"  v-if="header.value == 'status'">
                      <StopWatchComp :seconds="item[header.value].second" :status="item[header.value].first"  />
                    </span>
                    <span class="tableText" v-else>
                      {{ item[header.value].second  }}
                    </span>
                  </div>
                </div>

                <div v-else class="tableTextContainer">
                  <span
                    v-if="header.value === 'queueName'"
                    class="clickableName"
                    @click="handleNavigate(item[header.value])"
                    >{{ item[header.value] }}</span
                  >
                  <span v-else class="tableText">
                    <div>
                      <img
                        v-if="getColumnIcons(header.name)"
                        :src="
                          require(`@/assets/icons/${getColumnIcons(
                            header.name
                          )}`)
                        "
                        class="tableIcons"
                      />
                      <span class="tableText">
                        {{ item[header.value] }}
                      </span>
                    </div></span
                  >
                </div>
              </td>
            </tr>
            <tr v-if="index !== items.length - 1" style="height: 10px">
              <td :colspan="headers.length"><DividerComp /></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import DividerComp from "@/components/base_components/DividerComp.vue";
import StopWatchComp from "./StopWatchComp.vue"


export default {
  name: "ListCompDash",
  props: {
    items: Array,
    headers: Array,
    name: String,
  },
  data() {
    return {
      selectedItems: [],
      singleSelected: {},
      windowWidth: null,
      currentSortColumn: null,
      sortAscending: true,
    };
  },
  components: { DividerComp, StopWatchComp },
  watch: {
    windowWidth() {
      this.$nextTick(() => {
        this.dynamicWidth;
      });
    },
  },
  emits: ["remove-item", "selected-item", "edit-item", "navigate-queue", "click-row", "update-items"],
  methods: {
    getColumnIcons(header, value) {
      if (header === "Status") {
        let statusImg;
        if (value?.first === "Away") {
          statusImg = "High Priority.png";
        } else if (value?.first === "Ready") {
          statusImg = "Available.png";
        } else if (value?.first === "Logged Out") {
          statusImg = "Medium Priority.png";
        } else if (value?.first.includes("On-A-Call") || value?.first === "On-External-Call") {
          statusImg = "Urgent Priority.png";
        }
        else { //TODO: Need more images for different statuses
          statusImg = "High Priority.png";
        }
        return [statusImg, "Status Time.png"];
      } else if (
        header === "Outbound and Answered Calls" ||
        header === "Inbound and Outbound Calls"
      ) {
        return ["Outbound.png", "Inbound.png"];
      } else if (header === "Missed and Abandoned Calls") {
        return ["Missed.png", "Abandonded.png"];
      } else if (header === "Tickets Resolved") {
        return ["Created Tickets.png", "Resolved Tickets.png"];
      } else if (header === "Tickets Escalated") {
        return ["Internal.png", "External.png"];
      } else if (header === "Agents On Phone & Away") {
        return ["Urgent Priority.png", "High Priority.png"];
      } else if (header === "LogicomUSA & Overflow Tickets") {
        return ["LogicomUSA.png", "Overflow.png"];
      } else if (header === "Calls Waiting & Wait Time") {
        return ["callswaiting.png", "waittime_larger.png"];
      } else if (header === "Missed Calls") {
        return "Missed.png";
      } else if (header === "Abandoned Calls") {
        return "Abandonded.png";
      } else if (header === "Outbound Calls") {
        return "Outbound.png";
      } else if (header === "Answered Calls") {
        return "Inbound.png";
      }
      return null;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    handleNavigate(name) {
      this.$emit("navigate-queue", name);
    },
    sort(column) {
      this.currentSortColumn = column;
      this.sortAscending = !this.sortAscending; 
      this.applySorting();
    },

    applySorting() {
      let sortedItems = [...this.items]; 

      sortedItems.sort((a, b) => {
        let valA = a[this.currentSortColumn];
        let valB = b[this.currentSortColumn];

        if (typeof valA === 'object' && valA !== null) valA = valA.first;
        if (typeof valB === 'object' && valB !== null) valB = valB.first;

        if (valA < valB) return this.sortAscending ? -1 : 1;
        if (valA > valB) return this.sortAscending ? 1 : -1;
        return 0;
      });

      this.$emit('update-items', sortedItems);
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.windowWidth = window.innerWidth;
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    localItems() {
      return this.items;
    },
    isSelected() {
      return (item) => {
        return this.selectedItems.some((selItem) => {
          return selItem[this.uniqueKey] === item[this.uniqueKey];
        });
      };
    },
    dynamicWidth() {
      return Number(this.windowWidth) - 340;
    },
  },
};
</script>

<style scoped>
.listName {
  font-weight: bold;
  font-size: 14pt;
  color: #271d7a;
  padding: 0px 10px 20px 10px;
  background-color: #ffffff;
}
.tableWrapper {
  overflow: auto;
  max-height: 100%;
  padding-bottom: 10px;
}

.tableWrapper th {
  position: sticky;
  top: 0;
  background: white;
}

.tableHeader {
  font-size: 12pt;
  font-weight: normal;
  color: #619bd6;
}
.tableText {
  color: #271d7a;
  font-size: 12pt;
}
.tableTextContainer {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
}
.tableContainer {
  padding: 20px 2px 20px 20px !important;
  background-color: #ffffff;
  height: 100%;
  overflow-y: hidden;
  padding-bottom: 50px !important;
  overflow-y: hidden;
}
.table {
  padding-left: 10px;
  padding-right: 30px;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.tableHeader {
  text-align: left;
  padding-right: 15px;
}
.tableCell {
  display: flex;
  flex-direction: column;
}
.tableIcons {
  width: 28px;
  padding-right: 15px;
}
.multiContainer {
  padding-bottom: 10px;
}
.clickableName {
  color: #271d7a;
  font-size: 12pt;
  font-weight: bold;
  cursor: pointer;
}
</style>
