<template>
  <div :class="{ outline: input.outlined }">
    <span class="radioInputLabel" :class="{ center: input.centered }">{{
      input.label
    }}</span>
    <div
      :class="{
        radioSelect: input.direction == 'row',
        radioSelectColumn: input.direction == 'column',
      }"
    >
      <div
        v-for="(option, index) in input.options"
        :key="index"
        class="radioOptionContainer"
        :class="{ center: input.centered }"
      >
        <CheckBox
          :multi-select="multiSelect"
          :item="option"
          :checked="submissionData[input.id] == option.value"
          @toggle-selected="toggleSelected"
        />
        <span class="radioOptionlabel formInputLabel">
          {{ option.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBox from "@/components/base_components/FormComponents/CheckBox.vue";

export default {
  name: "RadioComp",
  props: {
    input: Object,
    submissionData: Object,
    section: String,
    multiSelect: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  components: { CheckBox },
  methods: {
    toggleSelected(item) {
      if (this.multiSelect) {
        const index = this.selectedItems.indexOf(item);
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
        } else {
          this.selectedItems.push(item);
        }
        this.$emit("toggle-selected", this.selectedItems);
      } else {
        this.$emit("toggle-selected", item);
      }
    },
  },
  emits: ["toggle-selected"],
};
</script>

<style scoped>
.outline {
  outline: 1px solid #271d7a;
  padding: 5px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.radioInputLabel {
  display: flex;
  font-size: 9pt;
  color: #271d7a;
  font-weight: 500;
}
.radioOptionlabel {
  padding: 5px 15px 0px 3px;
}
.radioSelect {
  display: flex;
  flex-direction: row;
}
.radioSelectColumn {
  display: flex;
  flex-direction: column;
}

.radioOptionContainer {
  display: flex;
  flex-direction: row;
  padding-left: 2px;
}
</style>
