<template>
    <v-app>
        <v-app-bar height="50" v-if="loggedIn && INITIAL_APP_LOAD">
            <navBar 
                :userName="username.toUpperCase()"
                :showPhonePopoutProp="showPhonePopup"
                @close-clickPhoneIcon="handlePhonePopout"
                :showPhoneIconProp="showPhoneIcon"
                :hasUnreadNotifications="false"
                @logout-user="handleSignOut"
                />
        </v-app-bar>

        <v-navigation-drawer style="background: #2e3e91;" expand-on-hover rail permanent v-if="loggedIn && INITIAL_APP_LOAD">
            <Navigation />
        </v-navigation-drawer>

        <v-main v-if="INITIAL_APP_LOAD">
            <router-view :key="$route.path" />
            <AlertsGroup v-model:alerts="alerts" />
        </v-main>

    </v-app>
</template>


<style>
::-webkit-scrollbar {
    width: 10px;
    height: 10px !important;
}

::-webkit-scrollbar-track-piece {
    background-color: #ffffff
}

:root {
  --easy-table-scrollbar-thumb-color: #b1cdea !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b1cdea;
  border: 3px solid #fff;
}

.vue3-easy-data-table__main[data-v-19cc4b1b]::-webkit-scrollbar-track {
  border-radius: 0px !important;
}
.vue3-easy-data-table__main[data-v-19cc4b1b]::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
}

header.v-toolbar {
    overflow: visible;
}

html {
    overflow-y: auto !important;
}

body {
  font-family: "Red Hat Display", sans-serif;
}

.vue3-easy-data-table__main{
    overflow: revert !important;
}

.vgl-item:not(.vgl-item--placeholder){
    border: 0px !important;
    filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
}
</style>


<script setup>

const hostname = window.location.hostname;
// const last_mouse_move = ref(Date.now());

var ENVIRONMENT = "";

import { onMounted, watch, ref } from "vue";
import navBar from "@/components/base_components/Navigation/NavBar.vue";
import Navigation from "@/components/base_components/Navigation/MenuBar.vue";
import { router } from "@/router/index.js";
import { Amplify, Auth, Storage } from 'aws-amplify';
import { Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useAuthStore } from "@/stores/auth/authStore.js";
import { useWebRTCStore } from "./stores/webRTC/webRTCStore";
// import { API } from "aws-amplify";
// import * as mutations from "@/graphql/mutations.js";
// import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useAgentStore } from "@/stores/agentStore.js";
import { addAlert } from "@/helpers/alertUtils";
import { NOTIFICATION } from "@/constants/statuses.constants";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { onBeforeUnmount, onUnmounted } from "vue";
import { useGeneralStore } from "@/stores/generalStore.js";
import { useLEXStore } from "@/stores/ContactCenter/LEX/LEXStore.js";

const generalStore = useGeneralStore();

if(hostname.includes("test") || hostname.includes("staging")){
    ENVIRONMENT = hostname.split(".")[0].toLowerCase() + "-";
    generalStore.setRTFlag(false);
}
else if(hostname.includes("localhost") || hostname.includes("development")){
    ENVIRONMENT = "developer-";
    generalStore.setRTFlag(false);
}


var config = awsconfig;
config.aws_cloud_logic_custom = [
    {
        "name": "mail",
        "endpoint": `https://${ENVIRONMENT}api.lexconnect.cloud/mail`,
        "region": "us-east-1"
    },
    {
        "name": "google",
        "endpoint": `https://${ENVIRONMENT}api.lexconnect.cloud/google`,
        "region": "us-east-1"
    },
    {
        "name": "switch",
        "endpoint": `https://${ENVIRONMENT}api.lexconnect.cloud/switch`,
        "region": "us-east-1"
    },
    {
        "name": "switchPivots",
        "endpoint": `https://${ENVIRONMENT}pivot.lexconnect.cloud/switchPivots`,
        "region": "us-east-1"
    },
    {
        "name": "bandwidth",
        "endpoint": `https://${ENVIRONMENT}api.lexconnect.cloud/bandwidth`,
        "region": "us-east-1"
    },
    {
        "name": "apiMappings",
        "endpoint": `https://${ENVIRONMENT}api.lexconnect.cloud/api`,
        "region": "us-east-1"
    },
    {
        "name": "frontend",
        "endpoint": `https://${ENVIRONMENT}api.lexconnect.cloud/frontend`,
        "region": "us-east-1"
    },
];

Amplify.configure(config);
Auth.configure({storage: window.sessionStorage})






var loggedIn = ref(false);
// var defaultPath = ref("/");
var INITIAL_APP_LOAD = ref(false);
var username = ref("");
var showPhonePopup = ref(false);
var showPhoneIcon = ref(true);
var alerts = ref([]);

var WARNING_TIMER = null;
var TIMEOUT_NOW_TIMER = null;
var AGENT_SOCKET_TIMER = null;




const authStore = useAuthStore();
const webRTCStore = useWebRTCStore();
const agentStore = useAgentStore();


authStore.setCurrentEnvironment(ENVIRONMENT);

// Listeners
const hubListenerCancelToken = Hub.listen('auth', (data) => {
    switch (data.payload.event) {
        case 'signIn':
            loggedIn.value = true;
            break;
        case 'signOut':
            loggedIn.value = false;
            break;
        case 'signIn_failure':
            loggedIn.value = false;
            break;
        default:
            // console.log("Hub.listen - auth");
            // console.log(data);
            break;
    }
});
// End of Listeners










// Functions
async function refreshTokens() {
    if (loggedIn.value === false) { return; }

    try {
        const SESSION_COOKIE = checkIfSessionCookieAlive();

        if (SESSION_COOKIE) {
            const TIME_OUT_NOW = authStore.getTimeOutMinutes;

            setSessionCookie("session", TIME_OUT_NOW);
            resetSessionTimers();
            const cognitoUser = await Auth.currentAuthenticatedUser({
                bypassCache: true
            });
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.refreshToken, () => {});
        }
        else {
            handleSignOut();
        } 
    } catch (e) {
        //Do Nothing
    }
}



function startSessionTimers() {
    const TIME_OUT_NOW = authStore.getTimeOutMinutes;
    const TIME_OUT_WARNING = authStore.getTimeOutWarningMinutes;

    WARNING_TIMER = setTimeout(sendTimeOutWarning, TIME_OUT_WARNING * 60000);
    TIMEOUT_NOW_TIMER = setTimeout(timeOutUser, TIME_OUT_NOW * 60000);
}

function resetSessionTimers() {
    if (loggedIn.value === true) {
        clearTimeout(WARNING_TIMER);
        clearTimeout(TIMEOUT_NOW_TIMER);
        startSessionTimers();
    }
}

function sendTimeOutWarning() {
    const TIME_OUT_NOW = authStore.getTimeOutMinutes;
    const TIME_OUT_WARNING = authStore.getTimeOutWarningMinutes;
    const time_left = TIME_OUT_NOW - TIME_OUT_WARNING;

    addAlert(NOTIFICATION, `Inactive page. you will be logged out in ${time_left} minutes.`, alerts.value);
}

function timeOutUser() {
    const SESSION_COOKIE = checkIfSessionCookieAlive();

    if (!SESSION_COOKIE) {
        handleSignOut();
    }
    else {
        resetSessionTimers();
    }
}



function setSessionCookie(cname, exminutes) {
  const d = new Date();
  d.setTime(d.getTime() + (exminutes * 60 * 1000));
  document.cookie = `${cname}=${d.getTime()}; max-age=${exminutes * 60}; path=/`;
}

function getSessionCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

function checkIfSessionCookieAlive() {
    const SESSION_COOKIE = getSessionCookie("session");
    return SESSION_COOKIE;
}






const checkAuthenticatedUser = async () => {
    let user = null;
    if (!authStore.getCurrentUser) {
        try {
            user = await Auth.currentAuthenticatedUser();

            Storage.configure({
                region: awsconfig.aws_user_files_s3_bucket_region,
                bucket: awsconfig.aws_user_files_s3_bucket,
                identityPoolId: awsconfig.aws_user_pools_id
            });
        }
        catch (error) {
            user = null;
        }

        if (user) {
            await authStore.setCurrentUser(user);
        }
    }
    else {
        user = authStore.getCurrentUser;
    }




    if (user) {
        loggedIn.value = true;
        // await createUserIfNotThere(user);
        // await authStore.fetchUsersGroups();
        // await authStore.fetchSessionInformation(user);
        username.value = user.attributes.email;
        // await authStore.setDefaultApplicationPath();
        // defaultPath = await authStore.getDefaultApplicationPath;
        var foundRedirectPath = false;


        // console.log(defaultPath);

        // console.log(router.currentRoute);



        console.log(authStore.getToLocationArray);

        for (let location of authStore.getToLocationArray) {
            if (location.path != "/") {
                router.replace(location);
                foundRedirectPath = true;
                break;
            }
        }

        if(!foundRedirectPath) {
            router.replace({path: "/lex"});   
        }
    }
    else {
        loggedIn.value = false;
        router.replace('/');
    }
}


async function handleSignOut() {
    await Auth.signOut(); 
    loggedIn.value = false;
    await authStore.setCurrentUser(null);
    router.push("/");
}

const handlePhonePopout = async (inputVal) => {
    showPhonePopup.value = !inputVal;
}




async function webRTCCheck(authStore, webRTCStore) {
    if (authStore.getCurrentUser && authStore.getCurrentUser.username) {
        await webRTCStore.connectToWebRTC_phone(authStore.getCurrentUser.username);

        let webphone = webRTCStore.getLibWebPhone;

        if (webphone) {
            webphone.getUserAgent().start();
            showPhoneIcon.value = true;

            // TODO: this needs to stay for debugging purposes. uncomment if you need to debug. 
            // webphone.onAny((event, ...data) => console.log(event, data));

            webphone.on("call.created", (lwp, call) => {
                console.log("call created");
                console.log(call);
                webRTCStore.setCurrentCallType("Regular");

                let formatted_date_time = new Date().toLocaleString().replace(/(.*) (\d+:\d+:\d+)/, '$1 - $2').replace(",", "");
                let direction = call.getDirection();
                let identity = call.remoteIdentity(true);

                if (direction == "terminating") {

                    // set the webRTCStore values
                    webRTCStore.setCurrentCallCallerID(identity._uri._user);
                    webRTCStore.setCurrentCallCallerName(identity.display_name);
                    webRTCStore.addUpdateCallHistory(call._id, "incoming", formatted_date_time, identity.display_name, identity._uri._user);

                    //open up the phonepopout and migrate to the active page
                    if (!showPhonePopup.value) {
                        showPhonePopup.value = true;
                    }

                    webRTCStore.changeSelectedPage("incoming");
                }
                else {
                    webRTCStore.setCurrentCallCallerID(identity._uri._user);
                    webRTCStore.setCurrentCallCallerName(identity.display_name);
                    webRTCStore.setCurrentCallCallDuration(0);
                    //open up the phonepopout and migrate to the active page
                    if (!showPhonePopup.value) {
                        showPhonePopup.value = true;
                    }
                    webRTCStore.addUpdateCallHistory(call._id, "outgoing", formatted_date_time, identity.display_name, identity._uri._user);
                    webRTCStore.changeSelectedPage("active");
                }


                console.log("Current Call: ", webRTCStore.getCurrentCall);
                webRTCStore.setNewCallCallObj(call);
            });

            webphone.on("call.primary.timeupdate", (lwp, call, date_time, not_used, time) => {
                webRTCStore.setCurrentCallCallDuration(time);
                let call_history = webRTCStore.getCallHistory;
                let call_history_obj = call_history[call._id];
                webRTCStore.addUpdateCallHistory(call_history_obj.id, call_history_obj.direction, call_history_obj.date_time, call_history_obj.display_name, call_history_obj.number, time);
            });

            webphone.on("call.ended", (lwp, call, message) => {
                console.log(message)
                console.log(lwp);
                var sessions = lwp.getUserAgent()._userAgent._sessions;
                webRTCStore.setCurrentActiveSessions(sessions);
                console.log("Current Active Sessions:", webRTCStore.getCurrentActiveSessions);

                if (!webRTCStore.getCallsOnHold[call._id]) {
                    webRTCStore.setCurrentCallStatus("");
                    webRTCStore.changeSelectedPage("Keypad");
                    webRTCStore.clearMute();
                }
                if (webRTCStore.getCallsOnHold[call._id]) {
                    webRTCStore.removeCallOnHold(call._id);
                }
            });

            webphone.on("call.failed", (lwp, call, message) => {
                let call_history = webRTCStore.getCallHistory;
                let call_history_obj = call_history[call._id];
                let missed = false;

                if (call_history_obj.direction == "incoming") {
                    missed = true;
                }

                if (message.cause == "Rejected") {
                    if (!webRTCStore.getCallsOnHold[call._id]) {
                        webRTCStore.setCurrentCallStatus("");
                        webRTCStore.changeSelectedPage("Keypad");
                    }
                    webRTCStore.addUpdateCallHistory(call_history_obj.id, call_history_obj.direction, call_history_obj.date_time, call_history_obj.display_name, call_history_obj.number, call_history_obj.duration, missed);
                }

                if (message.cause == "Canceled") {
                    if (!webRTCStore.getCallsOnHold[call._id]) {
                        webRTCStore.setCurrentCallStatus("");
                        webRTCStore.changeSelectedPage("Keypad");
                    }
                    webRTCStore.addUpdateCallHistory(call_history_obj.id, call_history_obj.direction, call_history_obj.date_time, call_history_obj.display_name, call_history_obj.number, call_history_obj.duration, missed);
                }

                if (webRTCStore.getCallsOnHold[call._id]) {
                    webRTCStore.removeCallOnHold(call._id);
                    // TODO: need to figure out how to make the CallsOnHoldComp.vue update its list
                }
            });

            webphone.on("call.established", (lwp, call, message) => {
                console.log("call established");
                console.log(message);
                console.log(call);

              
                var sessions = lwp.getUserAgent()._userAgent._sessions;
                console.log(sessions);
                webRTCStore.setCurrentActiveSessions(sessions);
                console.log("Current Active Sessions:", webRTCStore.getCurrentActiveSessions);


                webRTCStore.setCurrentCallCallObj(call);
                webRTCStore.setCurrentCallStatus("active");
                let identity = call.remoteIdentity(true);

                if (identity._uri._user != "*98") {
                    webRTCStore.changeSelectedPage("active");
                }


            });

            webphone.on("call.hold", (lwp, call, message) => {
                console.log(message);
                // call._clearPrimary();

                let call_history = webRTCStore.getCallHistory;
                let call_history_obj = call_history[call._id];

                let direction = call.getDirection();
                if (direction == "terminating") {
                    direction = "incoming";
                }
                else {
                    direction = "outgoing";
                }

                webRTCStore.addUpdateCallsOnHold(call_history_obj.id, call_history_obj.display_name, call_history_obj.direction, call_history_obj.date_time, call_history_obj.number, call_history_obj.duration, call);
                webRTCStore.setCurrentCallStatus("");
                // webRTCStore.setCurrentCallCallObj(null);
                webRTCStore.changeSelectedPage("Calls On Hold");
            });
        }
    }
}
// End of Functions




// Life Cycle Hooks
onMounted(async () => {
    await checkAuthenticatedUser();
    await webRTCCheck(authStore, webRTCStore);
    INITIAL_APP_LOAD.value = true;
});


onBeforeUnmount(() => {
    hubListenerCancelToken();
    document.removeEventListener('mousedown', refreshTokens);

    console.log("App.vue Unmounted");
    agentStore.closeAgentSocket();
    clearTimeout(AGENT_SOCKET_TIMER);
});

onUnmounted(() => {
    console.log("App.vue Unmounted");
    agentStore.closeAgentSocket();
    clearTimeout(AGENT_SOCKET_TIMER);
});



// TODO: NEED TO SETUP ONCE WE HAVE PERMISSIONS
watch(loggedIn, async (newValue) => {
    INITIAL_APP_LOAD.value = false;

    if (newValue == true) {
        await checkAuthenticatedUser();
        INITIAL_APP_LOAD.value = true;
       
    }
    else {
        router.push("/");
        INITIAL_APP_LOAD.value = true;
    }
});

watch(showPhonePopup, async (newValue) => {
    console.log("showPhonePopup: ", newValue);

    if (newValue == true) {
        setTimeout(async () => {
            webRTCStore.setCurrentCallCallerID("Jim Stevens");
            webRTCStore.setCurrentCallCallerName("Jim Stevens");
            webRTCStore.setCurrentCallFromQueue("LogicomUSA");
            webRTCStore.changeSelectedPage("incoming");
            webRTCStore.setCurrentCallType("Inbound");

            await new Promise(r => setTimeout(r, 2000));

            const lexStore = useLEXStore();
            lexStore.setIncomingCall({
                "callerID": "Jim Stevens",
                "callerName": "Jim Stevens",
                "fromQueue": "LogicomUSA",
            });


        }, 5000);
    }

});



// End of Life Cycle Hooks

</script>
