<template>
  <div class="questionContainer" ref="form">
    <template v-for="(question, index) in questions" :key="index">
      <FlowInput
        v-if="index <= currentIndex"
        :question="question"
        :index="index"
        :selectedFlow="selectedFlow"
        :formData="submissionData"
        :LESFlag="LESFlag"
        @toggle="toggleCard(index)"
        @toggleNested="(dependent) => toggleNested(index, dependent)"
        @addDependentToFlow="handleAddDependent"
        @removeQuestion="handleRemoveQuestion"
        @currentIndex="handleCurrentIndex"
        @updateFlowNav="updateNav"
        @submission-data="handleSubmissionData"
        @remove-key="handleRemoveDependent"
        @update-downloaded-flow-segment="updateDownloadedFlowSegment"
        @scrollToBottom="scrollToBottom"
      />
    </template>
    <div ref="lastChild"></div>
  </div>
</template>

<script>
import FlowInput from "./FlowInput.vue";

export default {
  name: "CurrentFlow",
  props: {
    selectedFlow: Object,
    drawerOpen: Boolean,
    questions: Array,
    editFlow: Object,
    clearFlow: Boolean,
    LESFlag: Boolean,
    sectionTitle: {
      type: String,
      default: ''
    },
    isItemClicked: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      submissionData: {},
      currentIndex: 0,
    };
  },
  methods: {
    toggleCard(index, depIndex, dependent) {
      this.$emit("toggle", index, "flow", dependent);
    },
    toggleNested(index, dependent) {
      this.$emit("toggle", index, "flow", dependent);
    },
    handleAddDependent(option, question) {
      this.$emit("addDependentToFlow", option, question);
    },
    handleRemoveQuestion(question, dependent) {
      this.$emit("removeQuestion", question, dependent);
    },
    updateDownloadedFlowSegment(dependents, question) {
      this.$emit("update-downloaded-flow-segment", dependents, question);
      this.$nextTick(() => {
        this.$emit('updateNav');
      });

    },
    handleSubmissionData(submissionData) {
      this.submissionData = { ...this.submissionData, ...submissionData };
      this.$emit('updatedSubmissionData', this.submissionData);
      this.$nextTick(() => {
        this.$emit('updateNav');
      });
    },
    updateNav() {
      this.$nextTick(() => {
        this.$emit('updateNav');
      });
    },
    handleRemoveDependent(key) {
      delete this.submissionData[key];
    },
    handleCurrentIndex() {
      let questionType = this.questions[this.currentIndex]?.type;
      if (
        this.currentIndex < this.questions.length - 1 &&
        (this.submissionData[this.questions[this.currentIndex].id] ||
          questionType === "Display Only" ||
          questionType === "Display In Notes" ||
          questionType === "Smart Link")
      ) {
        this.currentIndex++;
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
      const lastChildElement = this.$refs.lastChild;
        lastChildElement?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      });
    },
  },
  updated() {
    this.sections = this.$refs.form.querySelectorAll('.flowFormContent');

    const elements = this.$refs.form.querySelectorAll('.flowInputLabel');
    if (this.sectionTitle.length > 0) {
      elements.forEach(item => {
        item.classList.remove('active');

        if (item.dataset.question_id === this.sectionTitle) {
          item.classList.add('active');
        }
      }); 
    } else {
      elements.forEach(item => {
        item.classList.remove('active');
      });

      if (elements.length > 0) {
        const lastElement = elements[elements.length - 1];
        lastElement.classList.add('active');
      }
    }
  },
  components: {
    FlowInput,
  },
  computed: {
    localIsItemClicked: {
      get () {
        return this.isItemClicked;
      },
      set (value) {
        this.$emit('update:isItemClicked', value);
      }
    }
  },
  watch: {
    sectionTitle (value) {
      let input = null;
      this.$refs.form.querySelectorAll('.flowInputLabel').forEach(item => {
        if (item.dataset.question_id === value && item.offsetParent) {
          input = item;
        }
      })

      if (input) {
        const targetPosition = input.offsetTop - 250;
        this.$nextTick(() => {
          this.$refs.form.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
        });
      }
    },
    currentIndex: {
      deep: true,
      immediate: true,
      async handler() {
        this.localIsItemClicked = false;
        
        await this.$nextTick();

        await new Promise(resolve => setTimeout(resolve, 200));
        this.scrollToBottom();

        this.localIsItemClicked = true;
      },
    },
    clearFlow: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.currentIndex = 0;
          this.submissionData = {};
        }
      },
    },
    questions: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            // this.currentIndex += 1;
            this.$emit('updateNav');
          });
        }
      },
    }
  },
  emits: ["removeQuestion", "toggle", "updateNav", "addDependentToFlow", "update-downloaded-flow-segment", "updatedSubmissionData"],
};
</script>

<style scoped>
.questionContainer {
  outline: 1px solid #271d7a;
  margin-top: 10px;
  height: 100%;
  overflow: auto;
}
</style>
