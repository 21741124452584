import { defineStore } from 'pinia'
import { Auth, API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

export const useAuthStore = defineStore('Auth', {
    state: () => ({
        current_user: null,
        current_db_user: null,
        current_environment: null,
        user_management_secret: null,
        users_groups: null,
        users_applications: null,
        default_application_path: null,
        switch_web_socket_url: null,
        TIME_OUT_WARNING_MINUTES: null,
        TIME_OUT_MINUTES: null,
        router_to_location_array: []
    }), 
    getters: {
        getCurrentUser(state) {
            return state.current_user;
        },
        getCurrentEnvironment(state) {
            return state.current_environment;
        },
        getUserManagementSecret(state) {
            return state.user_management_secret;
        },
        getUsersGroups(state) {
            return state.users_groups;
        },
        getUsersApplications(state) {
            return state.users_applications;
        },
        getCurrentDBUser(state) {
            return state.current_db_user;
        },
        getDefaultApplicationPath(state) {
            return state.default_application_path;
        },
        getSwitchWebSocketUrl(state) {
            return state.switch_web_socket_url;
        },
        getTimeOutWarningMinutes(state) {
            return state.TIME_OUT_WARNING_MINUTES;
        },
        getTimeOutMinutes(state) {
            return state.TIME_OUT_MINUTES;
        },
        getToLocationArray(state) {
            return state.router_to_location_array;
        }
    },
    actions: {
        async setCurrentUser(user) {
            this.current_user = user;
           
            // const api_name = "frontend";
            // const path = "/get_secret";

            // const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
            
            // var bodyJSON = {
            //     secret_id: "cognito"
            // }

            // const myInit = {
                // body: bodyJSON,
                // headers: {
                    // Authorization: userAuth,
                    // "Content-Type": "application/json"
                // }  
            // }

            try {
                // const response = await API.post(api_name, path, myInit);
                // this.user_management_secret = response.data;
            }
            catch(error) {
                console.log("Do nothing for now");
            }

            try {
                await this.fetchUserDBInfo();
            } catch {
                return;
            }
        },
        setCurrentEnvironment(environment) {
            this.current_environment = environment;
        },
        async updateDefaultApplication(value) {
            if(!value) {
                return { error: true, message: "Unable to update starting application."};
            }

            const updateUsers = /* GraphQL */ `
            mutation UpdateUsers(
              $input: UpdateUsersInput!
              $condition: ModelUsersConditionInput
            ) {
              updateUsers(input: $input, condition: $condition) {
                id
                starting_application_id
              }
            }
          `;

            const options = {
                query: updateUsers,
                variables: {
                    input: {
                        _version: this.current_db_user._version,
                        id: this.current_db_user.id,
                        starting_application_id: value,
                    },
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            try {
                const result = await API.graphql(options);

                if (result.errors) {
                    return { error: true, message: result.errors };
                }

                await this.fetchUserDBInfo();

                return { error: false, message: 'Ok' };
            }
            catch (error) {
                console.error("Caught an error in updateUser:", error);
                return { error: true, message: error };
            }
        },
        async getFirstApplication() {
            const sectionOrder = ["Workspace", "Analytics", "Billing", "Contact Center", "LES", "Switch Ops", "Tickets", "Voice", "Admin"];
            
            let firstApplicationRoute = null;
            let firstApplicationName = null;
            let firstApplicationID = null;
        
            for (let i = 0; i < sectionOrder.length; i++) {
                const section = sectionOrder[i];
                
                const appsInSection = this.users_applications.filter(app => app.application_section === section);
                
                for (let j = 0; j < appsInSection.length; j++) {
                    const app = appsInSection[j];
                    
                    if (!app.route || app.route.trim() === "") continue;
        
                    if (!firstApplicationRoute || (app.name < firstApplicationName)) {
                        firstApplicationRoute = app.route;
                        firstApplicationName = app.name;
                        firstApplicationID = app.application_id;
                    }
                }
        
                if (firstApplicationRoute) break;
            }

            return {route: firstApplicationRoute, name: firstApplicationName, application_id: firstApplicationID};
        },
        async setDefaultApplicationPath() {  
            var currentStartingApplicationID = this.current_db_user.starting_application_id;
            var firstApplication = await this.getFirstApplication();
            var id = firstApplication.application_id;
            if(!currentStartingApplicationID) {
                var updateResults = await this.updateDefaultApplication(id);
                if(updateResults.error) {
                    this.default_application_path = "/admin";
                } else {
                    this.default_application_path = firstApplication.route;
                }
            } else {
                //Validate that the id is still valid
                var foundObj = this.users_applications.find(x => x.application_id == currentStartingApplicationID);    
                if (!foundObj) { this.default_application_path = firstApplication.route; return;}
                
                if(Object.keys(foundObj).length == 0) {
                    var results = await this.updateDefaultApplication(id);
                    if(results.error) {
                        this.default_application_path = "/admin";
                    } else {
                        this.default_application_path = firstApplication.route;
                    }
                } else {
                    this.default_application_path = foundObj.route;
                }
            }
        },                      
        async fetchUsersGroups() {
            const user = await Auth.currentAuthenticatedUser();
            const users_groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        
            var filterArray = [];
        
            if (Array.isArray(users_groups)) {
                for (var group of users_groups) {
                    filterArray.push({ cognito_name: { eq: group } });
                }
            } else {
                console.log("No groups or non-iterable groups for the user");
                return; 
            }
        
            const listGroupsQuery = /* GraphQL */ `
                query ListGroups($filter: ModelGroupsFilterInput, $limit: Int, $nextToken: String) {
                    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                            Name
                            cognito_name
                            PageLevelPermissions
                            createdAt
                            Applications (filter: {_deleted: {ne: true}}, limit: 2000) {
                                items {
                                    allowed_permissions
                                    application {
                                        name
                                        application_section
                                        is_child_application
                                        route
                                    } 
                                    application_id
                                }
                            }
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            __typename
                        }
                        nextToken
                        startedAt
                        __typename
                    }
                }
            `;
        
            let nextToken = null;
            let users_groups_data = [];
        
            try {
                do {
                    const options = {
                        query: listGroupsQuery,
                        variables: {
                            filter: {
                                or: filterArray,
                                _deleted: { ne: true }
                            },
                            limit: 1000,
                            nextToken: nextToken
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };
        
                    const result = await API.graphql(options);
                    users_groups_data = users_groups_data.concat(result.data.listGroups.items);
                    nextToken = result.data.listGroups.nextToken;
                } while (nextToken);
        
                this.users_groups = users_groups_data;
                //console.log("users_groups", this.users_groups);
        
                var users_applications = [];
                for (var userGroup of this.users_groups) {
                    for (var appObject of userGroup.Applications.items) {
                        var duplicateIndex = users_applications.findIndex(app => app.name === appObject.application.name && app.application_section === appObject.application.application_section);
        
                        //stacking apps and permissions
                        if (duplicateIndex === -1) {
                            appObject.application['allowed_permissions'] = appObject.allowed_permissions || "";
                            users_applications.push({...appObject.application, ...{application_id: appObject.application_id}});
                        } else {
                            let existingPermissions = users_applications[duplicateIndex].allowed_permissions ? users_applications[duplicateIndex].allowed_permissions.split(',') : [];
                            let newPermissions = appObject.allowed_permissions ? appObject.allowed_permissions.split(',') : [];
                            let combinedPermissions = [...new Set([...existingPermissions, ...newPermissions])];
                            users_applications[duplicateIndex].allowed_permissions = combinedPermissions.join(',');
                        }  
                    } 
                }
        
                //console.log("users_applications", users_applications);  
                this.users_applications = users_applications;
            } catch (error) {
                console.log("Error fetching user groups:", error);
            }
        },      
        async fetchUserDBInfo() {
            var username = this.current_user.username;

            const getUsers = /* GraphQL */ `
            query GetUsers($id: ID!) {
                getUsers(id: $id) {
                id
                partner
                username
                first_name
                last_name
                bookmarked_tickets
                favorite_apps
                notifications {
                    nextToken
                    startedAt
                    __typename
                }
                has_subscribed_reports
                subscribed_reports
                favorite_reports
                webrtc_config_s3_key
                workspace_layout
                subscribed_systems
                maintenanceMessages
                emailNotifications
                disabled
                agent_account_id
                starting_application_id
                agent_recipient_id
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
                }
            }`;

            let options = {
                query: getUsers,
                variables: {
                    id: username
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            }

            let result = await API.graphql(options);
            //console.log("fectchDBUserResult", result.data.getUsers)
            if (result.data.getUsers && !result.data.getUsers._deleted) {
                if(Object.keys(result.data.getUsers).length > 0) {
                    this.current_db_user = result.data.getUsers;
                }
            }


            console.log("THIS CURRENT DB USER: ", this.current_db_user);

            const listPartners = /* GraphQL */ `
            query ListPartners(
                $filter: ModelPartnersFilterInput
                $limit: Int
                $nextToken: String
            ) {
                listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                    partner
                    switch_web_socket_url
                }
                nextToken
                startedAt
                __typename
                }
            }`;

            let partner_options = {
                query: listPartners,
                variables: {
                    filter: {
                        partner: {
                            eq: this.current_db_user.partner
                        }
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            }

            let partner_result = await API.graphql(partner_options);

            this.switch_web_socket_url = partner_result.data.listPartners.items[0]?.switch_web_socket_url;
        },
        async validateAdminStatus(superuserOnly = false) {
            var user_groups = this.current_user.signInUserSession.accessToken.payload['cognito:groups'];
            //console.log(user_groups);
            if(superuserOnly) {
                if ( user_groups.includes("SuperUser") ) {
                    return true;
                }
            }

            var partner = this.current_db_user.partner;

            if(partner == "LogicomUSA" || user_groups.includes("SuperUser")) {
                return true;
            }

            return false;
        },
        async fetchSessionInformation(user) {
            this.current_user = user;
           
            const api_name = "frontend";
            const path = "/get_secret";

            const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
            
            var bodyJSON = {
                secret_id: "session_timeout"
            }

            const myInit = {
                body: bodyJSON,
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                }  
            }

            try {
                const response = await API.post(api_name, path, myInit);
                console.log("SessionInformation response", response);
                
                if (response.data) {
                    this.TIME_OUT_WARNING_MINUTES = response.data.TIME_OUT_WARNING_MINUTES;
                    this.TIME_OUT_MINUTES = response.data.TIME_OUT_MINUTES;
                }
            }
            catch(error) {
                console.log("Do nothing for now");
            }

        },
        async pushToLocationArray(location) {
            this.router_to_location_array.push(location);
        }
    },
})