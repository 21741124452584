import { defineStore } from "pinia";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { queryGraphQL } from "@/helpers/dbUtil.js";
import * as queries from "@/graphql/queries.js";
// import * as mutations from "@/graphql/mutations.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { useAuthStore } from "@/stores/auth/authStore.js";
/* eslint-disable */
export const useDashboardStore = defineStore("Dashboard", {
    state: () => ({
        agents_table_data: [],
        agents_unchanged_data: {},
        agent_filter_options: {},
        queues_table_data: [],
        queues_filtered_data: [],
        queue_filter_options: {},
        selected_queue_filter: "",
        agent_list: {},
        queue_list: {},
        selected_queue: "",
        queue_members: {},
    }),
    getters: {
        getAgentsData(state) {
            return state.agents_table_data;
        },
        getAgentsFilterOptions(state) {
            return state.agent_filter_options;
        },
        getQueueData(state) {
            if (state.selected_queue_filter === "") {
                return state.queues_table_data;
            } else {
                return state.queues_filtered_data;
            }
        },
        getQueuesFilterOptions(state) {
            return state.queue_filter_options;
        },
        getSelectedQueue(state) {
            return state.selected_queue;
        },
        DashboardSocket(state) {
            return state.DashboardSocket;
        }
    },
    actions: {
        toHHMMSS(secs) {
            var sec_num = parseInt(secs, 10)
            var hours = Math.floor(sec_num / 3600)
            var minutes = Math.floor(sec_num / 60) % 60
            var seconds = sec_num % 60

            return [hours, minutes, seconds]
                .map(v => v < 10 ? "0" + v : v)
                .filter((v, i) => v !== "00" || i > 0)
                .join(":")
        },
        async fetchSupervisors(agentName = null) {
            var error_message = "Unable to Get Supervisors";
            try {
                var hasData = true;
                var nextToken = null;
                var supervisorsSet = new Set();
        
                const normalizedAgentName = agentName ? agentName.toLowerCase().replace(/\s+/g, '') : null;
        
                while (hasData) {
                    var options = {
                        query: queries.listEmployees,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                                assigned_group: { eq: "Contact Center" }
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }
        
                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }
        
                    var result = await API.graphql(options);
        
                    for (const item of result.data.listEmployees.items) {
                        const itemNameNormalized = item.name.toLowerCase().replace(/\s+/g, '');
        
                        if (normalizedAgentName && itemNameNormalized === normalizedAgentName) {
                            return item.supervisor || "N/A";
                        }
                        if (!normalizedAgentName && item.supervisor && !supervisorsSet.has(item.supervisor)) {
                            supervisorsSet.add(item.supervisor);
                        }
                    }
        
                    if (result.data.listEmployees.nextToken != null) {
                        nextToken = result.data.listEmployees.nextToken;
                    } else {
                        hasData = false;
                    }
                }
        
                if (normalizedAgentName) {
                    return "N/A";
                }
                
                return Array.from(supervisorsSet);
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        },                 
        filterAgentData(filters = {}) {
            if (Object.keys(filters).length === 0) {
                this.agents_table_data = [...this.agents_unchanged_data];
                return;
            }
        
            this.currentFilters = filters;
            var filteredData = [];
        
            for (let record of this.agents_unchanged_data) {
                let agentStatus = record.status.first.split('\n')[0];
        
                let agentNameMatch = !filters.agentName || filters.agentName === "" || record.agentName === filters.agentName;
                let statusMatch = !filters.status || filters.status === "" || agentStatus.includes(filters.status);
                let supervisorMatch = !filters.supervisor || filters.supervisor === "" || record.supervisor === filters.supervisor;
        
                if ((agentNameMatch && (!filters.status || filters.status === "") && (!filters.supervisor || filters.supervisor === "")) ||
                    (statusMatch && (!filters.agentName || filters.agentName === "") && (!filters.supervisor || filters.supervisor === "")) ||
                    (supervisorMatch && (!filters.agentName || filters.agentName === "") && (!filters.status || filters.status === "")) ||
                    (agentNameMatch && statusMatch && supervisorMatch)) {
                    filteredData.push(record);
                }
            }
        
            this.agents_table_data = filteredData;
        },                       
        updateAgentTableData(newData) {
            this.agents_table_data = newData;
        },
        setAgentFilters() {
            var agent_name_options = [{ label: "None", value: "" }];

            for (let record of this.agents_table_data) {
                let name = record.agentName ?? "";

                if (name !== "") {
                    agent_name_options.push({ label: name, value: name });
                }
            }

            agent_name_options = agent_name_options.filter(
                (obj, index, self) =>
                    index === self.findIndex((o) => o.label === obj.label && o.value === obj.value)
            );

            this.agent_filter_options = {
                agentName: agent_name_options,
            };
        },      
        async setAgentList() {
            var api_name = "switch";
            var path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_recipients`;

            var userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
            }

            let result_json = await API.get(api_name, path, myInit);

            var usersData = await this.retrieveAllContactCenterUsers();


            var agents = result_json.data.data;
            var agent_ids = [];

            for (var agent of agents) {
                var presence_id = "";
                for (var user of usersData) {
                    if (user.user_id == agent.id) {
                        presence_id = user.presence_id;
                    }
                }

                const supervisorName = await this.fetchSupervisors(agent.display_name);

                this.agent_list[agent.id] = { display_name: agent.display_name, email: agent.name, presence_id: presence_id, supervisor: supervisorName};
                agent_ids.push(agent.id);
            }

            api_name = "switch";
            path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_recipients/status`;

            userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {
                    data: {
                        recipient_ids: agent_ids
                    }
                }
            }

            result_json = await API.post(api_name, path, myInit);

            var agent_statuses = result_json.data.data;

            if (this.agents_table_data.length == 0) {
                for (const [key, value] of Object.entries(agent_statuses)) {
                    var availability_state = value.availability_state;

                    if (value == "not_logged_in") {
                        availability_state = "Logged Out";
                        continue; //TODO: REMOVE THIS TO ADD LOGGED OUT AGENTS: NEEDED FOR SECOND RELEASE
                    }

                    let agent_status;
                    if (availability_state === "On-A-Call" && value.handling_call && value.handling_call.queue_name) {
                        let callerId = value.handling_call.caller_id_num ? `</br>${value.handling_call.caller_id_num}` : "";
                        agent_status = `On-A-Call</br>${value.handling_call.queue_name}${callerId}`;
                    } else {
                        agent_status = availability_state; 
                    }                                   

                    var gregorian_offset = 62167219200;
                    var timeInCurrentStatus = Math.floor(Date.now() / 1000) - (value.stats.last_action_time - gregorian_offset);
                    var abandoned_calls = value.stats.offered_calls - (value.stats.total_calls + value.stats.missed_calls);

                    var agent_tickets = await this.getAgentsTicketsForToday(key);
                    var outboundCallsCount = await this.getAgentOutboundCalls(key, this.agent_list[key].presence_id, (value.stats.login_time - gregorian_offset));
                    var average_recovery_time = await this.getAgentAverageRecoveryTime(key);

                    var ticketResolvedPercent = 0;
                    var ticketEscalatedPercent = 0;
                    if (agent_tickets.totalTickets != 0) {
                        var ticketResolvedPercent = Number(agent_tickets.resolvedTickets) / Number(agent_tickets.totalTickets) * 100;
                        var ticketEscalatedPercent = Number(agent_tickets.escalatedTickets) / Number(agent_tickets.totalTickets) * 100;
                    }

                    this.agents_table_data.push({
                        recipient_id: key,
                        agentName: this.agent_list[key].display_name,
                        agentEmail: this.agent_list[key].email,
                        status: { first: agent_status, second: timeInCurrentStatus },
                        callsCurrentShift: value.stats.offered_calls,
                        outboundCalls: outboundCallsCount,
                        answeredCalls: value.stats.total_calls,
                        missedCalls: value.stats.missed_calls,
                        abandonedCalls: abandoned_calls,
                        ticketsResolved: { first: agent_tickets.resolvedTickets, second: `${ticketResolvedPercent}%` },
                        ticketsEscalated: { first: agent_tickets.escalatedTickets, second: `${ticketEscalatedPercent}%` },
                        averageRecoveryTime: average_recovery_time,
                        supervisor: this.agent_list[key].supervisor || "N/A",
                    })
                }
            }

            // Sort the data
            this.agents_table_data.sort((a, b) => a.agentName.localeCompare(b.agentName));

            //console.log("this.agents_table_data", this.agents_table_data);
           
            this.agents_unchanged_data = [...this.agents_table_data].sort((a, b) => a.agentName.localeCompare(b.agentName));

            this.setAgentFilters();
        },
        updateQueuesTableData(newData) {
            this.queues_table_data = newData;
        },
        setFilter(name) {
            this.selected_queue_filter = name;
        },
        fetchQueueData() {
            //TODO: Call to get the Data

            //TODO: Update the unchanged data
            // this.queues_unchanged_data = [
            //   {
            //     queueName: "ACE Fiber Technician",
            //     answeredCalls: 50,
            //     callsWaitingAndWaitTime: { first: 23, second: "00:34" },
            //     inboundAndOutboundCalls: { first: 34, second: "12" },
            //     missedAndAbandonedCalls: { first: 34, second: "12%" },
            //     callsHandedToOverflow: 15,
            //     newTickets: 5,
            //     logicomUSAAndOverflowTickets: { first: "34%", second: "12%" },
            //     ticketsEscalated: { first: "15", second: "20" },
            //     csrEscalations: 3,
            //     agentsReady: 12,
            //     agentsOnPhoneAndAway: { first: 34, second: 10 },
            //     callsWaitingInQueue: 7,
            //     averageWaitTime: "00:12",
            //     longestHoldTimeCurrent: "00:22",
            //     longestHoldTimeDay: "00:34",
            //     callbacksScheduled: 4,
            //     "Average Recovery Time": "00:23",
            //   },
            //   {
            //     queueName: "Central Access",
            //     answeredCalls: 50,
            //     callsWaitingAndWaitTime: { first: 23, second: "00:34" },
            //     inboundAndOutboundCalls: { first: 34, second: "12%" },
            //     missedAndAbandonedCalls: { first: 34, second: "12%" },
            //     callsHandedToOverflow: 15,
            //     newTickets: 5,
            //     logicomUSAAndOverflowTickets: { first: "34%", second: "12%" },
            //     ticketsEscalated: { first: "15", second: "20" },
            //     csrEscalations: 3,
            //     agentsReady: 12,
            //     agentsOnPhoneAndAway: { first: 34, second: "10" },
            //     callsWaitingInQueue: 7,
            //     averageWaitTime: "00:23",
            //     longestHoldTimeCurrent: "00:23",
            //     longestHoldTimeDay: "00:23",
            //     callbacksScheduled: 4,
            //     "Average Recovery Time": "00:23",
            //   },
            //   {
            //     queueName: "LREC",
            //     answeredCalls: 50,
            //     callsWaitingAndWaitTime: { first: 23, second: "00:34" },
            //     inboundAndOutboundCalls: { first: 34, second: "12%" },
            //     missedAndAbandonedCalls: { first: 34, second: "12%" },
            //     callsHandedToOverflow: 15,
            //     newTickets: 5,
            //     logicomUSAAndOverflowTickets: { first: "34%", second: "12%" },
            //     ticketsEscalated: { first: "15", second: "20" },
            //     csrEscalations: 3,
            //     agentsReady: 12,
            //     agentsOnPhoneAndAway: { first: 34, second: "10" },
            //     callsWaitingInQueue: 7,
            //     averageWaitTime: "00:23",
            //     longestHoldTimeCurrent: "00:23",
            //     longestHoldTimeDay: "00:23",
            //     callbacksScheduled: 4,
            //     "Average Recovery Time": "00:23",
            //   },
            // ];

            // this.queues_table_data = this.queues_unchanged_data;

            // this.setQueueFilters();

            // return { error: false, message: "okay" };
        },
        filterQueueData(filters = {}) {
            if (this.selected_queue_filter == "") {
                this.queues_filtered_data = this.queues_table_data;
            } else {
                var filteredData = [];

                for (let record of this.queues_table_data) {
                    if (Object.prototype.hasOwnProperty.call(filters, "queueName")) {
                        if (
                            record.queueName != filters.queueName &&
                            typeof filters.queueName != "undefined"
                        ) {
                            continue;
                        }
                    }
                    filteredData.push(record);
                }
                this.queues_filtered_data = filteredData;
            }
        },
        setQueueFilters() {
            var queue_options = [];

            for (let record of this.queues_table_data) {
                let name = record.queueName ?? "";
                if (name !== "") {
                    queue_options.push({ label: name, value: name });
                }
            }

            queue_options = queue_options.filter(
                (obj, index, self) =>
                    index ===
                    self.findIndex((o) => o.label === obj.label && o.value === obj.value)
            );

            this.queue_filter_options = {
                queueName: queue_options,
            };
        },
        searchRecord(searchValue, recordObj) {
            for (var field of recordObj) {
                if (typeof field === "object") {
                    var foundInObj = this.searchRecord(searchValue, field);
                    if (foundInObj) {
                        return true;
                    }
                } else {
                    if (field.includes(searchValue)) {
                        return true;
                    }
                }
            }
            return false;
        },
        async initializeSwitchSocket() {
            const api_name = "switch";
            const path = `/get_auth_token`;

            const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            const myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {}
            }

            let result_json = await API.put(api_name, path, myInit);


            const authStore = useAuthStore();
            const websocket_url = authStore.getSwitchWebSocketUrl;

            var DashboardSocket = new WebSocket(websocket_url);

            //TODO: Make Socket Connection
            DashboardSocket.onopen = function (data) {
                //A subscription must include a valid pair of account_id / auth_token, and must include the binding that you want to listen to.
                var queuesSubscription = {
                    action: 'subscribe',
                    auth_token: result_json.data.auth_token,
                    data: {
                        account_id: '519b73ddf6c2ba5688acc12e9d0c01d4',
                        binding: 'qubicle.queue',
                    }
                };

                var recipientSubscription = {
                    action: 'subscribe',
                    auth_token: result_json.data.auth_token,
                    data: {
                        account_id: '519b73ddf6c2ba5688acc12e9d0c01d4',
                        binding: 'qubicle.recipient',
                    }
                };


                //bindings we might want: qubicle.recipient, dashboard.callcenter_pro, qubicle.queue
                //The data flowing through sockets must be a String, so we cast the JSON into a String
                var queuesSubscriptionString = JSON.stringify(queuesSubscription);
                var recipientSubscriptionString = JSON.stringify(recipientSubscription);
                //Once we properly configured our subscription, we can send the corresponding string to the WebSocket, which will notify the system that we want to subscribe to an event
                DashboardSocket.send(queuesSubscriptionString);
                DashboardSocket.send(recipientSubscriptionString);
            };


            return DashboardSocket;
        },
        async updateQubicleQueueData(event) {
            var queueFound = false;
            var agentsOnPhone = 0;
            var agentsAway = event.data.active_recipient_count - event.data.available_recipient_count;
            var agents_array = event.data.members;

            for (var agent of this.agents_table_data) {
                if (agents_array.includes(agent.recipient_id)) {
                    if (agent.status.first == "On-A-Call" || agent.status.first == "On-External-Call") {
                        agentsOnPhone++;
                    }
                }
            }

            agentsAway = agentsAway - agentsOnPhone;
            var outboundCallsCount = await this.getQueueOutboundCalls(event.data.queue_name.trim());
            var callsHandedToOverflowCount = await this.getQueueOverflowCalls(event.data.queue_name.trim());
            var queueTicketInformation = await this.getQueueTicketsForToday(event.data.queue_name);

            var queue_object = {
                queueName: event.data.queue_name, //Valid Data
                answeredCalls: event.data.stats.total_sessions - (event.data.stats.missed_sessions + event.data.stats.abandoned_sessions), //Valid Data
                callsWaitingAndWaitTime: { first: event.data.stats.active_session_count, second: this.toHHMMSS(event.data.stats.estimated_wait) }, //First not Valid, Second Valid
                inboundAndOutboundCalls: { first: event.data.stats.total_sessions, second: outboundCallsCount }, //Not Valid
                missedAndAbandonedCalls: { first: event.data.stats.missed_sessions, second: event.data.stats.abandoned_sessions }, //Valid Data
                callsHandedToOverflow: callsHandedToOverflowCount, //Not Valid
                newTickets: queueTicketInformation.new_tickets_count,
                logicomUSAAndOverflowTickets: { first: "82%", second: "18%" }, //Not Valid
                ticketsEscalated: { first: queueTicketInformation.internal_internet_escalations, second: queueTicketInformation.external_internet_escalations }, //Not Valid
                csrEscalations: queueTicketInformation.open_csr_escalations, //Not Valid
                agentsReady: event.data.available_recipient_count, //Available-Recipient-Count: Count of currently available recipients (ready to take a call) - I Think its Valid
                agentsOnPhoneAndAway: { first: agentsOnPhone, second: agentsAway }, //First: I think valid, Second: I think valid
                callsWaitingInQueue: event.data.stats.active_session_count, //Not Valid
                averageWaitTime: this.toHHMMSS(event.data.stats.average_wait), //Valid
                longestHoldTimeCurrent: `00:00`, //Dont know how to get
                longestHoldTimeDay: this.toHHMMSS(event.data.stats.estimated_wait), //dont know how to get
                callbacksScheduled: queueTicketInformation.callBacksScheduled, //dont know how to get
                "Average Recovery Time": `00:16`, //dont know how to get
                current_queue_sessions: [],
            }

            if (this.queues_table_data.length) {
                this.queues_table_data.forEach((queue, index) => {
                    if (queue.queueName == event.data.queue_name) {
                        queueFound = true;
                        this.queues_table_data[index] = queue_object;
                    }
                });
            }

            if (!queueFound) {
                this.queues_table_data.push(queue_object);
            }
        },
        async handleQubicleJoinEvent(event) {
            var queueFound = false;
            var new_tickets_count = 0;
            var external_internet_escalations = 0;
            var internal_internet_escalations = 0;
            var open_csr_escalations = 0;
            var agentsOnPhone = 0;
            var agentsAway = event.data.active_recipient_count - event.data.available_recipient_count;

            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            var twoHoursBefore = rightNow.setHours(rightNow.getHours() - 2);

            const searchTickets = /* GraphQL */ `
      query SearchTickets(
        $filter: SearchableTicketsFilterInput
        $sort: [SearchableTicketsSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchableTicketsAggregationInput]
      ) {
        searchTickets(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
          aggregates: $aggregates
        ) {
          items {
            id
            queue_id
            queue {
              id
              queue
              queue_name
              switch_queue_name
              contact_information
              sign_off_information
              flow_information
              ticket_subject_values
              tools_list
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            subject
            description
            status
            time_started
            time_last_contact
            priority
            owner
            owner_group
            creator
            requestor
            requestor_group
            cc
            createdAt
            updatedAt
            contact_information
            custom_fields {
              items {
                set_value
                queue_custom_field {
                  default_custom_field {
                    field_name
                  }
                }
              }
            }
            comments {
              nextToken
              startedAt
              __typename
            }
            activity {
              items {
                activity_type
                field_changed
                new_field_value
                old_field_value
              }
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          total
          aggregateItems {
            name
            result {
              ... on SearchableAggregateScalarResult {
                value
              }
              ... on SearchableAggregateBucketResult {
                buckets {
                  key
                  doc_count
                  __typename
                }
              }
            }
            __typename
          }
          __typename
        }
      }
    `;

            var outboundCallsCount = await this.getQueueOutboundCalls(event.data.queue_name.trim());
            var callsHandedToOverflowCount = await this.getQueueOverflowCalls(event.data.queue_name.trim());
            // console.log("AGENTS DATA", this.agents_table_data);
            //Grabbing all agents in the queue statuses

            // var agents_array = event.data.members;

            // for (var agent of this.agents_table_data) {
            //   if (agents_array.includes(agent.recipient_id)) {
            //     if (agent.status.first == "On-A-Call" || agent.status.first == "On-External-Call") {
            //       agentsOnPhone++;
            //     }
            //   }
            // }

            // agentsAway = agentsAway - agentsOnPhone;



            //Grabbing New Tickets
            for (var queue of this.queue_list) {
                if (queue.switch_queue_name == event.data.queue_name.trim()) {
                    var options = {
                        limit: 1000,
                        query: searchTickets,
                        variables: {
                            filter: {
                                queue_id: { eq: queue.id },
                                createdAt: { gt: startOfDay.toISOString() }
                            }
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }
                    var listQueuesResult = await API.graphql(options);

                    var queue_tickets = listQueuesResult.data.searchTickets.items;
                }
            }

            if (queue_tickets) {
                for (var ticket of queue_tickets) {
                    //New Created Tickets
                    if (Date.parse(ticket.createdAt) > twoHoursBefore) {
                        new_tickets_count++;
                    }

                    var ticket_custom_fields = ticket.custom_fields.items;

                    //Ticket Escalations Count
                    for (var custom_field of ticket_custom_fields) {
                        if (custom_field.queue_custom_field.default_custom_field.field_name == "Escalation") {
                            if (custom_field.set_value == "External Standard - Internet") {
                                external_internet_escalations++;
                            }
                            else if (custom_field.set_value == "Internal Internet") {
                                internal_internet_escalations++;
                            }


                            if (custom_field.set_value && ticket.status == "Open" && ticket.owner != "Nobody") {
                                open_csr_escalations++;
                            }
                            //Calculate Open CSR Escalations - if ticket open and escalated
                            if (ticket.owner != "Nobody" && ticket.status == "Open") {
                                open_csr_escalations++;
                            }
                        }
                    }

                }
            }


            var joined_queue_session = {
                session_id: event.data.session_id,
                session_object: event.data.session_data
            }


            var queue_object = {
                queueName: event.data.queue_name, //Valid Data
                answeredCalls: event.data.stats.total_sessions - (event.data.stats.missed_sessions + event.data.stats.abandoned_sessions), //Valid Data
                callsWaitingAndWaitTime: { first: event.data.stats.active_session_count, second: this.toHHMMSS(event.data.stats.estimated_wait) }, //First not Valid, Second Valid
                inboundAndOutboundCalls: { first: event.data.stats.total_sessions, second: outboundCallsCount }, //Not Valid
                missedAndAbandonedCalls: { first: event.data.stats.missed_sessions, second: event.data.stats.abandoned_sessions }, //Valid Data
                callsHandedToOverflow: callsHandedToOverflowCount, //Not Valid
                // newTickets: new_tickets_count,
                newTickets: 2,
                logicomUSAAndOverflowTickets: { first: "82%", second: "18%" }, //Not Valid
                ticketsEscalated: { first: internal_internet_escalations, second: external_internet_escalations }, //Not Valid
                csrEscalations: open_csr_escalations, //Not Valid
                agentsReady: event.data.available_recipient_count, //Available-Recipient-Count: Count of currently available recipients (ready to take a call) - I Think its Valid
                agentsOnPhoneAndAway: { first: agentsOnPhone, second: agentsAway }, //First: I think valid, Second: I think valid
                callsWaitingInQueue: event.data.stats.active_session_count, //Not Valid
                averageWaitTime: this.toHHMMSS(event.data.stats.average_wait), //Valid
                longestHoldTimeCurrent: `00:00`, //Dont know how to get
                longestHoldTimeDay: this.toHHMMSS(event.data.stats.estimated_wait), //dont know how to get
                callbacksScheduled: 7, //dont know how to get
                "Average Recovery Time": `00:16`, //dont know how to get
                current_queue_sessions: [],
            }


            if (this.queues_table_data.length) {
                this.queues_table_data.forEach((queue, index) => {
                    if (queue.queueName == event.data.queue_name) {
                        queueFound = true;

                        queue_object.current_queue_sessions = this.queues_table_data[index].current_queue_sessions;
                        queue_object.current_queue_sessions.push(joined_queue_session);
                        this.queues_table_data[index] = queue_object;
                    }
                });
            }

            if (!queueFound) {
                queue_object.current_queue_sessions.push(joined_queue_session);
                this.queues_table_data.push(queue_object);
            }

            // console.log("Queue Data", this.queues_table_data);
        },
        async handleQubicleDeliveredEvent(event) {
            var queueFound = false;
            var new_tickets_count = 0;
            var external_internet_escalations = 0;
            var internal_internet_escalations = 0;
            var open_csr_escalations = 0;
            var agentsOnPhone = 0;
            var agentsAway = event.data.active_recipient_count - event.data.available_recipient_count;

            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            var twoHoursBefore = rightNow.setHours(rightNow.getHours() - 2);

            const searchTickets = /* GraphQL */ `
      query SearchTickets(
        $filter: SearchableTicketsFilterInput
        $sort: [SearchableTicketsSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchableTicketsAggregationInput]
      ) {
        searchTickets(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
          aggregates: $aggregates
        ) {
          items {
            id
            queue_id
            queue {
              id
              queue
              queue_name
              switch_queue_name
              contact_information
              sign_off_information
              flow_information
              ticket_subject_values
              tools_list
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            subject
            description
            status
            time_started
            time_last_contact
            priority
            owner
            owner_group
            creator
            requestor
            requestor_group
            cc
            createdAt
            updatedAt
            contact_information
            custom_fields {
              items {
                set_value
                queue_custom_field {
                  default_custom_field {
                    field_name
                  }
                }
              }
            }
            comments {
              nextToken
              startedAt
              __typename
            }
            activity {
              items {
                activity_type
                field_changed
                new_field_value
                old_field_value
              }
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          total
          aggregateItems {
            name
            result {
              ... on SearchableAggregateScalarResult {
                value
              }
              ... on SearchableAggregateBucketResult {
                buckets {
                  key
                  doc_count
                  __typename
                }
              }
            }
            __typename
          }
          __typename
        }
      }
    `;

            var outboundCallsCount = await this.getQueueOutboundCalls(event.data.queue_name.trim());
            var callsHandedToOverflowCount = await this.getQueueOverflowCalls(event.data.queue_name.trim());
            // console.log("AGENTS DATA", this.agents_table_data);
            //Grabbing all agents in the queue statuses

            // var agents_array = event.data.members;

            // for (var agent of this.agents_table_data) {
            //   if (agents_array.includes(agent.recipient_id)) {
            //     if (agent.status.first == "On-A-Call" || agent.status.first == "On-External-Call") {
            //       agentsOnPhone++;
            //     }
            //   }
            // }

            // agentsAway = agentsAway - agentsOnPhone;



            //Grabbing New Tickets
            for (var queue of this.queue_list) {
                if (queue.switch_queue_name == event.data.queue_name.trim()) {
                    var options = {
                        limit: 1000,
                        query: searchTickets,
                        variables: {
                            filter: {
                                queue_id: { eq: queue.id },
                                createdAt: { gt: startOfDay.toISOString() }
                            }
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }
                    var listQueuesResult = await API.graphql(options);

                    var queue_tickets = listQueuesResult.data.searchTickets.items;
                }
            }

            if (queue_tickets) {
                for (var ticket of queue_tickets) {
                    //New Created Tickets
                    if (Date.parse(ticket.createdAt) > twoHoursBefore) {
                        new_tickets_count++;
                    }

                    var ticket_custom_fields = ticket.custom_fields.items;

                    //Ticket Escalations Count
                    for (var custom_field of ticket_custom_fields) {
                        if (custom_field.queue_custom_field.default_custom_field.field_name == "Escalation") {
                            if (custom_field.set_value == "External Standard - Internet") {
                                external_internet_escalations++;
                            }
                            else if (custom_field.set_value == "Internal Internet") {
                                internal_internet_escalations++;
                            }


                            if (custom_field.set_value && ticket.status == "Open" && ticket.owner != "Nobody") {
                                open_csr_escalations++;
                            }


                            //Calculate Open CSR Escalations - if ticket open and escalated
                            if (ticket.owner != "Nobody" && ticket.status == "Open") {
                                open_csr_escalations++;
                            }
                        }
                    }

                }
            }


            var delivered_queue_session = event.data.session_id;


            var queue_object = {
                queueName: event.data.queue_name, //Valid Data
                answeredCalls: event.data.stats.total_sessions - (event.data.stats.missed_sessions + event.data.stats.abandoned_sessions), //Valid Data
                callsWaitingAndWaitTime: { first: event.data.stats.active_session_count, second: this.toHHMMSS(event.data.stats.estimated_wait) }, //First not Valid, Second Valid
                inboundAndOutboundCalls: { first: event.data.stats.total_sessions, second: outboundCallsCount }, //Not Valid
                missedAndAbandonedCalls: { first: event.data.stats.missed_sessions, second: event.data.stats.abandoned_sessions }, //Valid Data
                callsHandedToOverflow: callsHandedToOverflowCount, //Not Valid
                // newTickets: new_tickets_count,
                newTickets: 2,
                logicomUSAAndOverflowTickets: { first: "82%", second: "18%" }, //Not Valid
                ticketsEscalated: { first: internal_internet_escalations, second: external_internet_escalations }, //Not Valid
                csrEscalations: open_csr_escalations, //Not Valid
                agentsReady: event.data.available_recipient_count, //Available-Recipient-Count: Count of currently available recipients (ready to take a call) - I Think its Valid
                agentsOnPhoneAndAway: { first: agentsOnPhone, second: agentsAway }, //First: I think valid, Second: I think valid
                callsWaitingInQueue: event.data.stats.active_session_count, //Not Valid
                averageWaitTime: this.toHHMMSS(event.data.stats.average_wait), //Valid
                longestHoldTimeCurrent: `00:00`, //Dont know how to get
                longestHoldTimeDay: this.toHHMMSS(event.data.stats.estimated_wait), //dont know how to get
                callbacksScheduled: 7, //dont know how to get
                "Average Recovery Time": `00:16`, //dont know how to get
                current_queue_sessions: [],
            }


            if (this.queues_table_data.length) {
                this.queues_table_data.forEach((queue, index) => {
                    if (queue.queueName == event.data.queue_name) {
                        queueFound = true;
                        // REMOVE THE SESSION FROM THE QUEUE THAT WAS SENT SET TO THE  delivered_queue_session variable
                        queue_object.current_queue_sessions = this.queues_table_data[index].current_queue_sessions;


                        // delivered_queue_session
                        for (var session of queue_object.current_queue_sessions) {
                            if (session.session_id == delivered_queue_session) {
                                queue_object.current_queue_sessions.splice(queue_object.current_queue_sessions.indexOf(session), 1);
                            }
                        }

                        this.queues_table_data[index] = queue_object;
                    }
                });
            }

            if (!queueFound) {
                this.queues_table_data.push(queue_object);
            }

            // console.log("Queue Data", this.queues_table_data);
        },
        async handleAgentEvent(event) {
            //console.log("agent event", event);
            var agentFound = false;
            var agent_status = event.data.availability_state;
            var gregorian_offset = 62167219200;

            if (event.name == "offer") {
                let callIdNum = event.data.caller_id_num ? `<br>${event.data.caller_id_num}` : "";
                let queueName = event.data.queue_name ? `<br>From ${event.data.queue_name}` : "";
                agent_status = `Call Offered${queueName}${callIdNum}`;
            }
            else if (event.name == "wrapup_start") {
                agent_status = "Wrapup-Time";
            }
            else if (event.name == "delivered") {
                let callType = "Inbound";
                let callIdNum = event.data.caller_id_num ? `<br>${event.data.caller_id_num}` : "";
                let queueName = event.data.queue_name ? `<br>${event.data.queue_name}` : ""; 
                agent_status = `On-A-Call<br>${callType}${queueName}${callIdNum}`;
            }
            else if (event.name == "external_call_start") {
                let callType = "Outbound";
                let callIdNum = event.data.caller_id_num ? `<br>${event.data.caller_id_num}` : "";
                let queueName = event.data.queue_name ? `<br>${event.data.queue_name}` : ""; 
                agent_status = `On-A-Call<br>${callType}${queueName}${callIdNum}`;
            }
            else if (event.name == "away") {
                let reason = event.data.reason;
                agent_status = `Away - ${reason}`;
            }
            else if (event.data.state == "away") {
                agent_status = "Away";
            }
            else if (event.data.state == "ready") {
                agent_status = "Ready";
            }                           

            var agent_tickets = await this.getAgentsTicketsForToday(event.data.recipient_id);
            var outboundCallsCount = await this.getAgentOutboundCalls(event.data.recipient_id, this.agent_list[event.data.recipient_id].presence_id, (event.data.stats.login_time - gregorian_offset));
            var average_recovery_time = await this.getAgentAverageRecoveryTime(event.data.recipient_id);

            var ticketResolvedPercent = 0;
            var ticketEscalatedPercent = 0;
            if (agent_tickets.totalTickets != 0) {
                var ticketResolvedPercent = (Number(agent_tickets.resolvedTickets) / Number(agent_tickets.totalTickets) * 100).toFixed(2);
                var ticketEscalatedPercent = (Number(agent_tickets.escalatedTickets) / Number(agent_tickets.totalTickets) * 100).toFixed(2);
            }

            if (this.agents_table_data.length) {
                this.agents_table_data.forEach((agent, index) => {
                    if (agent.recipient_id == event.data.recipient_id) {
                        agentFound = true;

                        this.agents_table_data[index] = {
                            recipient_id: event.data.recipient_id,
                            agentName: this.agent_list[event.data.recipient_id].display_name,
                            agentEmail: this.agent_list[event.data.recipient_id].email,
                            status: { first: agent_status, second: 0 },
                            callsCurrentShift: event.data.stats.offered_calls,
                            outboundCalls: outboundCallsCount, //TODO: Need to track this through a table 
                            answeredCalls: event.data.stats.total_calls,
                            missedCalls: event.data.stats.missed_calls,
                            abandonedCalls: event.data.stats.missed_calls,
                            ticketsResolved: { first: agent_tickets.resolvedTickets, second: `${ticketResolvedPercent}%` },
                            ticketsEscalated: { first: agent_tickets.escalatedTickets, second: `${ticketEscalatedPercent}%` },
                            averageRecoveryTime: average_recovery_time, //TODO: How to track this data?
                            supervisor: "Supervisor 1", //TODO: How to track this data?
                        };
                    }
                });
            }


            if (!agentFound) {
                this.agents_table_data.push({
                    recipient_id: event.data.recipient_id,
                    agentName: this.agent_list[event.data.recipient_id].display_name,
                    agentEmail: this.agent_list[event.data.recipient_id].email,
                    status: { first: agent_status, second: 0 },
                    callsCurrentShift: event.data.stats.offered_calls,
                    outboundCalls: outboundCallsCount, //TODO: Need to track this through a table 
                    answeredCalls: event.data.stats.total_calls,
                    missedCalls: event.data.stats.missed_calls,
                    abandonedCalls: event.data.stats.missed_calls,
                    ticketsResolved: { first: agent_tickets.resolvedTickets, second: `${ticketResolvedPercent}%` },
                    ticketsEscalated: { first: agent_tickets.escalatedTickets, second: `${ticketEscalatedPercent}%` },
                    averageRecoveryTime: "00:00:24", //TODO: How to track this data?
                    supervisor: "Supervisor 1", //TODO: How to track this data?
                });
            }
        },
        async handleQueueEvent(event) {
            var queueFound = false;
            var agentsOnPhone = 0;
            var agentsAway = event.data.active_recipient_count - event.data.available_recipient_count;
            var queue_id = event.data.queue_id;

            var agents_array = this.queue_members[queue_id].members;

            for (var agent of this.agents_table_data) {
                if (agent.recipient_id in agents_array) {
                    if (agent.status.first == "On-A-Call" || agent.status.first == "On-External-Call") {
                        agentsOnPhone++;
                    }
                }
            }

            agentsAway = agentsAway - agentsOnPhone;
            var outboundCallsCount = await this.getQueueOutboundCalls(event.data.queue_name.trim());
            var callsHandedToOverflowCount = await this.getQueueOverflowCalls(event.data.queue_name.trim());
            var queueTicketInformation = await this.getQueueTicketsForToday(event.data.queue_name);

            var queue_object = {
                queueName: event.data.queue_name, //Valid Data
                answeredCalls: event.data.stats.total_sessions - (event.data.stats.missed_sessions + event.data.stats.abandoned_sessions), //Valid Data
                callsWaitingAndWaitTime: { first: event.data.stats.active_session_count, second: this.toHHMMSS(event.data.stats.estimated_wait) }, //First not Valid, Second Valid
                inboundAndOutboundCalls: { first: event.data.stats.total_sessions, second: outboundCallsCount }, //Not Valid
                missedAndAbandonedCalls: { first: event.data.stats.missed_sessions, second: event.data.stats.abandoned_sessions }, //Valid Data
                callsHandedToOverflow: callsHandedToOverflowCount, //Not Valid
                newTickets: queueTicketInformation.new_tickets_count,
                logicomUSAAndOverflowTickets: { first: `${queueTicketInformation.logicomTickets}%`, second: `${queueTicketInformation.overflowTickets}` },
                ticketsEscalated: { first: queueTicketInformation.internal_internet_escalations, second: queueTicketInformation.external_internet_escalations }, //Not Valid
                csrEscalations: queueTicketInformation.open_csr_escalations, //Not Valid
                agentsReady: event.data.available_recipient_count, //Available-Recipient-Count: Count of currently available recipients (ready to take a call) - I Think its Valid
                agentsOnPhoneAndAway: { first: agentsOnPhone, second: agentsAway }, //First: I think valid, Second: I think valid
                callsWaitingInQueue: event.data.stats.active_session_count, //Not Valid
                averageWaitTime: this.toHHMMSS(event.data.stats.average_wait), //Valid
                longestHoldTimeCurrent: `00:00`, //Dont know how to get
                longestHoldTimeDay: this.toHHMMSS(event.data.stats.estimated_wait), //dont know how to get
                callbacksScheduled: queueTicketInformation.callBacksScheduled, //dont know how to get
                "Average Recovery Time": `00:16`, //dont know how to get
                current_queue_sessions: [],
            }

            // Check
            if (this.queues_table_data.length) {
                this.queues_table_data.forEach((queue, index) => {
                    if (queue.queueName == event.data.queue_name) {
                        queueFound = true;
                        this.queues_table_data[index] = queue_object;
                    }
                });
            }
            this.setQueueFilters();

            if (!queueFound) {
                this.queues_table_data.push(queue_object);
            }
            let filteredQueueFound = false;
            if (this.queues_filtered_data.length) {
                this.queues_filtered_data.forEach((queue, index) => {
                    if (queue.queueName == event.data.queue_name) {
                        filteredQueueFound = true;
                        this.queues_filtered_data[index] = queue_object;
                    }
                });
            }
            if (!filteredQueueFound && queue_object.queueName == this.selected_queue_filter) {
                this.queues_filtered_data.push(queue_object);
            }
            
        },
        async setQueueList() {
            var options = {
                query: queries.listQueues,
                variables: {
                    limit: 1000
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            }

            try {
                var listQueuesResult = await queryGraphQL(options);
                // console.log("LIST QUEUES RESULT", listQueuesResult);
            }
            catch (error) {
                console.log(error);
            }

            this.queue_list = listQueuesResult.data.listQueues.items;


            //Pull back initial Switch queues
            var api_name = "switch";
            var path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_queues`;

            var userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
            }

            let result_json = await API.get(api_name, path, myInit);
            var queues = result_json.data.data;
     
            await Promise.all(queues.map(async (queue) => {
                var queueFound = false;

                api_name = "switch";
                path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_queues/${queue.id}/status`;

                userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

                myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    },
                }

                result_json = await API.get(api_name, path, myInit);



                api_name = "switch";
                path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_queues/${queue.id}`;

                userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

                myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    },
                }

                var queueMemberResult = await API.get(api_name, path, myInit);

                this.queue_members[queue.id] = { members: queueMemberResult.data.data.members, name: queueMemberResult.data.data.name};

                var agentsOnPhone = 0;
                var agentsAway = result_json.data.data.active_recipient_count - result_json.data.data.available_recipient_count;
                var outboundCallsCount = await this.getQueueOutboundCalls(queue.name.trim());
                var callsHandedToOverflowCount = await this.getQueueOverflowCalls(queue.name.trim());
                var queueTicketInformation = await this.getQueueTicketsForToday(queue.name);


                var queue_object = {
                    queueName: queue.name, //Valid Data
                    answeredCalls: result_json.data.data.stats.total_sessions - (result_json.data.data.stats.missed_sessions + result_json.data.data.stats.abandoned_sessions), //Valid Data
                    callsWaitingAndWaitTime: { first: result_json.data.data.stats.active_session_count, second: this.toHHMMSS(result_json.data.data.stats.estimated_wait) }, //First not Valid, Second Valid
                    inboundAndOutboundCalls: { first: result_json.data.data.stats.total_sessions, second: outboundCallsCount }, //Not Valid
                    missedAndAbandonedCalls: { first: result_json.data.data.stats.missed_sessions, second: result_json.data.data.stats.abandoned_sessions }, //Valid Data
                    callsHandedToOverflow: callsHandedToOverflowCount, //Not Valid
                    newTickets: queueTicketInformation.new_tickets_count,
                    // newTickets: 2,
                    logicomUSAAndOverflowTickets: { first: `${queueTicketInformation.logicomTickets}%`, second: `${queueTicketInformation.overflowTickets}` },
                    ticketsEscalated: { first: queueTicketInformation.internal_internet_escalations, second: queueTicketInformation.external_internet_escalations }, //Not Valid
                    csrEscalations: queueTicketInformation.open_csr_escalations, //Not Valid
                    agentsReady: result_json.data.data.available_recipient_count, //Available-Recipient-Count: Count of currently available recipients (ready to take a call) - I Think its Valid
                    agentsOnPhoneAndAway: { first: agentsOnPhone, second: agentsAway }, //First: I think valid, Second: I think valid
                    callsWaitingInQueue: result_json.data.data.stats.active_session_count, //Not Valid
                    averageWaitTime: this.toHHMMSS(result_json.data.data.stats.average_wait), //Valid
                    longestHoldTimeCurrent: `00:00`, //Dont know how to get
                    longestHoldTimeDay: this.toHHMMSS(result_json.data.data.stats.estimated_wait), //dont know how to get
                    callbacksScheduled: 7, //dont know how to get
                    "Average Recovery Time": `00:16`, //dont know how to get
                    current_queue_sessions: [],
                }

                if (this.queues_table_data.length) {
                    this.queues_table_data.forEach((queue, index) => {
                        if (queue.queueName == queue.name) {
                            queueFound = true;
                            this.queues_table_data[index] = queue_object;
                        }
                    });
                }
                this.setQueueFilters();

                if (!queueFound) {
                    this.queues_table_data.push(queue_object);
                }
            }));

            // sort tble data
            this.queues_table_data.sort((a, b) => a.queueName.localeCompare(b.queueName));
        },
        async getAgentsTicketsForToday(recipient_id) {
            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            startOfDay.setHours(0, 0, 0, 0);
            var agent_email = this.agent_list[recipient_id].email;


            const searchTickets = /* GraphQL */ `
            query SearchTickets(
                $filter: SearchableTicketsFilterInput
                $sort: [SearchableTicketsSortInput]
                $limit: Int
                $nextToken: String
                $from: Int
                $aggregates: [SearchableTicketsAggregationInput]
            ) {
                searchTickets(
                filter: $filter
                sort: $sort
                limit: $limit
                nextToken: $nextToken
                from: $from
                aggregates: $aggregates
                ) {
                items {
                    id
                    queue_id
                    queue {
                    id
                    queue
                    queue_name
                    switch_queue_name
                    contact_information
                    sign_off_information
                    flow_information
                    ticket_subject_values
                    tools_list
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                    }
                    subject
                    description
                    status
                    time_started
                    time_last_contact
                    priority
                    owner
                    owner_group
                    creator
                    requestor
                    requestor_group
                    cc
                    createdAt
                    updatedAt
                    contact_information
                    custom_fields {
                    items {
                        set_value
                        queue_custom_field {
                        default_custom_field {
                            field_name
                        }
                        }
                    }
                    }
                    comments {
                    nextToken
                    startedAt
                    __typename
                    }
                    activity {
                    items {
                        activity_type
                        field_changed
                        new_field_value
                        old_field_value
                    }
                    }
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                }
                nextToken
                total
                aggregateItems {
                    name
                    result {
                    ... on SearchableAggregateScalarResult {
                        value
                    }
                    ... on SearchableAggregateBucketResult {
                        buckets {
                        key
                        doc_count
                        __typename
                        }
                    }
                    }
                    __typename
                }
                __typename
                }
            }
            `;


            var options = {
                query: searchTickets,
                variables: {
                    limit: 1000,
                    filter: {
                        createdAt: {
                            gt: startOfDay.toISOString()
                        },
                        owner: {
                          eq: agent_email
                        }
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            }
            try {
                var ticketsResult = await queryGraphQL(options);
                // console.log("TICKETS RESULT", ticketsResult);
            }
            catch (error) {
                console.log(error);
            }

            var tickets = ticketsResult.data.searchTickets.items;

            var resolvedTickets = 0;
            var escalatedTickets = 0;
            var totalTickets = ticketsResult.data.searchTickets.total;
            for (var ticket of tickets) {
                if (ticket.status == "Resolved") {
                    resolvedTickets++;
                }

                var custom_fields = ticket.custom_fields?.items;


                if (custom_fields) {
                    for (var custom_field of custom_fields) {
                        if (custom_field.queue_custom_field.default_custom_field.field_name == "Escalation" && custom_field.set_value != "") {
                            escalatedTickets++;
                        }
                    }
                }
            }




            return { resolvedTickets: resolvedTickets, escalatedTickets: escalatedTickets, totalTickets: totalTickets };
        },
        async getQueueOutboundCalls(queue) {
            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            startOfDay.setHours(0, 0, 0, 0);

            var spoofed_numbers = "";
            for (var queue_object of this.queue_list) {
                if (queue == queue_object.switch_queue_name) {
                    if (queue_object.queue_cid_spoof_number) {
                        spoofed_numbers = queue_object.queue_cid_spoof_number.split(",");
                        break;
                    }
                }
            }


            var totalOutboundCalls = 0;



            for (var number of spoofed_numbers) {
                var options = {
                    query: queries.searchSwitchCDRs,
                    variables: {
                        limit: 1000,
                        filter: {
                            createdAt: {
                                gt: startOfDay.toISOString()
                            },
                            dialed_number: {
                                wildcard: `*${number[0]}*`
                            }
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }
                try {
                    var outBoundCallsResult = await queryGraphQL(options);
                    // console.log("OUTBOUND CALLS RESULT", outBoundCallsResult);
                    var total = outBoundCallsResult.data.searchSwitchCDRs.total;
                    totalOutboundCalls += total;
                }
                catch (error) {
                    console.log(error);
                }
            }

            return totalOutboundCalls;
        },
        async getQueueOverflowCalls(queue) {
            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            startOfDay.setHours(0, 0, 0, 0);



            var fc_overflow_number = "";
            for (var queue_object of this.queue_list) {
                if (queue == queue_object.switch_queue_name) {
                    if (queue_object.fc_overflow_number) {
                        fc_overflow_number = queue_object.fc_overflow_number.split(",");
                        break;
                    }
                }
            }

            var totalOverflowCalls = 0;

            var options = "";
            if (queue == "Retail Customers") {
                options = {
                    query: queries.searchSwitchCDRs,
                    variables: {
                        limit: 1000,
                        filter: {
                            createdAt: {
                                gt: startOfDay.toISOString()
                            },
                            calling_from: {
                                wildcard: `*${fc_overflow_number}*`
                            },
                            to: { wildcard: "*firstcall.s.logicom.center*" },
                            from: { wildcard: "*firstcall.s.logicom.center*" }
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }
            }
            else {
                options = {
                    query: queries.searchSwitchCDRs,
                    variables: {
                        limit: 1000,
                        filter: {
                            createdAt: {
                                gt: startOfDay.toISOString()
                            },
                            dialed_number: {
                                wildcard: `*${fc_overflow_number}*`
                            },
                            to: { wildcard: "*firstcall.s.logicom.center*" },
                            from: { wildcard: "*firstcall.s.logicom.center*" }
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }
            }

            try {
                var overflowCallsResult = await queryGraphQL(options);
                // console.log("OVERFLOW CALLS RESULT", overflowCallsResult);
                var total = overflowCallsResult.data.searchSwitchCDRs.total;
                totalOverflowCalls += total;
            }
            catch (error) {
                console.log(error);
            }

            return totalOverflowCalls;
        },
        async retrieveAllContactCenterUsers() {
            var hasData = true;
            var nextToken = null;
            var usersData = [];


            while (hasData) {
                var options = {
                    query: queries.listSwitchUsers,
                    variables: {
                        limit: 1000,
                        filter: {
                            account_id: {
                                eq: "519b73ddf6c2ba5688acc12e9d0c01d4" //Contact Center
                            },
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                if (nextToken != null) {
                    options.variables.nextToken = nextToken;
                }

                var users = await API.graphql(options);

                if (users.data.listSwitchUsers.nextToken != null) {
                    nextToken = users.data.listSwitchUsers.nextToken;

                    for (const item of users.data.listSwitchUsers.items) {
                        usersData.push(item);
                    }
                }
                else {
                    hasData = false;

                    for (const item of users.data.listSwitchUsers.items) {
                        usersData.push(item);
                    }
                }
            }

            return usersData;
        },
        async getAgentOutboundCalls(recipient_id, presence_id, loggedInTime) {
            var api_name = "switch";
            var path = `/accounts/519b73ddf6c2ba5688acc12e9d0c01d4/cdrs/get_user_specific`;

            var userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {
                    user_id: recipient_id,
                    query: {
                        created_from: loggedInTime,
                        created_to: loggedInTime + 86400,
                    }
                }
            }

            var result_json = await API.post(api_name, path, myInit);
            var agent_calls = result_json.data.data;

            var outboundCallsCount = 0;
            for (var agent_call of agent_calls) {
                if (agent_call.caller_id_number == presence_id) {
                    outboundCallsCount++;
                }
            }

            return outboundCallsCount;
        },
        async getQueueTicketsForToday(event_queue) {
            var new_tickets_count = 0;
            var external_internet_escalations = 0;
            var internal_internet_escalations = 0;
            var open_csr_escalations = 0;
            var callBacksScheduled = 0;
            var logicomTickets = 0;
            var overflowTickets = 0;

            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            var twoHoursBefore = rightNow.setHours(rightNow.getHours() - 2);

            const searchTickets = /* GraphQL */ `
            query SearchTickets(
                $filter: SearchableTicketsFilterInput
                $sort: [SearchableTicketsSortInput]
                $limit: Int
                $nextToken: String
                $from: Int
                $aggregates: [SearchableTicketsAggregationInput]
            ) {
                searchTickets(
                filter: $filter
                sort: $sort
                limit: $limit
                nextToken: $nextToken
                from: $from
                aggregates: $aggregates
                ) {
                items {
                    id
                    queue_id
                    queue {
                    id
                    queue
                    queue_name
                    switch_queue_name
                    contact_information
                    sign_off_information
                    flow_information
                    ticket_subject_values
                    tools_list
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                    }
                    subject
                    description
                    status
                    time_started
                    time_last_contact
                    priority
                    owner
                    owner_group
                    creator
                    requestor
                    requestor_group
                    cc
                    createdAt
                    updatedAt
                    contact_information
                    custom_fields {
                    items {
                        set_value
                        queue_custom_field {
                        default_custom_field {
                            field_name
                        }
                        }
                    }
                    }
                    comments {
                    nextToken
                    startedAt
                    __typename
                    }
                    activity {
                    items {
                        activity_type
                        field_changed
                        new_field_value
                        old_field_value
                    }
                    }
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                }
                nextToken
                total
                aggregateItems {
                    name
                    result {
                    ... on SearchableAggregateScalarResult {
                        value
                    }
                    ... on SearchableAggregateBucketResult {
                        buckets {
                        key
                        doc_count
                        __typename
                        }
                    }
                    }
                    __typename
                }
                __typename
                }
            }
            `;


            //Grabbing New Tickets
            for (var queue of this.queue_list) {
                if (queue.switch_queue_name == event_queue.trim()) { //event.data.queue_name
                    var options = {
                        limit: 1000,
                        query: searchTickets,
                        variables: {
                            filter: {
                                queue_id: { eq: queue.id },
                                createdAt: { gt: startOfDay.toISOString() }
                            }
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }
                    var listQueuesResult = await API.graphql(options);

                    var queue_tickets = listQueuesResult.data.searchTickets.items;
                }
            }

            if (queue_tickets) {
                for (var ticket of queue_tickets) {
                    //New Created Tickets
                    if (Date.parse(ticket.createdAt) > twoHoursBefore) {
                        new_tickets_count++;
                    }

                    var ticket_custom_fields = ticket.custom_fields.items;

                    //Ticket Escalations Count
                    for (var custom_field of ticket_custom_fields) {
                        if (custom_field.queue_custom_field.default_custom_field.field_name == "Escalation") {
                            if (custom_field.set_value == "External Standard - Internet") {
                                external_internet_escalations++;
                            }
                            else if (custom_field.set_value == "Internal Internet") {
                                internal_internet_escalations++;
                            }

                            if (custom_field.set_value && ticket.status == "Open" && ticket.owner != "Nobody") {
                                open_csr_escalations++;
                            }
                        }


                        if (custom_field.queue_custom_field.default_custom_field.field_name == "Callback") {
                            if (custom_field.set_value != "") {
                                callBacksScheduled++;
                            }
                        }
                    }


                    if (ticket.owner.includes("logicomusa")) {
                        logicomTickets++;
                    }
                    else if (ticket.owner.includes("firstcall")) {
                        overflowTickets++;
                    }
                }
            }


            var totalTickets = logicomTickets + overflowTickets;

            var logicomTicketsPercentage = 0;
            var overflowTicketsPercentage = 0;
            if (totalTickets != 0) {
                var logicomTicketsPercentage = logicomTickets / totalTickets * 100;
                var overflowTicketsPercentage = overflowTickets / totalTickets * 100;
            }

            var return_object = {
                new_tickets_count: new_tickets_count,
                external_internet_escalations: external_internet_escalations,
                internal_internet_escalations: internal_internet_escalations,
                open_csr_escalations: open_csr_escalations,
                callBacksScheduled: callBacksScheduled,
                overflowTickets: overflowTicketsPercentage,
                logicomTickets: logicomTicketsPercentage,
            }

            return return_object;
        },
        async getAgentAverageRecoveryTime(recipient_id) {
            var rightNow = new Date();
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth() - 3, rightNow.getDate(), 0, 0, 0);
            var hasData = true;
            var nextToken = null;
            var recipient_events = [];

            while (hasData) {
                var options = {
                    query: queries.listAgentsRecoveryTimes,
                    variables: {
                        filter: {
                            recipient_id: { eq: recipient_id},
                            createdAt: { gt: startOfDay.toISOString()}
                        },
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                if (nextToken != null) {
                    options.variables.nextToken = nextToken;
                }

                var result = await API.graphql(options);

                if (result.data.listAgentsRecoveryTimes.nextToken != null) {
                    nextToken = result.data.listAgentsRecoveryTimes.nextToken;

                    for (const item of result.data.listAgentsRecoveryTimes.items) {
                        recipient_events.push(item);
                    }
                }
                else {
                    hasData = false;

                    for (const item of result.data.listAgentsRecoveryTimes.items) {
                        recipient_events.push(item);
                    }
                }
            }

            var countOfRecoveryEvents = 0;
            var totalRecoveryTime = 0;

            for (var event of recipient_events) {
                totalRecoveryTime += event.recovery_time;
                countOfRecoveryEvents++;
            }

            var average_recovery_time = 0;
            if (totalRecoveryTime != 0) {
                average_recovery_time = Math.ceil(totalRecoveryTime / countOfRecoveryEvents);
            }

            return this.toHHMMSS(average_recovery_time);        
        },
        fetchAgentData() {
            //TODO: Call to get the Data

            //TODO: Update the unchanged data
            // this.agents_unchanged_data = [
            //   {
            //     agentName: "Noah Sutherland",
            //     status: { first: "Away - One on One", second: "01:46:15" },
            //     callsCurrentShift: "43",
            //     outboundCalls: 34,
            //     answeredCalls: "12%",
            //     missedCalls: 34,
            //     abandonedCalls: "12%",
            //     ticketsResolved: { first: 34, second: "12%" },
            //     ticketsEscalated: { first: 15, second: "20%" },
            //     averageRecoveryTime: "00:23",
            //     supervisor: "Supervisor 1",
            //   },
            //   {
            //     agentName: "Victoria Jaynes",
            //     status: { first: "Logged Out", second: "01:46:15" },
            //     callsCurrentShift: "43",
            //     outboundCalls: 34,
            //     answeredCalls: "12%",
            //     missedCalls: 34,
            //     abandonedCalls: "12%",
            //     ticketsResolved: { first: 34, second: "12%" },
            //     ticketsEscalated: { first: 15, second: "20%" },
            //     averageRecoveryTime: "00:23",
            //     supervisor: "Supervisor 2",
            //   },
            //   {
            //     agentName: "Andres Gomez",
            //     status: { first: "Away - One on One", second: "01:46:15" },
            //     callsCurrentShift: "43",
            //     outboundCalls: 34,
            //     answeredCalls: "12%",
            //     missedCalls: 34,
            //     abandonedCalls: "12%",
            //     ticketsResolved: { first: 34, second: "12%" },
            //     ticketsEscalated: { first: 15, second: "20%" },
            //     averageRecoveryTime: "00:23",
            //     supervisor: "Supervisor 3",
            //   },
            // ];

            // this.agents_table_data = this.agents_unchanged_data;

            // this.setAgentFilters();

            // return { error: false, message: "okay" };
        },
    },
});
