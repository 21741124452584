<template>
  <div class="flowsContainer" :style="{ height: dynamicHeight + 'px' }">
    <TableCompDash
      :headers="filteredHeadersList"
      :items="agentTableData"
      name="Agent Dashboard"
      @update-items="handleItemsUpdate"
      @click-row="handleClickRow"
    />
  </div>
</template>
<script>
import TableCompDash from "@/components/CcManagement/TableCompDashAgents.vue";
import { mapActions, mapStores } from 'pinia';
import { useDashboardStore } from '@/stores/ContactCenter/Management/DashboardStore.js'

export default {
  name: "AgentDashboard",
  data() {
    return {
      headers: [
        { name: "Agent Name", width: 175, value: "agentName" },
        { name: "Status", width: 175, value: "status" },
        {
          name: "Calls during Current Shift",
          width: 110,
          value: "callsCurrentShift",
        },
        {
          name: "Outbound Calls",
          width: 110,
          value: "outboundCalls",
        },
        {
          name: "Answered Calls",
          width: 110,
          value: "answeredCalls",
        },
        {
          name: "Missed Calls",
          width: 110,
          value: "missedCalls",
        },
        {
          name: "Abandoned Calls",
          width: 110,
          value: "abandonedCalls",
        },
        { name: "Tickets Resolved",
          width: 110,
          value: "ticketsResolved" },
        { name: "Tickets Escalated",
          width: 110,
          value: "ticketsEscalated" },
        {
          name: "Average Recovery Time",
          width: 110,
          value: "averageRecoveryTime",
        },
        {
          name: "Supervisor",
          width: 0,
          value: "supervisor",
          show: false,
        },
      ],
      items: [
        // {
        //   agentName: "Noah Sutherland",
        //   status: { first: "Away - One on One", second: "01:46:15" },
        //   callsCurrentShift: "43",
        //   outboundCalls: 34,
        //   answeredCalls: "12%",
        //   missedCalls: 34,
        //   abandonedCalls: "12%",
        //   ticketsResolved: { first: 34, second: "12%" },
        //   ticketsEscalated: { first: "15%", second: "20%" },
        //   averageRecoveryTime: "00:23",
        // },
      ],
      changed: []
    };
  },
  props: {
    filterOptionsArray: Array,
    dynamicHeight: Number,
    markedForRemoval: Array,
    unmarkForRemoval: Array,
    headersToDisplay: Array,
    searchValue: String,
  },
  methods: {
    ...mapActions(useDashboardStore, ['fetchAgentData']),
    handleSelectedItems(selectedItems) {
      this.$emit("selected-items", selectedItems);
    },
    handleClickRow(data) {
      console.log(data);
    },
    handleItemsUpdate(sortedItems) {
      this.DashboardStore.updateAgentTableData(sortedItems);
    }
  },
  mounted() {
    //this.fetchAgentData();
  },
  computed: {
    ...mapStores(useDashboardStore),
    agentTableData() {
      const data = this.DashboardStore.agents_table_data;
      if (!this.searchValue) {
        return data;
      }

      const lowerSearchValue = this.searchValue.toLowerCase();
      return data.filter((item) => {
        const agentNameMatches = item.agentName.toLowerCase().includes(lowerSearchValue);
        const statusMatches = item.status && item.status.first.toLowerCase().includes(lowerSearchValue);

        return agentNameMatches || statusMatches;
      });
    },
    filteredHeadersList() {
      if (this.headersToDisplay !== undefined && this.headersToDisplay.length) {
        return this.headers.filter((header) => {
          return (
            this.headersToDisplay.includes(header.value) ||
            header.value == "agentName" ||
            header.value == "status"
          );
        });
      } else {
        return this.headers;
      }
    },
    tableData() {
      return this.items.filter((item) => {
        return Object.entries(item).some(([key, value]) => {
          if (key !== "supervisor") {
            if (typeof value === "string") {
              return String(value)
                .toLowerCase()
                .trim()
                .includes(this.searchValue?.toLowerCase().trim());
            } else if (typeof value === "object" && value !== null) {
              return Object.values(value).some((value) =>
                String(value)
                  .toLowerCase()
                  .trim()
                  .includes(this.searchValue?.toLowerCase().trim())
              );
            }
          }
        });
      });
    },
  },
  watch: {
    markedForRemoval(marked) {
      console.log(marked);
      marked.forEach((markedItem) => {
        this.data.forEach((dataItem) => {
          if (markedItem.flowName === dataItem.flowName) {
            dataItem.markedForRemoval = "Yes";
          }
        });
      });
      this.changed = [...this.data];
      this.$emit("selected-items", []);
    },
    unmarkForRemoval(marked) {
      marked.forEach((markedItem) => {
        this.data.forEach((dataItem) => {
          if (markedItem.flowName === dataItem.flowName) {
            dataItem.markedForRemoval = "No";
          }
        });
      });

      this.changed = [...this.data];
      this.$emit("selected-items", []);
    },
    agentTableData(newVal) {
      this.items = newVal;
    }
  },
  components: { TableCompDash },
  emits: [
    "selected-items"
  ]
};
</script>
<style scoped>
.flowsContainer {
  padding: 20px;
  width: 100%;
  background-color: #e2f1f4;
}
:deep(.table) {
  padding-left: 0;
  width: auto;
}
.tableContainer {
  padding: 16px;
}

:deep(.listName) {
  padding-left: 0;
  padding-bottom: 0;
}

:deep(span.tableHeader) {
  display: flex;
  /* min-width: 150px; */
  padding-right: 30px;
  white-space: pre-line;
}

:deep(th.tableHeader:nth-child(2) span.tableHeader) {
  min-width: 190px;
  padding-right: 0;
}
/* :deep(span.tableHeader) {
  display: block;
  min-width: 150px;
  padding-right: 30px;
}
:deep(th.tableHeader:nth-child(2) span.tableHeader) {
  min-width: 190px;
  padding-right: 0;
} */

:deep(th.tableHeader:nth-child(2) span.tableHeader) {
  min-width: 190px;
  padding-right: 0;
}

/* :deep(th.tableHeader:last-child) {
  display: none !important;
} */

:deep(tbody > tr > td.data-cell:last-child) {
  display: none;
}
</style>
