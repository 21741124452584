/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "Quickbase",
            "endpoint": "https://jb19ndk4zb.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "apiMappings",
            "endpoint": "https://m1rm6mhjtf.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "bandwidth",
            "endpoint": "https://fo1a87nk77.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "census",
            "endpoint": "https://z20i7wggwk.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "fieldNation",
            "endpoint": "https://8n2vz2gvw1.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "fractel",
            "endpoint": "https://x0ngbm9lyd.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "frontendAPI",
            "endpoint": "https://1f7blzyjs3.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "generalLog",
            "endpoint": "https://6eogbgvmp3.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "google",
            "endpoint": "https://hjpnt11lhf.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "inteliquent",
            "endpoint": "https://r6yn232lhc.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "mail",
            "endpoint": "https://4ic6dey3vh.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "quickbaseWebhook",
            "endpoint": "https://dmys16qdq8.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "quickbooks",
            "endpoint": "https://pog4yp1rsh.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "revio",
            "endpoint": "https://uaot17mq0j.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "revioWebhook",
            "endpoint": "https://o2sph7x9v2.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "sandboxAPI",
            "endpoint": "https://i9pxukax5c.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "sureTax",
            "endpoint": "https://uaqc5dtx8c.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "switch",
            "endpoint": "https://a99xsz8oeg.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "switchPivots",
            "endpoint": "https://kpb0cwqfeh.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        },
        {
            "name": "switchWebhook",
            "endpoint": "https://28iv1am79l.execute-api.us-east-1.amazonaws.com/demo",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://t3gunikgevgrjffrytox3i6rza.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-5u4jpdmmjfatjdqpc5yvifaywa",
    "aws_cognito_identity_pool_id": "us-east-1:36947a0a-0e9d-479f-9564-e8ab66bb20f2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_96kXX3PIb",
    "aws_user_pools_web_client_id": "7ngk96c6v1672p8jsop0o651e3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lex-connect-site-uploads151006-demo",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
