<template>
  <nav>
    <img
      class="navLogo"
      v-bind:src="require(`@/assets/mainnav_logo.png`)"
      @click="clickLogo"
    />

    <div class="iconContainer">
      <div>
        <LightButton v-if="current_agent_status == 'Recovery' && wrap_up_started == true && ifCurrentAgentId" title="Extend Wrapup Time" @button-click="extendAgentWrapupTime"/>
      </div>
      <Dropdown v-if="current_agent_status != 'not_logged_in' && ifCurrentAgentId" style="width: 200px;" :options="status_options" @input="handleStatusChange" default="Change Status"/>
      <LightButton v-if="current_agent_status != 'not_logged_in' && ifCurrentAgentId" title="Change Status" @button-click="sendAgentStatusChange"/>
      <div>
        <TimeComp v-if="current_agent_status != 'loading' && ifCurrentAgentId" :stopwatch="stopwatch" />
      </div>
      <div>
        <StatusComp v-if="current_agent_status != 'loading' && ifCurrentAgentId" :value="current_agent_status" :stopwatch="stopwatch" />
      </div>
      <div>
        <LightButton v-if="current_agent_status == 'not_logged_in' && ifCurrentAgentId" title="Begin Session" @button-click="handleAgentSessionStart"/>
        <LightButton v-else-if="ifCurrentAgentId" title="End Session" @button-click="handleAgentSessionEnd"/>
      </div>
      <div v-if="current_agent_status != 'not_logged_in' && current_agent_status != 'loading'">
        <span title="Switch Socket is Connecting" class="dot" style="background-color: yellow;" v-if="agent_socket_status == '0'"></span> 
        <span title="Switch Socket is Connected" class="dot" style="background-color: green;" v-if="agent_socket_status == '1'"></span>
        <span title="Switch Socket is Closing" class="dot" style="background-color: orange;" v-if="agent_socket_status == '2'"></span>
        <span title="Switch Socket is Closed" class="dot" style="background-color: red;" v-if="agent_socket_status == '3'"></span>
      </div>

      <div v-if="true" class="notificationsWrapper">
        <img v-bind:src="getIconSrc()" @click="clickNotificationsIcon" />

        <div class="notificationsPopup">
          <NotificationsPopup
            :usersNotifications="usersNotifications"
            v-if="showNotificationsPopup"
            @close="showNotificationsPopup = false"
          />
        </div>
      </div>
      <div v-if="showPhoneIconProp" class="notificationsWrapper">
        <img
          v-bind:src="require(`@/assets/icons/Phone.png`)"
          @click="clickPhoneIcon"
        />
        <div class="phonePopup">
          <PhonePopout
            v-if="showPhonePopoutProp"
            @close="showPhonePopout = false"
          />
        </div>
      </div>

      <img
        v-if="showHelpIcon"
        v-bind:src="require(`@/assets/icons/Help - Support.png`)"
        @click="clickHelpIcon"
      />
      <div class="profileWrapper">
        <button class="userProfile" @click="clickUserProfile">
          {{ userName.charAt(0) }}
        </button>

        <div class="logoutPopup" v-if="showLogoutPopup">
          <span class="pretendLink" @click="goToProfile">Profile</span>
          <span class="pretendLink" @click="handleLogout">Logout</span>
        </div>
      </div>
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </nav>
</template>

<script>
import NotificationsPopup from "@/components/Notifications/NotificationsPopup.vue";
import PhonePopout from "@/components/webRTC/PhonePopout.vue";
import StatusComp from "@/components/base_components/StatusComp.vue";
import TimeComp from "@/components/base_components/TimeComp.vue";
import { mapStores } from 'pinia';
import { useHelpStore } from "@/stores/Help/helpStore.js";
import { useAuthStore } from "@/stores/auth/authStore.js";
import { useAgentStore } from "@/stores/agentStore.js";
import { useStatusNotificationStore } from "@/stores/site_notifications/statusNotificationStore.js";
import { Storage, Auth} from 'aws-amplify';
// import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
// import * as subscriptions from '@/graphql/subscriptions';
import LightButton from "@/components/base_components/Buttons/LightButton.vue";
import Dropdown from "@/components/base_components/FormComponents/DropDown.vue";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { addAlert } from '@/helpers/alertUtils';
import { ERROR } from "@/constants/statuses.constants.js";

export default {
  name: "NavBar",
  components: {
    NotificationsPopup,
    PhonePopout,
    StatusComp,
    LightButton,
    Dropdown,
    AlertsGroup,
    TimeComp
  },
  props: { 
    hasUnreadNotifications: Boolean,
    userName: String,
    showPhonePopoutProp: Boolean,
    showPhoneIconProp: Boolean,
    status_of_agent_socket: String
  },
  emits: ['close-clickPhoneIcon', 'logout-user', 'status-change'],
  data() {
    return {
      alerts: [],
      showNotificationsPopup: false,
      showPhonePopout: false,
      profileBackground: '#e2f1f4',
      profileColor: '#6b9fd9',
      notificationsSubscription: null,
      showLogoutPopup: false,
      showHelpIcon: false, //TODO: change the default to true when the help documentation is ready.
      pdf_page: "",
      isAgent: false,
      selected_status: null,
      status_options: [
        {label: "Available", value: "Ready"},
        {label: "Away - Lunch", value: "Away_Lunch"},
        {label: "Away - One-on-One", value: "Away_One-on-One"},
        {label: "Away - AFK", value: "Away_AFK"},
        {label: "Away - Meeting", value: "Away_Meeting"},
        {label: "Away - Training", value: "Away_Training"},
      ]
    };
  },
  computed: {
    ...mapStores(useHelpStore, useAuthStore, useStatusNotificationStore, useAgentStore),
    usersNotifications() {
      return this.StatusNotificationStore.getNotifications;
    },
    ifCurrentAgentId() {
      var agent_id = this.AgentStore.getCurrentAgentID;

      if(agent_id) {
        return true;
      } else {
        return false;
      }
    },
    current_agent_status() {
      return this.AgentStore.getAgentStatus;
    },
    stopwatch() {
      return this.AgentStore.getAgentTime;
    },
    wrap_up_started() {
      return this.AgentStore.getWrapUpStarted;
    },
    agent_socket_status() {
      return this.AgentStore.getAgentSocketStatus;
    }
  },
  async mounted() {
    this.pdf_page = this.HelpManagementStore.getPage;

    const user = await Auth.currentAuthenticatedUser();
    const user_id = user?.username;

    // var user_groups = await this.AuthStore.getUsersGroups;
    // const user_group_array = Object.values(user_groups);

    // for (var group of user_group_array) {
      // if (group.Name === "OverflowContactCenter") {
        // this.showHelpIcon = false;
      // }
    // }
    



    if (user_id) {
        // await this.StatusNotificationStore.getInitialUserNotifications(user_id);
        // await this.StatusNotificationStore.getTrackableSystems();

        // const siteNotifcationSubVariables = {
        //     filter: {
        //         user_id: {
        //             eq: user_id
        //         }
        //     }
        // };
        
        // this.notificationsSubscription = API.graphql({
        //     ...graphqlOperation(subscriptions.onCreateSiteNotifications, siteNotifcationSubVariables),
        //     authMode: GRAPHQL_AUTH_MODE.API_KEY
        // }).subscribe({
        //     next: async (notification) => {
        //         this.StatusNotificationStore.handleNewNotification(notification);
        //     },
        // });
    }
  },
  unmounted() {
    if (this.notificationsSubscription) {
        this.notificationsSubscription.unsubscribe();
    }
  },
  methods: {
    getIconSrc() {
      if (this.StatusNotificationStore.getHasUnreadNotifications) {
        return require(`@/assets/icons/Unread Notifications.png`);
      } else {
        return require(`@/assets/icons/Notifications.png`);
      }
    },
    clickUserProfile() {
      this.showLogoutPopup = !this.showLogoutPopup;
    },
    async handleStatusChange(status) {
      this.selected_status = status.value;
    },
    async sendAgentStatusChange() {
      if(this.current_agent_status != 'onAcall' && this.current_agent_status != 'onAExternalCall') {
        if (this.selected_status) {
          await this.AgentStore.updateAgentStatus(this.selected_status);
        }
      } else {
        addAlert(ERROR, "Unable to change status while on a call.", this.alerts);
      }
    },
    async extendAgentWrapupTime() {
      await this.AgentStore.extendAgentWrapupTime();
    },
    async clickHelpIcon() {
      this.pdf_page = this.HelpManagementStore.getPage;
      let formattedString = this.formatToPdfTitle(this.pdf_page);
      let urlString = "";

      let userInfo = await this.AuthStore.getCurrentUser.signInUserSession.accessToken.payload['cognito:groups'];
      if (userInfo && userInfo.includes("SuperUser")) {
        urlString = 'admin_docs';
      } else {
        urlString = 'docs';
      }
      const pdfUrl = await Storage.get(`${urlString}/${formattedString}.pdf`, { level: "public" });
      const newWindow = window.open(pdfUrl, '_blank', 'noopener,noreferrer'); 
      if(newWindow) {
        newWindow.opener = null;
      }
    },
    async handleAgentSessionStart() {
      //working
      await this.AgentStore.startAgentSession();
    },
    async handleAgentSessionEnd() {
      await this.AgentStore.endAgentSession();
    },
    formatToPdfTitle(string) {
      if (string !== "") {
        let newString = string.toLowerCase().replace(/\s/g, '');
        return newString.toLowerCase().replace(/-/g, '');
      }
    },
    clickPhoneIcon() {
      this.$emit("close-clickPhoneIcon", this.showPhonePopoutProp);
    },
    clickNotificationsIcon() {
      this.showNotificationsPopup = !this.showNotificationsPopup;
    },
    clickLogo() {
      // TODO: what needs to be done here besides a console.log? 
      console.log("clicked logo");
    },
    handleLogout() {
      this.$emit('logout-user')
    },
    goToProfile() {
      this.showLogoutPopup = false;
      this.$router.push(`/admin`);
    }
  },
};
</script>

<style scoped>
nav {
  background-color: #271d7a;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0.5px 0.5px 0.5px rgba(39, 29, 122, 0.4);
}

.iconContainer img {
  height: 24px;
  cursor: pointer;
}

.navLogo {
  height: 100%;
  padding: 9px;
}

.iconContainer {
  display: flex;
  padding: 13px;
  gap: 25px;
}

.userProfile {
  background-color: #e2f1f4;
  font-size: 18px;
  width: 28px;
  height: 28px;
  /* padding: 0px 4px; */
  border-radius: 2px;
  color: #6b9fd9;
}

.notificationsWrapper, .profileWrapper {
  position: relative;
}

.notificationsPopup {
  position: absolute;
  z-index: 1000;
  top: 37px;
  left: 50%;
  transform: translateX(-50%);
}

.phonePopup {
  position: absolute;
  z-index: 1000;
  top: 37px;
  left: 70%;
  transform: translateX(-90%);
}

/* .logoutPopup {
  position: absolute;
  z-index: 1000;
  top: 37px;
  right: 90%;
  transform: translateX(25%);
} */

.logoutPopup {
  color: #ffffff;
  overflow: hidden;
  position: absolute;
  background-color: #ffffff;
  right: -2px;
  top: 28px;
  z-index: 1;
  margin-top: 10px;
  padding: 5px;
  filter: drop-shadow(1px 1px 1px rgba(39, 173, 122, 0.2));
  font-size: 10pt;
  font-weight: 400;
}
.logoutPopup a, .logoutPopup .pretendLink {
  display: block;
  padding: 2px 5px 2px 3px;
  color: #271d7a;
  cursor: pointer;
  text-decoration: none;
  text-align: right;
}
.logoutPopup a:hover, .logoutPopup .pretendLink:hover {
  background-color: #eff4f9;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>
