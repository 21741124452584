/* eslint-disable */
import { defineStore } from 'pinia'
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
const hostname = window.location.hostname;
const ENVIRONMENT = hostname.split('.').length == 2
    ? ""
    : hostname.indexOf('localhost') !== -1
        ? "developer-"
        : hostname.split('.')[0] + "-";


export const useAccountLimitsStore = defineStore('AccountLimits', {
    state: () => ({
        limitInformation: {},
    }),
    getters: {
        getlimitInformation(state) {
            // console.log("Getter state:", state.limitInformation);
            return state.limitInformation
        }
    },
    actions: {
        async fetchLimits(accountNumber) {
            var error_message = 'Unable to Retrieve Limits';

            try {
                const api_name = "switch";
                const path = `/accounts/${accountNumber}/limits_frontend`;

                const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

                const myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    }
                }

                let result_json = await API.get(api_name, path, myInit);

                if (result_json.data.status !== 'success') {
                    return { error: true, message: error_message };
                }

                this.limitInformation = {
                    twoway_trunks: result_json.data.data.twoway_trunks,
                    inbound_trunks: result_json.data.data.inbound_trunks,
                    outbound_trunks: result_json.data.data.outbound_trunks,
                    resource_consuming_calls: result_json.data.data.resource_consuming_calls,
                    allow_prepay: result_json.data.data.allow_prepay ? 'Yes' : 'No',
                };

                // console.log("this.limitInformation", this.limitInformation);

                return { error: false, message: 'Ok' };

            }
            catch (error) {
                return { error: true, message: error };
            }
        },
        async setLimits(twoway_trunks, allow_prepay, outbound_trunks, resource_consuming_calls, inbound_trunks, accountNumber) {
            var error_message = 'Unable to Set Limits';
            try {
                // if (twoway_trunks > 5000) { twoway_trunks = 5000; }
                // if (outbound_trunks > 5000) { outbound_trunks = 5000; }
                // if (resource_consuming_calls > 5000) { resource_consuming_calls = 5000; }
                // if (inbound_trunks > 5000) { inbound_trunks = 5000; }

                // var bodyData = {
                //     "twoway_trunks": twoway_trunks,
                //     "inbound_trunks": inbound_trunks,
                //     "id": "limits",
                //     "allow_prepay": allow_prepay,
                //     "outbound_trunks": outbound_trunks,
                //     "resource_consuming_calls": resource_consuming_calls
                // }

                // const api_name = "switch";
                // const path = `/accounts/${accountNumber}/limits_frontend`;

                // const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

                // const myInit = {
                //     headers: {
                //         Authorization: userAuth,
                //         "Content-Type": "application/json"
                //     },
                //     body: bodyData
                // }

                // let result_json = await API.post(api_name, path, myInit);

                // if (result_json.status !== 'success') {
                //     return { error: true, message: error_message };
                // }

                return { error: false, message: 'Ok' };
            }
            catch (error) {
                return { error: true, message: error_message };
            }
        }
    },
})

