<template>
  <button
    class="checkbox"
    :class="{ checkboxSelected: isSelected }"
    @click.prevent="toggleSelected"
  >
    <span class="checkbox-icon"></span>
    <span class="checkbox-check"></span>
  </button>
</template>

<script>
export default {
  name: "CheckBox",
  emits: ["toggle-selected"],
  props: {
    item: [Object, String, Number, Boolean],
    checked: Boolean,
    unSelectable: { type: Boolean, default: false },
    multiSelect: { type: Boolean, default: true },
  },
  data() {
    return {
      isSelected: this.checked,
    };
  },
  watch: {
    checked(newChecked) {
      this.isSelected = newChecked;
    },
  },
  methods: {
    toggleSelected() {
      if (this.multiSelect) {
        this.isSelected = !this.isSelected;
        this.$emit("toggle-selected", this.item);
      } else {
        if (!this.isSelected || this.unSelectable) {
          this.isSelected = !this.isSelected;
          this.$emit("toggle-selected", this.item);
        }
      }
    },
  },
  computed: {
    getChecked() {
      return this.checked;
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  outline: 0.25pt solid #2e3e91;
  margin-right: 5px;
  position: relative;
  background-color: #fff;
}

.checkbox-icon::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: none;
}
.checkbox:hover .checkbox-icon::before {
  outline: 0.5pt solid #2e3e91;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
}

.checkbox:active .checkbox-icon::before {
  content: url(../../../assets/icons/Success-Blue.svg);
  outline: 1pt solid #2e3e91;
  font-size: 20px;
  line-height: 6px;
  text-align: center;
  padding: 0px 2px 0px 2px;
}

.checkbox.checkboxSelected .checkbox-icon::before {
  content: url(../../../assets/icons/Success.svg);
  color: #fff;
  background-color: #2b2d84;
  font-size: 20px;
  line-height: 6px;
  text-align: center;
  padding: 0px 2px 0px 2px;
}
</style>
