import { defineStore } from 'pinia'
import { API } from "aws-amplify";
import { Auth } from "aws-amplify"
import * as queries from "@/graphql/queries.js";
import * as mutations from "@/graphql/mutations.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Storage } from 'aws-amplify';
import awsconfig from '@/aws-exports';


export const useQualityAuditStore = defineStore('QualityAudit', {
    state: () => ({
        audits: [],
        employees: [],
    }),
    getters: {
        getEmployees(state) {
            return state.employees
        },
        getAudits(state) {
            return state.audits
        },
    },
    actions: {
        transformToAWSDate(date) {
            const [month, day, year] = date.split('/');
            const dateObj = new Date(Date.UTC(year, month - 1, day));
            return `${dateObj.getUTCFullYear()}-${String(dateObj.getUTCMonth() + 1).padStart(2, '0')}-${String(dateObj.getUTCDate()).padStart(2, '0')}`;
        },
        formatDate(inputDate) {
            const [year, month, day] = inputDate.split('-');
            return `${month}/${day}/${year}`;
        },
        async fetchQualityAuditsOverview(params) {
            //console.log("ENTERED GET FETCH AUDITS INFORMATION", params);
            this.audits = [];

            const employeeData = {};
            try {
                var hasData = true;
                var nextToken = null;
                var auditData = [];

                const filterObject = {
                    _deleted: { ne: true }
                };

                if (params && params.start_date !== undefined && params.end_date !== undefined) {
                    const formatDate = (dateStr) => dateStr.split('/').reverse().join('-');
                    const start_date = formatDate(params.start_date);
                    const end_date = formatDate(params.end_date);
                    // console.log("start_date", start_date)
                    // console.log("end_date", end_date)

                    filterObject.and = [{ date_of_audit: { gt: start_date, le: end_date } }];
                }


                const listContactCenterQualityAudits = /* GraphQL */ `
                query ListContactCenterQualityAudits(
                    $filter: ModelContactCenterQualityAuditsFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listContactCenterQualityAudits(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                    ) {
                    items {
                        id
                        employee_id
                        date_of_call
                        date_of_audit
                        name_of_auditor
                        call_recording
                        call_type
                        point_total
                        status
                        employee {
                            name
                        }
                        audit_object
                        mutated_object
                        compliance
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                    nextToken
                    startedAt
                    __typename
                    }
                }
                `;

                while (hasData) {
                    var options = {
                        query: listContactCenterQualityAudits,
                        variables: {
                            filter: filterObject,
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }


                    var audits = await API.graphql(options);


                    for (const item of audits.data.listContactCenterQualityAudits.items) {
                        auditData.push(item);
                    }

                    if (audits.data.listContactCenterQualityAudits.nextToken != null) {
                        nextToken = audits.data.listContactCenterQualityAudits.nextToken;
                    } else {
                        hasData = false;
                    }
                    auditData.forEach((audit) => {
                        const employee = audit.employee ? audit.employee.name : "";
                        //const parsedAuditObject = JSON.parse(audit.audit_object);
                        const parsedMutatedObject = JSON.parse(audit.mutated_object);
                        let score = {};
                        parsedMutatedObject.forEach(item => {
                            switch (item.category) {
                                case 'Overall Tone':
                                    score.tone = item.score;
                                    break;
                                case 'Strong Greeting And Ending':
                                    score.greeting = item.score;
                                    break;
                                case 'Troubleshooting':
                                    score.trouble = item.score;
                                    break;
                                case 'Documentation':
                                    score.documentation = item.score;
                                    break;
                                case 'Compliance':
                                    score.compliance = item.score;
                                    break;
                                default:
                                    break;
                            }
                        });

                        if (!employeeData[employee]) {
                            employeeData[employee] = {
                                numberOfAudits: 0,
                                totalScore: 0,
                                toneScore: 0,
                                greetingScore: 0,
                                troubleScore: 0,
                                documentationScore: 0,
                                complianceScore: 0,
                            };
                        }

                        const data = employeeData[employee];
                        data.numberOfAudits += 1;
                        data.totalScore += (score.tone + score.greeting + score.trouble + score.documentation);
                        data.toneScore += score.tone;
                        data.greetingScore += score.greeting;
                        data.troubleScore += score.trouble;
                        data.documentationScore += score.documentation;
                        if (score.compliance === 'Pass') {
                            data.passCount = (data.passCount || 0) + 1;
                        } else {
                            data.failCount = (data.failCount || 0) + 1;
                        }
                    });

                    //console.log('employeeData: ', employeeData)
                    const employeeAverages = Object.entries(employeeData).map(([employee, data]) => {
                        return {
                            employee,
                            number_of_audits: data.numberOfAudits,
                            average_total_score: data.totalScore / data.numberOfAudits,
                            average_percent_score: (data.totalScore / data.numberOfAudits) * 100 / 500,
                            average_tone_score: data.toneScore / data.numberOfAudits,
                            average_ticket_score: (data.troubleScore + data.documentationScore) / data.numberOfAudits,
                            compliance_score: (data.passCount || 0) > (data.failCount || 0) ? 'Pass' : 'Fail',
                            average_greeting_score: data.greetingScore / data.numberOfAudits,
                            average_documentation_score: data.documentationScore / data.numberOfAudits,
                        };
                    });

                    //console.log("Employee averages:", employeeAverages);
                    return employeeAverages;
                }

            } catch (error) {
                return { error: true, message: error };
            }
        },
        async addQualityAudit(data, list) {

            let totalScore = 0;
            let compliance = '';
            for (const item of list) {
                if (item.category === "Compliance") {
                    compliance = item.score;
                } else {
                    totalScore += item.score;
                }
            }

            const queryOptions = {
                query: queries.listEmployees,
                variables: {
                    filter: { name: { eq: data.wizard['Basic Information'].employee } }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            try {
                const queryResult = await API.graphql(queryOptions);
                if (queryResult.data.listEmployees.items.length === 0) {
                    return { error: true, message: "Employee not found" };
                }

                const employee_id = queryResult.data.listEmployees.items[0].id;

                const options = {
                    query: mutations.createContactCenterQualityAudits,
                    variables: {
                        input: {
                            employee_id: employee_id,
                            date_of_call: this.transformToAWSDate(data.wizard['Basic Information'].callDate),
                            date_of_audit: this.transformToAWSDate(data.wizard['Basic Information'].auditDate),
                            ticket_number: data.wizard['Basic Information'].ticket_number,
                            name_of_auditor: data.fillInInfo.auditor,
                            call_recording: data.fillInInfo.file.name,
                            call_type: data.wizard.Compliance.callType,
                            point_total: totalScore.toString(),
                            status: "Unpublished",
                            audit_object: JSON.stringify(data.wizard),
                            mutated_object: JSON.stringify(list),
                            compliance: compliance
                        }
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                };

                const result = await API.graphql(options);

                if (!result.data.createContactCenterQualityAudits.id) {
                    return { error: true, message: "Unable to Add Audit" };
                } else {
                    return { error: false, message: 'Ok', auditID: result.data.createContactCenterQualityAudits.id };
                }
            } catch (error) {
                return { error: true, message: "Unable to Add Audit" };
            }
        },
        async fetchQualityAuditsById(id) {
            const getContactCenterQualityAudits = /* GraphQL */ `query GetContactCenterQualityAudits($id: ID!) {
            getContactCenterQualityAudits(id: $id) {
              id
              employee_id
                employee {
                  id
                  name
                  email
                  position
                  employee_ticket_number
                  supervisor
                  start_time
                  terminated
                  date_terminated
                  duration
                  work_days
                  discipline_level
                  assigned_group
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
              ticket_number
              date_of_call
              date_of_audit
              name_of_auditor
              call_recording
              call_type
              point_total
              status
              audit_object
              mutated_object
              compliance
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
        }`;
            const options = {
                query: getContactCenterQualityAudits,
                variables: { id },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            try {
                const response = await API.graphql(options);
                if (response && response.data && response.data.getContactCenterQualityAudits) {
                    let audit = response.data.getContactCenterQualityAudits;
                    const parsedAuditObject = JSON.parse(audit.audit_object);
                    const parsedMutatedObject = JSON.parse(audit.mutated_object);

                    if (audit.call_recording) {
                        try {

                            let audioURL = await Storage.get(`quality_audit/audio_files/${audit.call_recording}`, { level: 'public' });
                            let response = await fetch(audioURL);
                            let blob = await response.blob();

                            audit.audio = await new Promise((resolve, reject) => {
                                let reader = new FileReader();

                                reader.onloadend = function () {
                                    let base64data = reader.result;
                                    resolve({
                                        audioFileName: audit.call_recording,
                                        filename: audit.call_recording,
                                        audioSrc: base64data,
                                        audioLocation: `quality_audit/audio_files/${audit.call_recording}`,
                                    });
                                };

                                reader.onerror = reject;

                                reader.readAsDataURL(blob);
                            });
                        } catch (error) {
                            console.error("Failed to fetch audio:", error);
                            audit.audio = null;
                        }
                    } else {
                        audit.audio = null;
                    }

                    let score = {};
                    parsedMutatedObject.forEach(item => {
                        switch (item.category) {
                            case 'Overall Tone':
                                score.tone = item.score;
                                score.tone_comments = parsedAuditObject['Overall Tone'].comments;
                                break;
                            case 'Strong Greeting And Ending':
                                score.greeting = item.score;
                                score.greeting_comments = parsedAuditObject['Strong Greeting And Ending'].comments;
                                break;
                            case 'Troubleshooting':
                                score.trouble = item.score;
                                score.trouble_comments = parsedAuditObject['Troubleshooting'].comments;
                                break;
                            case 'Documentation':
                                score.documentation = item.score;
                                score.documentation_comments = parsedAuditObject['Documentation'].comments;
                                break;
                            case 'Compliance':
                                score.compliance = item.score;
                                score.compliance_comments = parsedAuditObject['Compliance'].comments;
                                break;
                            default:
                                break;
                        }
                    });

                    if (parsedAuditObject.Compliance && parsedAuditObject.Compliance.comments) {
                        score.compliance_comments = parsedAuditObject.Compliance.comments;
                    }

                    audit = {
                        id: audit.id,
                        audit_date: audit.date_of_audit,
                        employee: audit.employee?.name ? audit.employee.name : "",
                        ticket_number: audit.ticket_number ? audit.ticket_number : "",
                        date_call: audit.date_of_call,
                        auditor: audit.name_of_auditor,
                        audit_status: audit.status,
                        score,
                        audio: audit.audio,
                        parsedAuditObject,
                        parsedMutatedObject
                    };

                    //console.log("Final audit object:", audit);

                    this.audits = [audit];

                    return this.audits;
                } else {
                    return { error: true, message: "No data found for the given ID" };
                }
            } catch (error) {
                console.error("Error fetching audit by ID:", error);
                return { error: true, message: error };
            }
        },
        async fetchQualityAudits() {
            this.audits = [];
            try {
                var hasData = true;
                var nextToken = null;
                var auditData = [];

                const listContactCenterQualityAudits = /* GraphQL */ `
                query ListContactCenterQualityAudits(
                    $filter: ModelContactCenterQualityAuditsFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listContactCenterQualityAudits(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                    ) {
                    items {
                        id
                        employee_id
                        date_of_call
                        date_of_audit
                        name_of_auditor
                        call_recording
                        call_type
                        point_total
                        ticket_number
                        status
                        employee {
                            name
                            employee_ticket_number
                        }
                        audit_object
                        mutated_object
                        compliance
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                    nextToken
                    startedAt
                    __typename
                    }
                }
                `;

                while (hasData) {
                    var options = {
                        query: listContactCenterQualityAudits,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var audits = await API.graphql(options);

                    for (const item of audits.data.listContactCenterQualityAudits.items) {
                        auditData.push(item);
                    }

                    if (audits.data.listContactCenterQualityAudits.nextToken != null) {
                        nextToken = audits.data.listContactCenterQualityAudits.nextToken;
                    } else {
                        hasData = false;
                    }
                }

                let audioPromises = auditData.map((audit, index) => {
                    return new Promise((resolve, reject) => {
                        console.log("reject", reject)
                        if (!audit.call_recording) {
                            console.error("No call recording for audit:", audit);
                            resolve();
                            return;
                        }
        
                        Storage.get(`quality_audit/audio_files/${audit.call_recording}`, { level: 'public' })
                            .then(audioURL => fetch(audioURL))
                            .then(response => response.blob())
                            .then(blob => {
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                    let base64data = reader.result;
                                    auditData[index].audio = {
                                        audioFileName: audit.call_recording,
                                        filename: audit.call_recording,
                                        audioSrc: base64data,
                                        audioLocation: `quality_audit/audio_files/${audit.call_recording}`,
                                    };
                                    resolve();
                                };
                                reader.onerror = () => {
                                    console.error("Failed to load audio for", audit.call_recording);
                                    resolve(); 
                                };
                                reader.readAsDataURL(blob);
                            })
                            .catch(error => {
                                console.error("Failed to fetch audio:", error);
                                auditData[index].audio = null;
                                resolve();
                            });
                    });
                });
        
                await Promise.all(audioPromises);
                //console.log("All audio files loaded");


                this.audits = auditData.map((audit) => {
                    const parsedAuditObject = JSON.parse(audit.audit_object);
                    const parsedMutatedObject = JSON.parse(audit.mutated_object);

                    let score = {};
                    parsedMutatedObject.forEach(item => {
                        switch (item.category) {
                            case 'Overall Tone':
                                score.tone = item.score;
                                score.tone_comments = parsedAuditObject['Overall Tone'].comments;
                                break;
                            case 'Strong Greeting And Ending':
                                score.greeting = item.score;
                                score.greeting_comments = parsedAuditObject['Strong Greeting And Ending'].comments;
                                break;
                            case 'Troubleshooting':
                                score.trouble = item.score;
                                score.trouble_comments = parsedAuditObject['Troubleshooting'].comments;
                                break;
                            case 'Documentation':
                                score.documentation = item.score;
                                score.documentation_comments = parsedAuditObject['Documentation'].comments;
                                break;
                            case 'Compliance':
                                score.compliance = item.score;
                                score.compliance_comments = parsedAuditObject['Compliance'].comments;
                                break;
                            default:
                                break;
                        }
                    });

                    if (parsedAuditObject.Compliance && parsedAuditObject.Compliance.comments) {
                        score.compliance_comments = parsedAuditObject.Compliance.comments;
                    }

                    return {
                        id: audit.id,
                        audit_date: audit.date_of_audit,
                        employee: audit.employee?.name ? audit.employee.name : "",
                        ticket_number: audit.ticket_number ? audit.ticket_number : "",
                        date_call: audit.date_of_call,
                        auditor: audit.name_of_auditor,
                        audit_status: audit.status,
                        score,
                        audio: audit.audio,
                        parsedAuditObject,
                        parsedMutatedObject
                    };
                });
                
                return this.audits;
            } catch (error) {
                return { error: true, message: error };
            }
        },
        async getEmployeeInformation() {
            this.employeeOptions = [];
            try {
                var hasData = true;
                var nextToken = null;
                var employeeData = [];


                while (hasData) {
                    var options = {
                        query: queries.listEmployees,
                        variables: {
                            filter: {
                                _deleted: { ne: true },
                                assigned_group: { eq: "Contact Center" }
                            },
                            limit: 2000
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    }

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var employees = await API.graphql(options);

                    for (const item of employees.data.listEmployees.items) {
                        employeeData.push(item);
                    }

                    if (employees.data.listEmployees.nextToken != null) {
                        nextToken = employees.data.listEmployees.nextToken;
                    }
                    else {
                        hasData = false;
                    }
                }

                // Sort 
                employeeData.sort((a, b) => a.name.localeCompare(b.name));

                this.employees = employeeData;

                return this.employees
            }
            catch (error) {
                return { error: true, message: error };
            }
        },
        async publishAudit(id, audit_status) {
            const error_message = "Unable to publish audit";

            const queryOptions = {
                query: queries.getContactCenterQualityAudits,
                variables: { id: id },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            const queryResult = await API.graphql(queryOptions);

            if (!queryResult.data || !queryResult.data.getContactCenterQualityAudits) {
                return { error: true, message: "Infraction not found" };
            }

            const _version = queryResult.data.getContactCenterQualityAudits._version;

            const updateOptions = {
                query: mutations.updateContactCenterQualityAudits,
                variables: {
                    input: {
                        id: id,
                        status: audit_status,
                        _version: _version
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            try {
                const result = await API.graphql(updateOptions);

                if (!result.data.updateContactCenterQualityAudits.id) {
                    return { error: true, message: error_message };
                } else {
                    return { error: false };
                }
            } catch (error) {
                return { error: true, message: error_message };
            }
        },
        calculateSectionScoreAndSatisfactory(section, auditObject, pointValues, scales) {
            let score = 0;
            let satisfactory = false;

            if (section !== 'Compliance') {
                for (const field in auditObject[section]) {
                    if (field !== 'comments' && auditObject[section][field] === true) {
                        score += pointValues[section][field];
                    }
                }

                satisfactory = score >= parseInt(scales[section].Satisfactory.split('-')[0]);
            } else {
                const callType = auditObject.Compliance.callType;
                const verificationLevel = auditObject.Compliance.verificationLevel;

                const criteria = {
                    twoStep_inbound: [
                        'verifiedFullAccountHolderSName',
                        'verifiedFullServiceAddress',
                        'askedForPermissionToAccessAccount'
                    ],
                    twoStep_outbound: [
                        'statementOfRecording',
                        'verifiedFullAccountHolderSName',
                        'verifiedFullServiceAddress',
                        'askedForPermissionToAccessAccount'
                    ],
                    threeStep_inbound: [
                        'verifiedFullAccountHolderSName',
                        'verifiedFullServiceAddress',
                        'verifiedAccountNumberOrLast4DigitsOfPartnerProvidedRgOrOntMacAddress',
                        'askedForPermissionToAccessAccount'
                    ],
                    threeStep_outbound: [
                        'statementOfRecording',
                        'verifiedFullAccountHolderSName',
                        'verifiedFullServiceAddress',
                        'verifiedAccountNumberOrLast4DigitsOfPartnerProvidedRgOrOntMacAddress',
                        'askedForPermissionToAccessAccount'
                    ]
                };

                const complianceCriteria = criteria[`${verificationLevel}_${callType}`];

                satisfactory = complianceCriteria.every(field => {
                    return auditObject.Compliance[field];
                });

                score = satisfactory ? 'Pass' : 'Fail';
            }

            return { score, satisfactory };
        },
        async editAudit(submissionData) {
            let error_message = "Unable to update audit"

            const queryOptions = {
                query: queries.getContactCenterQualityAudits,
                variables: { id: submissionData.id },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            const queryResult = await API.graphql(queryOptions);

            if (!queryResult.data || !queryResult.data.getContactCenterQualityAudits) {
                return { error: true, message: "Audit not found" };
            }

            const _version = queryResult.data.getContactCenterQualityAudits._version;

            let awsAudit_date = this.transformToAWSDate(submissionData.audit_date);
            let awsDate_call = this.transformToAWSDate(submissionData.date_call);

            let auditObject = submissionData.auditFieldsObject;
            //let mutatedObject = submissionData.mutatedObject;

            if (submissionData.compliance_comments) {
                auditObject.Compliance.comments = submissionData.compliance_comments;
            }
            if (submissionData.tone_comments) {
                auditObject['Overall Tone'].comments = submissionData.tone_comments;
            }
            if (submissionData.greeting_comments) {
                auditObject['Strong Greeting And Ending'].comments = submissionData.greeting_comments;
            }
            if (submissionData.trouble_comments) {
                auditObject.Troubleshooting.comments = submissionData.trouble_comments;
            }
            if (submissionData.documentation_comments) {
                auditObject.Documentation.comments = submissionData.documentation_comments;
            }

            const pointValues = {
                "Overall Tone": {
                    "askingForMemberSAssistanceInsteadOfOrderingMemberAround": 2,
                    "avoidingDeadAir": 4,
                    "backgroundSoundQuality": 3,
                    "ownershipThroughoutCall": 2,
                    "pleaseAndThankYouPhrases": 4,
                    "politeThroughCall": 4,
                    "positiveTone": 4
                },
                "Strong Greeting And Ending": {
                    "endingBrandingWithPartnerName": 1,
                    "endingConfidentEnding": 2,
                    "endingRecapValueStatement": 1,
                    "endingSettingCorrectExpectationsPositioning": 2,
                    "greetingEmpathyStatement": 2,
                    "greetingIdentifyCorrectPartner": 1,
                    "greetingIdentifyIssueOfferAssistance": 1,
                    "greetingIdentifyName": 1,
                    "greetingOfferTicketNumber": 1
                },
                "Troubleshooting": {
                    "bestPossibleResolutionReached": 10,
                    "correctUseOfProbingQuestions": 5,
                    "followedCorrectTechnicalTroubleshootingProcess": 10,
                    "memberLevelDescriptionOfEquipmentLimitedTechJargon": 5,
                    "rebootedOntAndRgWithMemberPermission": 5,
                    "remainedWithinScopeOfSupport": 5
                },
                "Documentation": {
                    "correctEscalationField": 1,
                    "correctIssueCode": 1,
                    "correctResolutionCode": 1,
                    "correctVerificationField": 3,
                    "filledOutFlowProperlyAndTruthfully": 3,
                    "followedFlowStepsCorrectly": 3,
                    "partnerSpecificTicketHandling": 3,
                    "professionalAndDetailedNotes": 5,
                    "usedAdvancedSupportFlow": 5
                }
            };

            for (const section in auditObject) {
                if (Object.prototype.hasOwnProperty.call(auditObject, section)) {
                    if (section === "Basic Information") continue;

                    for (const field in auditObject[section]) {
                        if (Object.prototype.hasOwnProperty.call(auditObject[section], field) && field !== 'comments') {
                            if (submissionData[field] === false) {
                                delete auditObject[section][field];
                            }
                        }
                    }
                }
            }

            if (auditObject.Compliance) {
                for (const field in auditObject.Compliance) {
                    if (field !== 'comments' && field !== 'callType' && field !== 'verificationLevel') {
                        auditObject.Compliance[field] = !!submissionData[field];
                    }
                }
            }

            const complianceFields = ['verifiedFullAccountHolderSName', 'verifiedFullServiceAddress', 'askedForPermissionToAccessAccount', 'verifiedAccountNumberOrLast4DigitsOfPartnerProvidedRgOrOntMacAddress', 'statementOfRecording'];
            complianceFields.forEach(field => {
                if (submissionData[field] !== undefined) {
                    auditObject.Compliance[field] = !!submissionData[field];
                }
            });

            auditObject.Compliance.callType = submissionData['Call Type'];
            auditObject.Compliance.verificationLevel = submissionData['Verification Level'];

            for (const field in submissionData) {
                if (!field.startsWith("comments") && submissionData[field] === true) {
                    for (const section in pointValues) {
                        if (Object.prototype.hasOwnProperty.call(pointValues[section], field) && !auditObject[section]?.[field]) {
                            auditObject[section] = auditObject[section] || {};
                            auditObject[section][field] = true;
                        }
                    }
                }
            }

            //console.log("FINAL AUDIT OBJECT", auditObject);

            let newMutatedObject = [];

            const scales = {
                "Overall Tone": { UnSatisfactory: '0-19', Satisfactory: '20-23' },
                "Strong Greeting And Ending": { UnSatisfactory: '0-9', Satisfactory: '10-12' },
                "Troubleshooting": { UnSatisfactory: '0-35', Satisfactory: '36-40' },
                "Documentation": { UnSatisfactory: '0-21', Satisfactory: '22-25' },
            };

            let totalScore = 0;

            for (const section in pointValues) {
                if (Object.prototype.hasOwnProperty.call(pointValues, section)) {
                    const { score, satisfactory } = this.calculateSectionScoreAndSatisfactory(section, auditObject, pointValues, scales);
                    newMutatedObject.push({ category: section, score: score, satisfactory: satisfactory });
                    totalScore += score;
                }
            }

            const complianceScoreAndSatisfactory = this.calculateSectionScoreAndSatisfactory('Compliance', auditObject, pointValues, scales);
            newMutatedObject.push({
                category: 'Compliance',
                score: complianceScoreAndSatisfactory.score,
                satisfactory: complianceScoreAndSatisfactory.satisfactory
            });

            //console.log("NEW MUTATED OBJECT", newMutatedObject);


            const updateOptions = {
                query: mutations.updateContactCenterQualityAudits,
                variables: {
                    input: {
                        id: submissionData.id,
                        ticket_number: submissionData.ticket_number,
                        date_of_audit: awsAudit_date,
                        date_of_call: awsDate_call,
                        call_type: auditObject.Compliance.callType,
                        audit_object: JSON.stringify(auditObject),
                        mutated_object: JSON.stringify(newMutatedObject),
                        compliance: complianceScoreAndSatisfactory.score,
                        point_total: totalScore.toString(),
                        _version: _version
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            try {
                const result = await API.graphql(updateOptions);

                if (!result.data.updateContactCenterQualityAudits.id) {
                    return { error: true, message: error_message };
                } else {
                    return { error: false };
                }
            } catch (error) {
                return { error: true, message: error };
            }
        },
        async sendMail(submissionData) {
            var error_message = 'Unable to Retrieve Limits';

            const emailData = {
                to: submissionData.email,
                subject: 'Audit Review',
                body: 'Attached Audit Review',
                from: "no-reply@lexconnect.cloud",

                attachments: [
                    {
                        bucket: awsconfig.aws_user_files_s3_bucket,
                        path: submissionData.attachments[0].path,
                        fileName: submissionData.attachments[0].filename
                    }
                ]
            };

            try {
                const api_name = "mail";
                const path = `/frontend/send`;

                const userAuth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

                const myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    },
                    body: emailData
                }

                let result_json = await API.post(api_name, path, myInit);

                if (!result_json.success) {
                    return { error: true, message: error_message };
                }

                return { error: false, message: result_json.data };

            }
            catch (error) {
                return { error: true, message: error };
            }
        },
    },
})