<template>
  <div class="SchedulingCoverage">
    <CoverageDrawer
      v-model:drawerOpen="localDrawer"
      :drawerData="drawerData"
      @close-click="handleCloseClick"
      @submit-click="handleApplyFilter"
      @filters-cleared="resetTableData"
      :numCols="12"
    />
    <div class="tableContainer">
      <CoverageTableComp
        :headers="tableHeaders"
        :data-list="filteredData"
        :rows-per-page="24"
        background-color="#e2f1f4"
        :single-select="true"
        :select-all="false"
        :fixedWidth="false"
        uniqueKey="id"
        :left-nav-present="true"
        @click-row="handleClickRow"
      />
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>

<script>
import CoverageTableComp from "@/components/Voice/Management/Table/CoverageTableComp.vue";
import CoverageDrawer from '@/components/CcManagement/Attendance/Drawer/AuditOverviewDrawer.vue';
import { DAYS } from '@/constants/days.constants.js'
import { TIME_SLOTS } from '@/constants/time-slots.constants.js';
import AlertsGroup from '@/components/base_components/AlertsGroup.vue';
import { addAlert } from '@/helpers/alertUtils';
import { ERROR } from "@/constants/statuses.constants.js";
import { useCCManagementSchedulingStore } from "@/stores/ContactCenter/Management/CCManagementSchedulingStore.js";
import { mapStores } from 'pinia';

export default {
  name: 'CoverageView',
  components: {
    CoverageTableComp,
    CoverageDrawer,
    AlertsGroup
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tableHeaders: [
        { text: "Time Periods", value: "time" },
        { text: "Sunday", value: "sunday" },
        { text: "Monday", value: "monday" },
        { text: "Tuesday", value: "tuesday" },
        { text: "Wednesday", value: "wednesday" },
        { text: "Thursday", value: "thursday" },
        { text: "Friday", value: "friday" },
        { text: "Saturday", value: "saturday" },
      ],
      tableData:  [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
      ],
      drawerData: [
        {
          title: 'Filters', button: 'Apply', type: 'apply'
        },
        {
          title: "Start Date always set 6 months back",
          details: [
            {
              name: 'End Date',
              type: 'datePicker',
              value: 'end_date'
            },
            {
              name: 'Weeks',
              type: 'input',
              value: 'weeks'
            },
            {
              name: 'Position',
              value: "position",
              type: "multiSelect",
              default: "Select a Position",
              options: [

              ]
            },
            {
              name: 'Chart Type',
              value: "chart_type",
              type: "select",
              default: 'Select chart type',
              options: [
                {
                  label: "Coverage",
                  value: "Coverage",
                },
                {
                  label: "Staffing",
                  value: "Staffing",
                },
                {
                  label: "Volume",
                  value: "Volume",
                }
              ]
            }
          ]
        }
      ],
      alerts: [],
    }
  },
  computed: {
    ...mapStores(useCCManagementSchedulingStore),
    localDrawer: {
      get () {
        return this.drawer
      },
      set(value) {
        this.$emit('update:drawer', value)
      }
    },
    searchResult: {
      get () {
        return this.search;
      },
      set (search) {
        this.$emit('update:search', search);
      }
    },
    maxValue () {
      let result = this.tableData[0][0];

      for (let i = 0; i < this.tableData.length; i++) {
        for (let j = 0; j < this.tableData[i].length; j++) {
          if (this.tableData[i][j] > result) {
            result = this.tableData[i][j];
          }
        }
      }
      return result;
    },
    getTableData () {
      return this.tableData.map((item, index) => {
        return {
          time: TIME_SLOTS[index],
          ...this.getTableDay(item)
        }
      })
    },
    filteredData() {
      const containsSearchTerm = (obj, searchTerm) => {
        for (const key in obj) {
          if (key !== 'color') {
            if (typeof obj[key] === 'object') {
              if (containsSearchTerm(obj[key], searchTerm)) {
                return true;
              }
            } else {
              if ((key === 'time' || key === 'value') && String(obj[key]).toLowerCase().includes(searchTerm.toLowerCase())) {
                return true;
              }
            }
          }
        }
        return false;
      };

      const filterFunction = (item) => {
        return containsSearchTerm(item, this.searchResult);
      };

      if (this.searchResult !== '') {
        return this.getTableData.filter(filterFunction);
      }

      return this.getTableData;
    }
  },
  methods: {
    handleCloseClick () {
      this.localDrawer = false;
    },
    resetTableData() {
      this.tableData = this.initialTableData();
    },
    initialTableData() {
      return [
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
      ];
    },
    getTableDay (item) {
      let result = {};
      item.forEach((value, index) => {
        result[DAYS[index]] = {
          value,
          color: `rgba(62, 135, 211, ${parseInt(value * 100/this.maxValue)/100})`
        }
      })

      return result;
    },
    async handleApplyFilter(data) {
      //console.log("Filter Data", data);

      const isValid = this.validateChartInputs(data);
      if (!isValid) {
        addAlert(ERROR, "Missing required fields for the selected chart type", this.alerts);
        return;
      }

      const filteredData = await this.CCManagementSchedulingStore.getCoverageData(data);

      if (typeof filteredData === 'object' && !Array.isArray(filteredData)) {
        this.tableData = Object.keys(filteredData).map(day => filteredData[day]);
      } else {
        this.tableData = filteredData;
      }
    },
    validateChartInputs(data) {
      const { chart_type: chartType, weeks, position, end_date: endDate, start_date: startDate } = data;

      switch (chartType) {
        case 'Coverage':
          return !!weeks && !!position && !!startDate && !!endDate;
        case 'Staffing':
          return !!position;
        case 'Volume':
          return !!startDate && !!endDate;
        default:
          return false;
      }
    },
    handleClickRow (data) {
      console.log(data);
    }
  },
  async mounted() {
    try {
      const positions = await this.CCManagementSchedulingStore.fetchPositions();

      const positionOptions = positions.map(pos => ({ label: pos, value: pos }));

      this.drawerData[1].details.forEach(detail => {
        if (detail.value === 'position') {
          detail.options = positionOptions;
        }
      });
    } catch (error) {
      console.error('Error fetching positions:', error);
    }
  },
  unmounted () {
    this.localDrawer = false;
    this.searchResult = '';
  },
  emits: [
    "update:drawer",
    "update:search"
  ]
}
</script>

<style scoped>
.SchedulingCoverage {
  flex-grow: 1;
  max-height: calc(100vh - 93px);
  overflow-y: auto;
}
.tableContainer {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
@media (max-width: 820px) {
  .SchedulingCoverage .tableContainer {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.sideDrawer {
  position: fixed;
  top: auto;
  z-index: 6;
  bottom: 0;
  min-height: calc(100vh - 93px);
  max-height: calc(100vh - 93px);
}
</style>