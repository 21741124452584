<script setup>
    import { toRefs } from 'vue';
    import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
    import "@aws-amplify/ui-vue/styles.css";

    import { Amplify, I18n } from 'aws-amplify';
    import awsconfig from '@/aws-exports';


    Amplify.configure(awsconfig);

    I18n.putVocabulariesForLanguage('en', {
        'Sign In': 'Login', // Tab header
        'Sign in': 'Login', // Button label,
        'Send code': 'Reset Password'
    });

    const formFields = {
        signIn: {
            username: {
                placeholder: ' ',
                label: 'Username'
            },
            password: {
                placeholder: ' '
            }
        },
        resetPassword: {
            username: {
                placeholder: ' ',
                label: 'Username',
            }
        }
    }

    const { toResetPassword, toSignIn } = toRefs(useAuthenticator());

</script>

<template>
    <div id="login_auth_div">
        <div id="auth_container">
            <authenticator :hide-sign-up="true" :form-fields="formFields">
                <template v-slot:header>
                    <div id="login_form_header_section">
                        <div id="login_logo_div">
                            <img id="login_logo_image" src="@/assets/loginpage_logo.png"/>
                        </div>
                        <div id="login_welcome_text">
                            <span>Welcome to LEX Connect</span>
                        </div>
                    </div>
                </template>
                <template v-slot:sign-in-header>
                    <h3 class="amplify-heading">
                        User Login
                    </h3>
                </template>
                <template v-slot:reset-password-header>
                    <h3 class="amplify-heading-reset-password">
                        Password Reset
                    </h3>
                    <p>Please enter your username to reset your password.</p>
                </template>
                <template v-slot:confirm-sign-in-header>
                    <h3 class="amplify-heading-confirm-sign-in-code">
                        TOTP Code
                    </h3>
                </template>
                <template v-slot:sign-in-footer>
                    <div style="text-align: right;">
                        <button
                        @click="toResetPassword"
                        class="amplify-button amplify-field-group__control reset_password_button"
                        data-fullwidth="false"
                        data-size="small"
                        data-variation="link"
                        type="button"
                        >
                            Forgot Password?
                        </button>
                    </div>
                </template>
                <template v-slot:reset-password-footer>
                    <div style="text-align: left;">
                        <p>Remember your password?</p>
                        <button
                        @click="toSignIn"
                        class="amplify-button amplify-field-group__control return_to_signin_button"
                        data-fullwidth="false"
                        data-size="small"
                        data-variation="link"
                        type="button"
                        >
                            Sign In
                        </button>
                    </div>
                </template>
                <template v-slot:confirm-sign-in-footer>
                    <div style="text-align: left;">
                        <p>Remember your password?</p>
                        <button
                        @click="toSignIn"
                        class="amplify-button amplify-field-group__control return_to_signin_button"
                        data-fullwidth="false"
                        data-size="small"
                        data-variation="link"
                        type="button"
                        >
                            Back to Sign In
                        </button>
                    </div>
                </template>
            </authenticator>
        </div>
    </div>
    
</template>


<style scoped>

    :deep(p, span, div, label, input) {
        font-family: "Red Hat Display", sans-serif;
        font-weight: 400;
        color: #271d7a;
        font-size: 20px;
    }
    .reset_password_button, .return_to_signin_button {
        border: solid 1px #ffffff;
        color:#3e87d3;
        font-size: 20px;
        font-weight: 500;
        font-family: "Red Hat Display", sans-serif;
    }

    .reset_password_button {
        padding-right: var(--amplify-space-xl);
    }

    .return_to_signin_button {
        padding-left: 0;
    }

    #auth_container {
        background-color: white;
    }
    #login_form_header_section {
        background-color: #ffffff;
        margin-bottom: 0px;
    }

    .reset_password_button:hover, .reset_password_button:focus, .reset_password_button:active,
    .return_to_signin_button:hover, .return_to_signin_button:focus, .return_to_signin_button:active {
        background-color: white;
        border: solid 1px #ffffff;
    }

    #login_auth_div {
        display: flex; 
        justify-content: center; 
        align-items: center; 
        height: 100%; 
        margin: 0px;
    }

    #login_logo_div {
        max-width: 500px;
        padding: var(--amplify-space-xl) var(--amplify-space-xl) 0 var(--amplify-space-xl);
        background-color: #ffffff;
    }

    #login_logo_image {
        max-width: 100%;
    }

    #login_welcome_text {
        font-weight: 500; 
        font-family: "Red Hat Display", sans-serif;
        color: #271d7a;
        font-size: 30px;
        margin-bottom: 40px;
        padding: 0 var(--amplify-space-xl) 0 var(--amplify-space-xl);
        background-color: #ffffff;
    }

    @media screen and (max-width: 960px) {
        #login_welcome_text {
            margin-bottom: 10px;
        }
    }

    .amplify-heading, .amplify-heading-reset-password, .amplify-heading-confirm-sign-in-code {
        font-size: 28px;
        font-weight: bold;
        font-family: "Red Hat Display", sans-serif;
        color: #3e87d3;
        background-color: #ffffff;
    }

    .amplify-heading {
        padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
    }

    .amplify-heading-reset-password, .amplify-heading-confirm-sign-in-code {
        padding: 0 0 0 0; 
    }

    :deep([data-amplify-authenticator] [data-amplify-router]) {
        border-color: #ffffff;
        box-shadow: none;
    }

    :deep(.amplify-label) {
        color: #271d7a;
        font-size: 25px;
        font-weight: 500;
        font-family: "Red Hat Display", sans-serif;
        margin-top: 20px;
    }

    :deep(.amplify-button[data-variation='primary']) {
        padding: 5px;
        background-color: #2b2d84;
        color: white;
        font-size: 14pt;
        font-weight: 400;
        filter: drop-shadow(1px 1px 1px rgba(39, 29, 122, 0.1));
        font-family: "Red Hat Display", sans-serif;
        border-radius: 2px;
        height: 42px;
        width: 197px;
    }

    :deep(.amplify-button[data-variation='primary']:hover) {
        border: 1pt solid #84b1e0;
        height: 42px;
        width: 197px;
    }

    :deep(.amplify-button[data-variation='primary']:focus) {
        padding: 5px;
        background-color: #84b1e0;
        outline: #2b2d84 solid 0.25pt;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14pt;
        font-weight: 500;
        box-shadow: 0 0 4px rgba(57, 111, 186, 0.6) inset;
        filter: none;
        color: white;
        height: 42px;
        width: 197px;
    }

    :deep(.amplify-button[data-variation='primary']:active) {
        padding: 5px;
        background-color: #84b1e0;
        outline: #2b2d84 solid 0.25pt;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14pt;
        font-weight: 500;
        box-shadow: 0 0 4px rgba(57, 111, 186, 0.6) inset;
        filter: none;
        color: white;
        height: 42px;
        width: 197px;
    }

    :deep(.amplify-button--link) {
        display: none !important;
    }

    :deep(.amplify-passwordfield .amplify-field-group__outer-end .amplify-button, .amplify-input) {
        color: #271d7a;
    }

    :deep(.amplify-passwordfield .amplify-field-group__outer-end .amplify-button:hover, .amplify-input:hover) {
        color: #271d7a;
        background-color: #ecf2f8;
        border-color: #271d7a;
    }

    :deep(.amplify-passwordfield .amplify-field-group__outer-end .amplify-button:focus, .amplify-input:focus) {
        color: #271d7a;
        background-color: #ffffff;
        border-color: transparent;
    }
</style>