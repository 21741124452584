<template>
  <div class="flowsContainer" :style="{ height: dynamicHeight - 20 + 'px' }">
    <template v-if="selectedQueue != null"
      ><RealTimeQueueDash :queues="queueTableData" :queueSelected="selectedQueue" />
    </template>
    <template v-else>
      <TableCompDash
        :headers="filteredHeadersList"
        :items="queueTableData"
        name="Queue Dashboard"
        @navigate-queue="handleNavigate"
        @update-items="handleItemsUpdate"
        @click-row="handleClickRow"
      />
    </template>
  </div>
</template>
<script>
// TODO: FIX THIS FILE IT HAS ESLINT ISSUES
import TableCompDash from "@/components/CcManagement/TableCompDash.vue";
import RealTimeQueueDash from "./RealTimeQueueDash.vue";

import { mapActions, mapStores } from 'pinia';
import { useDashboardStore } from '@/stores/ContactCenter/Management/DashboardStore.js'


export default {
  name: "QueueDashboard",
  props: {
    filterOptionsArray: Array,
    dynamicHeight: Number,
    markedForRemoval: Array,
    unmarkForRemoval: Array,
    headersToDisplay: Array,
    queueClicked: Boolean,
    searchValue: String,
  },
  data() {
    return {
      componentKey: 0,
      selectedQueue: null,
      // queueTableData: [],
      headers: [
        { name: "Queue Name", width: 300, value: "queueName" },
        {
          name: "Calls Waiting & Wait Time",
          width: 120,
          value: "callsWaitingAndWaitTime",
        },
        { name: "Agents Ready", width: 100, value: "agentsReady" },
        {
          name: "Agents On Phone & Away",
          width: 150,
          value: "agentsOnPhoneAndAway",
        },
        { name: "New Tickets", width: 100, value: "newTickets" },
        {
          name: "LogicomUSA & Overflow Tickets",
          width: 120,
          value: "logicomUSAAndOverflowTickets",
        },
        { name: "Tickets Escalated", width: 120, value: "ticketsEscalated" },
        { name: "Open CSR Escalations", width: 100, value: "csrEscalations" },
        { name: "Answered Calls", width: 100, value: "answeredCalls" },
        {
          name: "Inbound and Outbound Calls",
          width: 160,
          value: "inboundAndOutboundCalls",
        },
        {
          name: "Missed and Abandoned Calls",
          width: 160,
          value: "missedAndAbandonedCalls",
        },
        {
          name: "Calls Handed to Overflow",
          width: 120,
          value: "callsHandedToOverflow",
        },
        {
          name: "Calls Waiting in Queue",
          width: 120,
          value: "callsWaitingInQueue",
        },
        { name: "Average Wait Time", width: 120, value: "averageWaitTime" },
        {
          name: "Longest Hold Time - Current",
          width: 140,
          value: "longestHoldTimeCurrent",
        },
        {
          name: "Longest Hold Time - Day",
          width: 120,
          value: "longestHoldTimeDay",
        },
        {
          name: "Callbacks Scheduled",
          width: 120,
          value: "callbacksScheduled",
        },
      ],
      queue: {
        queueName: "ACE Fiber Technician",
        answeredCalls: "50%",
        callsWaitingAndWaitTime: { first: 34, second: "00:34" },
        inboundAndOutboundCalls: { first: 34, second: 12 },
        missedAndAbandonedCalls: { first: "34%", second: "12%" },
        callsHandedToOverflow: "15%",
        newTickets: 45,
        logicomUSAAndOverflowTickets: { first: "34%", second: "66%" },
        ticketsEscalated: { first: "15", second: "20" },
        csrEscalations: 3,
        agentsReady: 12,
        agentsOnPhoneAndAway: { first: 34, second: "10" },
        callsWaitingInQueue: 7,
        averageWaitTime: "00:12",
        longestHoldTimeCurrent: "00:22",
        longestHoldTimeDay: "00:34",
        callbacksScheduled: 11,
        "Average Recovery Time": "00:23",
      },
      items: [],
      changed: [],
    };
  },

  methods: {
    ...mapActions(useDashboardStore, ['fetchQueueData']),
    handleSelectedItems(selectedItems) {
      this.$emit("selected-items", selectedItems);
    },
    handleNavigate(queue) {
      //Grab queue object using queue name
      this.selectedQueue = queue;
      this.$emit("selectedQueue", true);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    handleClickRow (data) {
      console.log(data);
    },
    handleItemsUpdate(sortedItems) {
      this.DashboardStore.updateQueuesTableData(sortedItems);
    }
  },
  mounted() {
    // this.queueTableData = this.DashboardStore.getQueueData;
  },
  computed: {
    ...mapStores(useDashboardStore),
    queueTableData() {
      const data = this.DashboardStore.getQueueData;
      if (!this.searchValue) {
        return data;
      }

      const lowerSearchValue = this.searchValue.toLowerCase();
      return data.filter((item) => item.queueName.toLowerCase().includes(lowerSearchValue));
    },
    filteredHeadersList() {
      if (this.headersToDisplay !== undefined && this.headersToDisplay.length) {
        return this.headers.filter((header) => {
          return (
            this.headersToDisplay.includes(header.value) ||
            header.value == "queueName"
          );
        });
      } else {
        return this.headers;
      }
    },
    tableData() {
      return this.items.filter((item) => {
        return Object.values(item).some((value) => {
          if (typeof value === "string") {
            return String(value)
            .toLowerCase()
            .trim()
            .includes(this.searchValue?.toLowerCase().trim())
          } else if (typeof value === "object" && value !== null) {
            return Object.values(value).some((value) =>
              String(value)
              .toLowerCase()
              .trim()
              .includes(this.searchValue?.toLowerCase().trim())
            );
          }
        });
      });
    },
  },
  watch: {
    queueTableData (newVal) {
      this.items = newVal;
    },
    markedForRemoval(marked) {
      marked.forEach((markedItem) => {
        this.data.forEach((dataItem) => {
          if (markedItem.flowName === dataItem.flowName) {
            dataItem.markedForRemoval = "Yes";
          }
        });
      });
      this.changed = [...this.data];
      this.$emit("selected-items", []);
    },
    unmarkForRemoval(marked) {
      marked.forEach((markedItem) => {
        this.data.forEach((dataItem) => {
          if (markedItem.flowName === dataItem.flowName) {
            dataItem.markedForRemoval = "No";
          }
        });
      });

      this.changed = [...this.data];
      this.$emit("selected-items", []);
    },
    queueClicked(newVal) {
      this.$nextTick(() => {
        if (newVal) {
          this.selectedQueue = {};
          this.$emit("selectedQueue", false);
        }
      });
    },
  },
  components: { TableCompDash, RealTimeQueueDash },
  emits: [
    "selected-items",
    "selectedQueue"
  ]
};
</script>
<style scoped>
.flowsContainer {
  padding: 20px;
  width: 100%;
  background-color: #e2f1f4;
}
.tableContainer {
  padding: 16px;
}
:deep(.table) {
  padding-left: 0;
  width: auto;
}

:deep(.listName) {
  padding-left: 0;
  padding-bottom: 0;
}
:deep(span.tableHeader) {
  display: block;
  min-width: 150px;
  padding-right: 30px;
}
:deep(span.tableHeader:nth-child(5)) {
  min-width: 120px;
  padding-right: 0;
}
</style>
