<template>
  <div class="flowPreviewContainer" :style="{ height: dynamicHeight + 'px' }">
    <div class="flowWizard">
      <FlowNav
        :navHeight="flattenFlow.length"
        :items="selectedFilteredFlow"
        v-model:currentIndex="currentIndex"
        v-model:currentStep="currentStep"
        v-model:isDebounce="isItemClicked"
        @active="setSectionTitle"
      />
    </div>
    <div class="flowPreview">
      <h2 class="previewHeader" :style="{ paddingTop: '11px' }">Preview</h2>
      <div class="flowContainer">
        <div class="preview-queue-flow-buttons">
          <DropDownMulti
            :sort_options="false"
            :options="queueDropdownOptions"
            @selected-values="updateDropDownQueue($event)"
            placeholder="Select a queue"
            style="margin-right: 20px;"
            class="multi_dropdown"
          />
          <DropDown
            ref="flowRef"
            :options="mutatedList"
            @input="updateDropDownVal(question, $event)"
            default="Select a flow"
            style="margin-right: 20px;"
          />
          <DropDown
            :options="LESDropdownOptions"
            @input="updateLESDropDownVal($event)"
            :value="{ label: 'LES: No', value: 'false'}"
            ref="LESDropdown"
          />
        </div>
        <div class="flowForm" :style="{ height: previewHeaderHeight + 'px' }">
          <FlowForm 
            @updateNav="updateNav"
            @currentData="handleProgressCurrentItem" 
            :sectionTitle="sectionTitle"
            @sectionLabel="setSectionLabelByID"
            :questions="selectedFlow" 
            :clearFlow="clearFlow" 
            @update-downloaded-flow-segment="updateDownloadedFlowSegment"
            v-model:isItemClicked="isItemClicked"
            :LESFlag="LESFlag"
            :key="resetKey"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FlowNav from '@/components/LEX/tickets/FlowNav.vue';

import FlowForm from "@/components/CcManagement/Flows/FlowForm.vue";
import DropDown from "@/components/base_components/FormComponents/DropDown.vue";
import DropDownMulti from "@/components/base_components/FormComponents/DropDownMulti.vue";
import { useFlowManagerStore } from "@/stores/ContactCenter/Management/FlowManagerStore.js";
import { mapStores, mapActions } from 'pinia';
import { uniq } from '@/helpers/uniqUtils.js'

export default {
  name: "PreviewView",
  data() {
    return {
      previewHeaderHeight: '0px',
      resetKey: 0,
      clearFlow: false,
      currentIndex: 0,
      currentQuestion: null,
      previousQuestions: [],
      response: "",
      questionIndex: 0,
      mutatedList: [],
      originalFlows: [],
      sectionTitle: "",
      isItemClicked: false,
      flowsList: [
        {
          label: "Flow 1",
          value: [
            {
              description: "!!!!!!!!  STOP AND READ !!!!!!!!!",
              type: "Display Only",
            },
            {
              description:
                "If this is an L.E.S member do not use this Flow. Use the L.E.S Flow provided. Check Member's Account Info to be sure.",
              type: "Display Only",
            },
            {
              description: "Member's Initial Description of the Issue:",
              type: "expandableBubble",
              id: 1,
            },
            {
              description:
                "What is the Callback number we can reach you at? (Add to Subject line)",
              type: "expandableBubble",
              id: 2,
            },
            {
              description:
                "NOTE: As the agent, you are responsible for verifying this Partner's Member based on either 2 Steps or 3 Steps based on the documented policy. Reach out to your supervisor for clarification if needed.",
              type: "Display Only",
              id: 3,
            },
            {
              description: "___THIS  CALL  IS 3 STEP VERIFICATION__",
              type: "Display Only",
              id: 4,
            },
            {
              description:
                "Check ALL THE Boxes of verification that member supplied.",
              type: "radio",
              id: 5,
              options: [
                {
                  label: "Name of Account holder.",
                  value: "Name of Account holder.",
                },
                {
                  label: "Complete Address including city and state.",
                  value: "Complete Address including city and state.",
                },
                { label: "Account number.", value: "Account number." },
                {
                  label: "Last 4 of MAC id of RG /ONT",
                  value: "Last 4 of MAC id of RG /ONT",
                },
                {
                  label: "Member Gave permission to access the account",
                  value: "Member Gave permission to access the account",
                },
              ],
              multiSelect: true,
            },

            {
              description:
                "Issue the Ticket number for Today's call. Check account status for Suspension per tools /wiki/alerts. Note: If it is a  Suspended account should use the CSR flow.",
              type: "Display Only",
              id: 6,
            },
            {
              description: "Is the member fully verified?",
              type: "radio",
              id: 7,
              options: [
                {
                  label: "Member is Fully Verified",
                  value: "Member is Fully Verified",
                },
                {
                  label: "Member is Not Fully Verified",
                  value: "Member is Not Fully Verified",
                },
              ],
              multiSelect: false,
              dependents: [
                {
                  description: "RG IP Addres/Link:",
                  type: "bubble",
                  id: 8,
                  dependentOn: "Member is Fully Verified",
                  question: "Is the member fully verified?",
                },
                {
                  description:
                    "If Caller is not verified. Do not open the member's account in any internal tools. Continue with basic troubleshooting.",
                  type: "Display Only",
                  id: 9,
                  dependentOn: "Member is Not Fully Verified",
                  question: "Is the member fully verified?",
                },
              ],
            },
            {
              description:
                "Troubleshooting Steps Performed with Tier 1 Technician:",
              type: "bubble",
              id: 10,
            },
            {
              description: "Result of Tier 1 Troubleshooting:",
              type: "radio",
              id: 11,
              options: [
                { label: "RESOLVED", value: "RESOLVED" },
                { label: "ESCALATION", value: "ESCALATION" },
                { label: "SETUP CALLBACK", value: "SETUP CALLBACK" },
              ],
              dependents: [
                {
                  description: "ISSUE RESOLVED",
                  type: "Display In Notes",
                  id: 12,
                  dependentOn: "RESOLVED",
                  question: "Result of Tier 1 Troubleshooting:",
                },
                {
                  description: "Escalate ticket to Internal Internet",
                  type: "Display Only",
                  id: 13,
                  dependentOn: "ESCALATION",
                  question: "Result of Tier 1 Troubleshooting:",
                },
                {
                  description:
                    "Setup callback according to documented procedure",
                  type: "Display Only",
                  id: 14,
                  dependentOn: "SETUP CALLBACK",
                  question: "Result of Tier 1 Troubleshooting:",
                },
              ],
            },
          ],
        },
      ],
      headers: [
        { text: "", value: "checkbox", width: 40, fixed: true },
        { text: "Flow name", value: "flowName" },
        { text: "Number of Questions", value: "questions" },
        { text: "Partners", value: "partners" },
        { text: "", value: "edithover", width: 20 },
      ],
      data: [
        {
          header: true,
          flowName: "FF-170599001",
          questions: "139.60.150.242",
          partners: "FreedomFiberTAs",
        },
        {
          header: true,
          flowName: "FF-170599001",
          questions: "139.60.150.242",
          partners: "FreedomFiberTAs",
        },
      ],
      flowDropdownOptions: [
        { label: "Flow 1", value: "flow1" },
        { label: "Flow 2", value: "flow2" },
      ],
      queueDropdownOptions: [],
      LESDropdownOptions: [
        { label: "LES: Yes", value: "true"},
        { label: "LES: No", value: "false"},
      ],
      selectedFlow: null,
      input: {
        name: "Question",
        type: "radio",
        required: false,
        direction: "column",
        outlined: false,
        label: "Select option",
        multiSelect: false,
        options: [
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
      },
      submissionData: {},
      selectedFilteredFlow: [],
      currentStep: this.currentIndex,
      LESFlag: false,

    };
  },
  props: { filterOptionsArray: Array, dynamicHeight: Number },
  computed: {
    ...mapStores(useFlowManagerStore),
    flattenFlow () {
      const flatArray = [];

      function recursiveFlatten(items) {
        if (!items) { return }
        for (const item of items) {
          if (
            item.type !== "Display Only" &&
            item.type !== "Display In Notes" &&
            item.type !== "Smart Link"
          ) {
            flatArray.push(item.description);
          }

          if (item.dependents) {
            recursiveFlatten(item.dependents);
          }
        }
      }

      recursiveFlatten(this.selectedFlow);
      return flatArray;
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions(useFlowManagerStore, ['loadData', 'getPartners']),
    handleSelectedItems(selectedItems) {
      this.$emit("selected-items", selectedItems);
    },
    handleResize() {
      var previewHeader = document.querySelector('.previewHeader');
      var flowSelector = document.querySelector('.preview-queue-flow-buttons');
      this.previewHeaderHeight = `calc(100% - ${parseInt(previewHeader.offsetHeight) + parseInt(flowSelector.offsetHeight)}px)`;
    },
    handleProgressCurrentItem() {
        // this.currentIndex += 1;
    },
    updateNav() {
      this.$nextTick(() => {

        var questions = document.getElementsByClassName('flowInputLabel');
        var question_labels = [];
        for (var question of questions) {
          if (question.innerText !== "" && !question.classList.value.includes('isLESFlagQuestion')) {
            var temp = {};
            temp.question_id = question.dataset.question_id;
            temp.value = question.innerText;
            question_labels.push(temp);
          }
        }
        this.selectedFilteredFlow = question_labels;
        this.currentIndex = this.selectedFilteredFlow.length - 1;
        this.currentStep = this.selectedFilteredFlow.length - 1;
        let index = this.selectedFilteredFlow.length - 1;
        this.setSectionLabelByID(this.selectedFilteredFlow[index])

      });
    },
    setSectionTitle (titleObj) {
      this.sectionTitle = titleObj.question_id;
    },
    navItemClicked(label) {
      var questions = document.getElementsByClassName('flowInputLabel');
      for (var question of questions) {
        if (question.innerText === label) {
          question.classList.add('activeQuestion');
        } else {
          question.classList.remove('activeQuestion');
        }
      }
    },
    setSectionLabelByID(value) {
      var checkedValue = "";
      if(typeof value == 'object' && Object.keys(value).length > 0) {
        checkedValue = value.question_id;
      } else {
        checkedValue = value;
      }

      var values = this.selectedFilteredFlow.map(obj => obj.question_id);
      const index = uniq(values).findIndex(item => item === checkedValue);

      if (index === -1) {
        return;
      }

      this.currentStep = index;
      // this.currentIndex = index;
    },
    handleCurrentIndex(index) {
      this.currentIndex = index;
    },
    // reIDFlow(questions) {
    //   for (var question of questions) {
    //     if (question?.id) {
    //       console.log(`Original ID: ${question.id}`); 
    //       question.original_id = question.id;
    //       question.id = question.id + "^_^" + Date.now(); 
    //       console.log(`New ID: ${question.id}`);
    //     }

    //     if (question.dependents) {
    //       question.dependents = this.reIDFlow(question.dependents);
    //     }
    //   }

    //   return questions;
    // },
    reIDFlow(questions, idCounter = 0) {
      for (var question of questions) {
        if (question?.id) {
          question.original_id = question.id;
          question.id = `${question.id}_unique_${idCounter++}`;
          console.log(`New ID: ${question.id}`);
        }

        if (question.dependents) {
          question.dependents = this.reIDFlow(question.dependents, idCounter);
        }
      }

      return questions;
    },
    updateDownloadedFlowSegment(dependents, question) {

      console.log("UPDATE DOWNLOADED FLOW SEGMENT IN PREVIEW VIEW");
      
      var downloaded_questions = dependents;
      var question_to_find = question;

      downloaded_questions = this.reIDFlow(downloaded_questions);
  
      this.findNestedFlowSegment(this.selectedFlow, downloaded_questions, question_to_find);
    },
    findNestedFlowSegment(dependent_questions, downloaded_questions, question_to_find) {
      // console.log("DEPENDENT QUESTIONS: ", dependent_questions);
      // console.log("DOWNLOADED QUESTIONS: ", downloaded_questions);
      // console.log("QUESTION TO FIND: ", question_to_find);

      for (var question of dependent_questions) {
        if (question?.flow == question_to_find?.flow) {
          question.dependents = downloaded_questions;
          //console.log("FOUND QUESTION: ", question);
          return;
        } 
        else {
          if (question.dependents) {
            this.findNestedFlowSegment(question.dependents, downloaded_questions, question_to_find);
          }
        }
      }
    },  
    handleFormSubmission(formData) {
      this.$emit("flow-data", formData);
    },
    handleQuestionIndex(questionIndex) {
      this.questionIndex = questionIndex;
    },
    updateDropDownQueue(event) {
      this.$refs.flowRef.clearSelection()
      this.currentIndex = 0;
      this.currentStep = 0;
      this.selectedFilteredFlow = [];
      let newList = [];


      var selectedQueues = Object.values(event).map(obj => obj.label);

      let flows = this.originalFlows;
      for(var flow of flows) {
        if(Array.isArray(flow.partners)) {
          for(var partner in flow.partners) {
            if(selectedQueues.indexOf(partner) != -1) {
            let flowObject = {
              label: flow.flowName,
              value: flow.staging_json
            }
            newList.push(flowObject)  
          }
          }
        } else {
          if(selectedQueues.indexOf(flow.partners) != -1) {
            let flowObject = {
              label: flow.flowName,
              value: flow.staging_json
            }
            newList.push(flowObject)  
          }
        }
      }

      this.clearFlow = true;
      this.mutatedList = newList;
      this.selectedFlow = null;
    },
    updateDropDownVal(q, flow) {
     
      if (this.selectedFlow !== flow) {
        this.selectedFilteredFlow = [];
        this.currentStep = 0;
        this.currentIndex = 0;
        this.clearFlow = true;
      }
      if (this.selectedFlow) {
        this.clearFlow = true;
      }

      if (flow.value) {
        this.selectedFlow = this.reIDFlow(flow.value);
      }
      console.log("SELECTED FLOW");
      console.log(this.selectedFlow);
    },
    updateLESDropDownVal(val) {
      if(this.LESFlag.toString() !== val.value) {
        // var currentFlow = this.selectedFlow;
        this.LESFlag = (val.value === 'true');
        this.selectedFilteredFlow = [];
        this.currentStep = 0;
        this.currentIndex = 0;
        this.clearFlow = true;
        // this.selectedFlow = currentFlow;
        this.resetKey++;
      }
    },
    updateRadioValue(name, option) {
      if (Array.isArray(option)) {
        this.submissionData[name] = option;
      } else {
        this.submissionData[name] = option.value;
      }
    },
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);
    var previewHeader = document.querySelector('.previewHeader');
    var flowSelector = document.querySelector('.preview-queue-flow-buttons');
    this.previewHeaderHeight = `calc(100% - ${parseInt(previewHeader.offsetHeight) + parseInt(flowSelector.offsetHeight)}px)`;
    this.currentStep = this.currentIndex
    await this.loadData();
    await this.getPartners();
    // var options = this.FlowManagerStore.fetchQueuesOptionArray;
    // console.log(options)

    var flows = this.FlowManagerStore.fetchFlows;
    var queues = this.FlowManagerStore.fetchQueues;    

    this.originalFlows = flows;
    var flows_json = [];
    for(var flow of flows){
      flows_json.push({
        label: flow.flowName,
        value: flow.staging_json
      });
    }
    let transformedArray = new Map(queues);

    var mutated = [...transformedArray]
      .filter(([label, value]) => label !== '' && label !== null && value !== '' && value !== null)
      .map(([label, value]) => ({ label, value }));

    this.queueDropdownOptions = [...mutated].sort((a, b) => a.label.localeCompare(b.label));
    this.queueDropdownOptions.unshift(
    {
        label: "Work in Progress",
        value: "Work in Progress",
    },
    {
        label: "Supporting Flow",
        value: "Supporting Flow",
    },
    {
        label: "Overflow",
        value: "Overflow",
    })
    this.flowsList = flows_json; 
  },
  watch: {
    clearFlow: {
      handler(newVal) {
        this.$nextTick(() => {
          if (newVal) {
            this.clearFlow = false;
          }
        });
      },
    },
  },
  components: { FlowForm, DropDown, DropDownMulti, FlowNav },
};
</script>
<style scoped>
.flowWizard {
  width: 40%;
  padding-top: 20px;
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  padding-bottom: 20px;
}
.flowPreviewContainer {
  display: flex;
  padding: 20px;
  width: 80%;
  background-color: #e2f1f4;
}


@media (min-width: 821px) {
  .flowPreviewContainer .preview-queue-flow-buttons {
    display: flex;
  }
}
@media (max-width: 820px) {
  .flowPreviewContainer {
    width: 100%;
  }
  .flowPreviewContainer .preview-queue-flow-buttons {
    display: flex;
    flex-direction: column;
  }
  .flowPreviewContainer .preview-queue-flow-buttons .dropdown.multi_dropdown {
    margin-bottom: 15px;
  }
}
.flowForm {
  background-color: #ffffff;
  position: relative;
  width: 100%;
  height: 99%;
  padding-bottom: 95px;
  box-sizing: border-box;
  padding-top: 15px;
}
.flowSelect {
  width: 50%;
  margin: 0 15px 15px;
}
.flowPreview {
  background-color: #ffffff;
  box-shadow: -5px 0 5px -5px rgba(39, 29, 122, 0.1);
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  padding: 5px 16px 20px 16px;
  display: flex;
  flex-direction: column;
}

.flowDropdown {
  width: 70%;
}

.previewHeader {
  padding-top: 5px;

  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
}

.selectedFlowContainer {
  flex-grow: 1;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px 20px;
  box-shadow: 0 0 4px rgba(39, 29, 122, 0.2);
}
.textArea {
  margin-top: 10px;
  width: 100%;
  flex-grow: 1;
  resize: none;
  padding: 0px 10px 0px 10px;
  font-size: 10pt;
  color: #271d7a;
  text-align: left;
  font-weight: normal;
  box-shadow: 0 0 4px rgba(39, 29, 122, 0.2);
  cursor: default;
}

.textArea:focus {
  outline: none;
}

:deep(.dropdown) {
  width: fit-content;
  min-width: 150px;
}

.multi_dropdown {
  max-width: 200px;
  margin-top: 0px;
}

.flowContainer {
  height: 100%;
}
</style>
