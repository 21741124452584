import { defineStore } from "pinia";
import libwebphone from "@/3rd_party_libraries/libwebphone/src/libwebphone.js";
import { API } from "aws-amplify";
import * as queries from "@/graphql/queries.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { Storage } from 'aws-amplify';
import { markRaw } from 'vue';

export const useWebRTCStore = defineStore("WebRTC", {
    state: () => ({
        number: "",
        selectedPage: "Keypad",
        currentCall: {
            "Caller ID": "",
            "Caller Name": "",
            "From Queue": "",
            "Total Call Duration": "",
            "Call Type": "",
            status: ""
        },
        currentCallObject: null,
        joinedCallObject: null,
        newCallObject: null,
        callsOnHold: {},
        callHistory:{},
        currentActiveSessions: [],
        libWebPhone: null,
        muted: false
    }),
    getters: {
        getNumber(state) {
            return state.number;
        },
        getMuteState(state) {
            return state.muted;
        },
        getSelectedPage(state) {
            return state.selectedPage;
        },
        getCurrentCall(state) {
            return state.currentCall;
        },
        getLibWebPhone(state) {
            return state.libWebPhone;
        },
        getCallHistory(state){
            return state.callHistory;
        },
        getCurrentCallCallStatus(state){
            return state.currentCall.status;
        },
        getCurrentCallCallDuration(state){
            return state.currentCall["Total Call Duration"];
        },
        getCallsOnHold(state){
            return state.callsOnHold;
        },
        getCurrentCallObject(state) {
            return state.currentCallObject;
        },
        getJoinedCallObject(state) {
            return state.joinedCallObject;
        },
        getNewCallObject(state) {
            return state.newCallObject;
        },
        getCurrentActiveSessions(state) {
            return state.currentActiveSessions;
        }
    },
    actions: {
        changeSelectedPage(selectedPage) {
            this.selectedPage = selectedPage;
        },
        toggleMuted() {
            if (this.currentCallObject !== null) {
                if (this.muted) {
                    this.currentCallObject.unmute()
                } else {
                    this.currentCallObject.mute()
                }
                this.muted = !this.muted;
            }
            
        },
        setCurrentActiveSessions(sessions) {
            this.currentActiveSessions = markRaw(sessions);
        },
        changeNumber(number) {
            this.number = number;
        },
        setCurrentCallCallerID(caller_id){
            this.currentCall["Caller ID"] = caller_id;
        },
        setCurrentCallCallerName(caller_name){
            this.currentCall["Caller Name"] = caller_name;
        },
        setCurrentCallFromQueue(queue){
            this.currentCall["From Queue"] = queue;
        },
        setCurrentCallCallDuration(duration){
            let formatted_duration = duration;
            this.currentCall["Total Call Duration"] = formatted_duration;
        },
        setCurrentCallStatus(status){
            this.currentCall.status = status;
        },
        setCurrentCallType(type) {
            this.currentCall["Call Type"] = type;
        },
        setCurrentCallCallObj(call){
            if(typeof call === "object" && call !== null){
                call = markRaw(call)
            }
            this.currentCallObject = call;
        },
        setJoinedCallObj(call) {
            if(typeof call === "object" && call !== null){
                call = markRaw(call)
            }
            this.joinedCallObject = call;
        },
        setNewCallCallObj(call){
            if(typeof call === "object" && call !== null){
                call = markRaw(call)
            }
            this.newCallObject = call;
        },
        hangupCurrentCall(){
            let dial_pad = this.libWebPhone.getDialpad();
            dial_pad.terminate();
            dial_pad.clear();

            let num = "";
            this.changeNumber(num);            
            this.setCurrentCallStatus("");
            this.changeSelectedPage("Keypad");
        },
        addUpdateCallHistory(id, direction, date_time, display_name, number = "Unavailable", duration = "0s", missed = false){
            let history_obj = {
                id: id,
                direction: direction,
                date_time: date_time,
                display_name: display_name,
                number: number,
                duration: duration,
                missed: missed,
            };
            this.callHistory[id] = history_obj;
        },
        clearMute() {
            this.muted = false;
        },
        addUpdateCallsOnHold(id, caller, direction, date_time, number = "Unavailable", duration = "0s", call = null){
            let call_obj = {
                id: id,
                caller: caller,
                direction: direction,
                time: date_time,
                number: number,
                duration: duration,
                call: call
            };
            this.callsOnHold[id] = markRaw(call_obj);
        },
        removeCallOnHold(id){
            delete this.callsOnHold[id];
        },
        // eslint-disable-next-line
        async connectToWebRTC_phone(user_id) {
            let s3FilePath = "";

            try {
                var options = {
                    query: queries.getUsers,
                    variables: {
                        id: user_id
                    },
                    authMode: GRAPHQL_AUTH_MODE.API_KEY
                }

                const userResponse = await API.graphql(options);

                try {
                    s3FilePath = userResponse.data.getUsers.webrtc_config_s3_key;
                }
                catch (error) {
                    s3FilePath = "";
                }
            }
            catch (error) {
                // TODO: what needs to be done here besides a console.log? 
                console.log(error);
            }

            // TODO: place config here for new users: "{bucket_name|diff per environement}/protected/{cognito user id | diff per environment}/web_rtc/sip_config.json"
            // The sip_config.json should be like the following:
            // {
            //     "sip_user":"",
            //     "sip_pass":"",
            //     "sip_realm":""
            // }
            //
            // The user in the users table will need to have webrtc_config_s3_key be set to "web_rtc/sip_config.json" based on this example.

            if (s3FilePath) {
                let s3_config = "";
                
                try{
                    const result = await Storage.get(s3FilePath, { level: "protected", identityId: user_id, download: true });

                    s3_config = JSON.parse(await result.Body.text());
                }
                catch(error){
                    s3_config = "";
                }

                if (s3_config && s3_config.sip_user && s3_config.sip_pass && s3_config.sip_realm) {
                    var config = {
                        dialpad: {
                            // renderTargets: [
                            //     "dialpad",
                            //     {
                            //         root: { elementId: "dialpad_custom_tweaked" },
                            //         data: {
                            //             dialed: {
                            //                 show: true,
                            //                 filter: { show: false },
                            //                 convert: { show: false },
                            //             },
                            //             dialpad: { show: false },
                            //             controls: { show: true },
                            //         },
                            //     },
                            // ],
                            globalKeyShortcuts: false,
                            tones: false,
                            renderTargets: [],
                        },
                        callList: {
                            // renderTargets: ["call_list"],
                            renderTargets: [],
                        },
                        callControl: {
                            // renderTargets: ["call_control"],
                            renderTargets: [],
                        },
                        mediaDevices: {
                            // audioinput: {
                            //     preferedDeviceIds: ["bnkZoip5H5kiNXBLV+YNGalX9r0kvfNjJsZcOkHPeQQ="],
                            // },
                            videoinput: {
                                enabled: false,
                            },
                            // renderTargets: [
                            //     "media_device",
                            //     {
                            //         root: {
                            //             elementId: "media_device_tweaked",
                            //         },
                            //         data: {
                            //             audiooutput: { show: false },
                            //             audioinput: { show: false },
                            //         },
                            //     },
                            // ],
                            renderTargets: [],
                        },
                        audioContext: {
                            // renderTargets: [
                            //     "audio_context",
                            //     {
                            //         root: { elementId: "audio_context_tweaked" },
                            //         data: {
                            //             input: { local: { show: false } },
                            //             output: { preview: { show: false } },
                            //         },
                            //     },
                            // ],
                            renderTargets: [],
                        },
                        userAgent: {
                            // renderTargets: ["user_agent"],
                            renderTargets: [],
                            transport: {
                                sockets: ["wss://portal.logicom.center:5065"],
                                recovery_max_interval: 30,
                                recovery_min_interval: 2,
                            },
                            authentication: {
                                username: s3_config.sip_user,
                                password: s3_config.sip_pass,
                                realm: s3_config.sip_realm,
                            },
                            user_agent: {
                                // contact_uri: '',
                                // display_name: '',
                                instance_id: "8f1fa16a-1165-4a96-8341-785c1ef24f12",
                                no_answer_timeout: 60,
                                register: true,
                                register_expires: 300,
                                user_agent: "libwebphone 2.x - dev",
                            },
                        },
                        videoCanvas: {
                            enabled: false,
                            // canvas: "video_screen",
                            // renderTargets: ["video_controls"],
                            renderTargets: [],
                        },
                    }; //End ofConfig

                    this.libWebPhone = markRaw(new libwebphone(config));
                }
            }
        },
        answerCurrentCall() {
            this.currentCallObject = 1;
        }
    },
});
