import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

//Vue3EasyDataTable
import Vue3EasyDataTable from 'vue3-easy-data-table';

//VueGridLayout
import VueGridLayout from 'vue-grid-layout';
import 'vue3-easy-data-table/dist/style.css';

//Pinia Store
import { createPinia } from 'pinia'

//VCalendar
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

//Grid Layout Plus
import { GridLayout, GridItem } from 'grid-layout-plus'


//https://github.com/sagalbot/vue-select / https://vue-select.org/ - Vue Searchable Select Dropdown  - DEMO: https://vue-select.org/sandbox.html
import VueSelect  from "vue-select";

const vuetify = createVuetify({
    components,
    directives,
});

const pinia = createPinia();

const app = createApp(App);

app.component('EasyDataTable', Vue3EasyDataTable);
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
app.component('GridLayout', GridLayout)
app.component('GridItem', GridItem)
app.component("v-select", VueSelect);
app.use(setupCalendar, {})
app.use(VueGridLayout);
app.use(router);
app.use(vuetify);
app.use(pinia);

app.mount('#app');

